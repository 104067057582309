import axios from 'axios';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
// Types
import { RolView } from '@trii/types/dist/Users';
import { UserRole } from '@trii/types/dist/Users';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import roleService from './roleService';

export const fetchRoles = createAsyncThunk(
  'Roles/fetchRoles',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.get(`${URL_SETTINGS}/roles`, config);

    return response.data;
  }
);

export const addRole = createAsyncThunk(
  'Roles/addRole',
  async (role: any, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const roleJson = JSON.stringify(role);

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.post(`${URL_SETTINGS}/roles`, roleJson, config);

    return response.data;
  }
);

export const updateRole = createAsyncThunk(
  'Roles/updateRole',
  async (updatedRole: UserRole, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const updatedRoleJson = JSON.stringify(updatedRole);

    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${URL_SETTINGS}/roles/${updatedRole.id}`,
      updatedRoleJson,
      config
    );

    return response.data;
  }
);

export const deleteRole = createAsyncThunk(
  'Roles/deleteRole',
  async (data: { newUsersRoleId: string; roleId: string }, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { newUsersRoleId, roleId } = data;
    const newUsersRoleIdJson = JSON.stringify({ rolId: newUsersRoleId });

    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      data: newUsersRoleIdJson,
    };

    const response = await axios.delete(`${URL_SETTINGS}/roles/${roleId}`, config);

    return response.data || roleId;
  }
);

export const fetchRoleUsers = createAsyncThunk(
  'Roles/fetchRoleUsers',
  async (roleId: string, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await roleService.fetchRoleUsers(
      `${URL_SETTINGS}/users`,
      jwtToken,
      roleId
    );

    return response;
  }
);

const rolesAdapter = createEntityAdapter<RolView>({});

export const roleSlice = createSlice({
  name: 'Role',
  initialState: rolesAdapter.getInitialState({
    viewRoles: [],
    state: 'idle',
    users: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, action) => {
        rolesAdapter.setAll(state, action.payload);
        state.state = 'succeeded';
      })
      .addCase(fetchRoles.pending, (state, action) => {
        state.state = 'loading';
      })
      .addCase(addRole.fulfilled, (state, action) => {
        rolesAdapter.addOne(state, action.payload);
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        rolesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        rolesAdapter.removeOne(state, action.payload);
      })
      .addCase(fetchRoleUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      });
  },
});

function makeTree(flatArray) {
  const tree = [];
  const map = new Map();
  for (const item of flatArray) {
    map.set(item.id, { ...item, children: [] });

    const parent = item.parent;
    if (parent) {
      const parentItem = map.get(parent.id);
      parentItem.children.push(map.get(item.id));
    } else {
      tree.push(map.get(item.id));
    }
  }

  return tree[0];
}

export const { selectAll: selectAllRoles, selectById: selectRoleById } =
  rolesAdapter.getSelectors((state: any) => state.Role);
export const selectAllViewRoles = createSelector(selectAllRoles, (roles) => {
  return makeTree(roles);
});
export const selectRoleUsers = (state: any) => state.Role.users;
export const getRoleFetchState = (state) => state.Role.state;

export const modalView = (state) => state.Role.modalDeleteValue;
export const infoView = (state) => state.Role.infoViewValue;

export default roleSlice.reducer;

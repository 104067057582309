import useField from 'hooks/useField';
import useSwitch from 'hooks/useSwitch';
import { useState, createContext } from 'react';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Types
import { IProfilesContext } from './types/ProfilesContext';
import { UserProfile } from '@trii/types/dist/Users';

export const ProfilesContext = createContext<IProfilesContext>({
  duplicatedNameField: fieldInitialDef,
  duplicatedDescriptionField: fieldInitialDef,
  nameField: fieldInitialDef,
  descriptionField: fieldInitialDef,
  selectedProfile: null,
  setSelectedProfile: () => {},
  updatedProfile: null,
  collectionsSwitches: [],
  conversationSwitches: [],
  contactSwitches: [],
  marketingSwitches: [],
  serviceSwitches: [],
  ticketSwitches: [],
  chatSwitches: [],
  callSwitches: [],
  calendarSwitches: [],
  crmSwitches: [],
  dataSyncSwitches: [],
  signatureSwitches: [],
  // agentSwitches: [],
  isEditing: false,
  setIsEditing: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

const ProfilesDataProvider = ({ children }) => {
  const [selectedProfile, setSelectedProfile] = useState<UserProfile>(null);
  // @ts-ignore
  const { conversations, contacts, marketing, service, tickets, debtCollection, calls, chat, calendar, crm, datasync, signature, agent } =
    selectedProfile || {};

  // Duplicated profile state
  const duplicatedNameField = useField('text', selectedProfile?.name || '');
  const duplicatedDescriptionField = useField('text', selectedProfile?.description || '');
  // Form state
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nameField = useField('text', selectedProfile?.name || '');
  const descriptionField = useField('text', selectedProfile?.description || '');
  //--------Conversations switches
  const enabledConversationSwitch = useSwitch(conversations?.enabled || false);
  const cannedResponsesAdminCatSwitch = useSwitch(conversations?.cannedResponses_adminCat || false);
  const cannedResponsesCreateSwitch = useSwitch(conversations?.cannedResponses_create || false);
  const dashboardSwitch = useSwitch(conversations?.dashboard || false);
  const editConfigSwitch = useSwitch(conversations?.editConfig || false);
  const editMsgNoteSwitch = useSwitch(conversations?.editMsgNote || false);
  const endFromInboxSwitch = useSwitch(conversations?.endFromInbox || false);
  const enterIncognitoModeSwitch = useSwitch(conversations?.enterIncognitoMode || false);
  const otherInboxesSwitch = useSwitch(conversations?.otherInboxes || false);
  const otherInboxesUsersSwitch = useSwitch(conversations?.otherInboxes_users || false);
  const otherInboxesGroupsSwitch = useSwitch(conversations?.otherInboxes_groups || false);
  const showUnassignedConversationsSwitch = useSwitch(conversations?.showUnassignedConversations || false);
  //--------Collections switches
  const enabledCollectionsSwitch = useSwitch(debtCollection?.enabled || false);
  //--------Contacts switches
  const deleteContactsSwitch = useSwitch(contacts?.delete || false);
  const editConfigContactsSwitch = useSwitch(contacts?.editConfig || false);
  const enabledContactsSwitch = useSwitch(contacts?.enabled || false);
  const exportContactsSwitch = useSwitch(contacts?.export || false);
  const importContactsSwitch = useSwitch(contacts?.import || false);
  const joinContactsSwitch = useSwitch(contacts?.join || false);
  //--------Marketing switches
  const enabledMarketingSwitch = useSwitch(marketing?.enabled || false);
  //--------Service switches
  const enabledServiceSwitch = useSwitch(service?.enabled || false);
  //--------Tickets switches
  const enabledTicketsSwitch = useSwitch(tickets?.enabled || false);
  const ticketCreateSwitch = useSwitch(tickets?.ticket_create || false);
  const ticketDeleteSwitch = useSwitch(tickets?.ticket_delete || false);
  const ticketEditSwitch = useSwitch(tickets?.ticket_edit || false);
  const ticketUpdateSwitch = useSwitch(tickets?.ticket_update || false);
  //--------Chat switches
  const enabledChatSwitch = useSwitch(chat?.enabled || false);
  const editConfigChatSwitch = useSwitch(chat?.editConfig || false);
  //--------Calls switches
  const enabledCallsSwitch = useSwitch(calls?.enabled || false);
  const editConfigCallsSwitch = useSwitch(calls?.editConfig || false);
  const dashboardCallsSwitch = useSwitch(calls?.dashboard || false);
  //--------Calendar switches
  const enabledCalendarSwitch = useSwitch(calendar?.enabled || false);
  const editConfigCalendarSwitch = useSwitch(calendar?.editConfig || false);
  //--------CRM switches
  const enabledCRMSwitch = useSwitch(crm?.enabled || false);
  const editConfigCRMSwitch = useSwitch(crm?.editConfig || false);
  //--------DataSync switches
  const enabledDataSyncSwitch = useSwitch(datasync?.enabled || false);
  const editConfigDataSyncSwitch = useSwitch(datasync?.editConfig || false);
  //--------Signature switches
  const enabledSignatureSwitch = useSwitch(signature?.enabled || false);
  const editConfigSignatureSwitch = useSwitch(signature?.editConfig || false);
  //--------Agent switches
  // const inactivityCheckAgentSwitch = useSwitch(agent.inactivityCheck || false);
  // const inactivityTimeAgent = null;

  const updatedProfile = {
    id: selectedProfile?.id,
    name: nameField.attributes.value as string,
    description: descriptionField.attributes.value as string,
    isDefault: selectedProfile?.isDefault,
    collections: {
      enabled: enabledCollectionsSwitch.checked,
    },
    contacts: {
      delete: deleteContactsSwitch.checked,
      editConfig: editConfigContactsSwitch.checked,
      enabled: enabledContactsSwitch.checked,
      export: exportContactsSwitch.checked,
      import: importContactsSwitch.checked,
      join: joinContactsSwitch.checked,
    },
    conversations: {
      enabled: enabledConversationSwitch.checked,
      cannedResponses_adminCat: cannedResponsesAdminCatSwitch.checked,
      cannedResponses_create: cannedResponsesCreateSwitch.checked,
      dashboard: dashboardSwitch.checked,
      editConfig: editConfigSwitch.checked,
      editMsgNote: editMsgNoteSwitch.checked,
      endFromInbox: endFromInboxSwitch.checked,
      enterIncognitoMode: enterIncognitoModeSwitch.checked,
      otherInboxes: otherInboxesSwitch.checked,
      otherInboxes_users: otherInboxesUsersSwitch.checked,
      otherInboxes_groups: otherInboxesGroupsSwitch.checked,
      showUnassignedConversations: showUnassignedConversationsSwitch.checked,
    },
    marketing: {
      enabled: enabledMarketingSwitch.checked,
    },
    service: {
      enabled: enabledServiceSwitch.checked,
    },
    tickets: {
      enabled: enabledTicketsSwitch.checked,
      ticket_create: ticketCreateSwitch.checked,
      ticket_delete: ticketDeleteSwitch.checked,
      ticket_edit: ticketEditSwitch.checked,
      ticket_update: ticketUpdateSwitch.checked,
    },
    chat: {
      enabled: enabledChatSwitch.checked,
      editConfig: editConfigChatSwitch.checked,
    },
    calls: {
      enabled: enabledCallsSwitch.checked,
      editConfig: editConfigCallsSwitch.checked,
      dashboard: dashboardCallsSwitch.checked,
    },
    calendar: {
      enabled: enabledCalendarSwitch.checked,
      editConfig: editConfigCalendarSwitch.checked,
    },
    crm: {
      enabled: enabledCRMSwitch.checked,
      editConfig: editConfigCRMSwitch.checked,
    },
    datasync: {
      enabled: enabledDataSyncSwitch.checked,
      editConfig: editConfigDataSyncSwitch.checked,
    },
    signature: {
      enabled: enabledSignatureSwitch.checked,
      editConfig: editConfigSignatureSwitch.checked,
    }
    // ,
    // agent: {
    //   inactivityCheck: inactivityCheckAgentSwitch.checked,
    //   inactivityTime: inactivityTimeAgent
    // },
  };

  return (
    <ProfilesContext.Provider
      value={{
        duplicatedNameField,
        duplicatedDescriptionField,
        nameField,
        descriptionField,
        selectedProfile,
        setSelectedProfile,
        updatedProfile,
        collectionsSwitches: [enabledCollectionsSwitch],
        conversationSwitches: [
          enabledConversationSwitch,
          cannedResponsesAdminCatSwitch,
          cannedResponsesCreateSwitch,
          dashboardSwitch,
          editConfigSwitch,
          editMsgNoteSwitch,
          endFromInboxSwitch,
          enterIncognitoModeSwitch,
          otherInboxesSwitch,
          otherInboxesUsersSwitch,
          otherInboxesGroupsSwitch,
          showUnassignedConversationsSwitch,
        ],
        contactSwitches: [
          enabledContactsSwitch,
          editConfigContactsSwitch,
          exportContactsSwitch,
          importContactsSwitch,
          joinContactsSwitch,
          deleteContactsSwitch,
        ],
        marketingSwitches: [enabledMarketingSwitch],
        serviceSwitches: [enabledServiceSwitch],
        ticketSwitches: [
          enabledTicketsSwitch,
          ticketCreateSwitch,
          ticketDeleteSwitch,
          ticketEditSwitch,
          ticketUpdateSwitch,
        ],
        chatSwitches: [
          enabledChatSwitch,
          editConfigChatSwitch,
        ],
        callSwitches: [
          enabledCallsSwitch,
          editConfigCallsSwitch,
          dashboardCallsSwitch,
        ],
        calendarSwitches: [
          enabledCalendarSwitch,
          editConfigCalendarSwitch,
        ],
        crmSwitches: [
          enabledCRMSwitch,
          editConfigCRMSwitch,
        ],
        dataSyncSwitches: [
          enabledDataSyncSwitch,
          editConfigDataSyncSwitch,
        ],
        signatureSwitches: [
          enabledSignatureSwitch,
          editConfigSignatureSwitch,
        ],
        // agentSwitches: [
        //   inactivityCheckAgentSwitch,
        // ],
        isEditing,
        setIsEditing,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </ProfilesContext.Provider>
  );
};

export default ProfilesDataProvider;

import dayjs, { Dayjs } from 'dayjs';
import { initial } from 'lodash';
import { useState, useEffect } from 'react';

export type UseDateFieldType = {
  actions: {
    onError: (errorMsg: string | JSX.Element) => void;
    onSuccess: () => void;
    resetValue: () => void;
  };
  attributes: {
    error: boolean;
    helperText: string;
    type: string;
    value: Dayjs;
    onChange: (event: Dayjs) => void;
  };
};

const useDateField = (type: string, initialValue?: Dayjs): UseDateFieldType => {
  initialValue = initialValue || dayjs();
  const [value, setValue] = useState<Dayjs | null>(initialValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const onChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const onError = (errorMsg: string) => {
    setError(true);
    setHelperText(errorMsg);
  };

  const onSuccess = () => {
    setError(false);
    setHelperText('');
  };

  const resetValue = () => {
    setValue(dayjs());
  };

  return {
    actions: { onError, onSuccess, resetValue },
    attributes: { error, helperText, type, value, onChange },
  };
};

export default useDateField;

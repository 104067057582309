import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';

// Types

// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';

const Header = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, apiKeyData } = useContext<ApiKeysContext>(InitApiKeysContext);
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Box display={'flex'} gap={1}>
        <Typography variant="subtitle2" sx={{color: theme=>theme.palette.text.primary}}>
          {t('ApiKeys.deleteApiKey')}
        </Typography>
        <Typography variant="subtitle2" sx={{fontWeight: "bold", color: theme=>theme.palette.text.primary}}>{apiKeyData.name}</Typography>
      </Box>
      <IconButton onClick={closeDeletePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;

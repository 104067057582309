import React from "react";
import { ProfilesContext } from "../../context/profilesContext";
//components/ui
import {
  ProfileInfoHeader,
  ProfileInfoBody,
  MessageNoProfileExist,
} from "./Components";
import Box from "@mui/material/Box";

export default function ProfileInfo() {
  const { selectedProfile } = React.useContext(ProfilesContext);

  return (
    <Box
      display="flex"
      gap="0.5rem"
      height="100%"
      maxHeight={"100%"}
      flexGrow={1}
      width="66.6%"
      flexDirection="column"
    >
      {selectedProfile === null ? (
        <MessageNoProfileExist profile={selectedProfile} />
      ) : (
        <>
          <ProfileInfoHeader />
          <ProfileInfoBody />
        </>
      )}
    </Box>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
//components/ui
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function ProfileInfoBodyTabs({ tab, setTab }) {
  const { t } = useTranslation();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeNavTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{ backgroundColor: "background.panel" }}
    >
      <Tabs value={tab} onChange={handleChangeNavTab}>
        <Tab label={t("global.detail")} {...a11yProps(0)} />
        <Tab label={t("global.users")} {...a11yProps(1)} />
      </Tabs>
    </Box>
  );
}

import React, { useContext } from 'react';
import { ProfilesContext } from 'features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext';
//components/ui
import { StyleContext } from 'style/styleProvider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
// Redux
import { useDispatch } from 'react-redux';
import { fetchProfileUsers } from 'redux/features/profilesSlice/profilesSlice';

export default function ProfilesListItem({ profile }) {
  const { selectedProfile, setSelectedProfile, setIsEditing, nameField } =
    useContext(ProfilesContext);
  const { fontSize } = useContext(StyleContext);
  const dispatch = useDispatch();

  const handleItemSelect = () => {
    setSelectedProfile(profile);
    setIsEditing(false);
    nameField.actions.onSuccess();

    dispatch(fetchProfileUsers(profile.id));
  };

  return (
    <ListItemButton
      selected={selectedProfile?.id === profile.id}
      className="fadein"
      onClick={(e) => handleItemSelect(e, profile.id)}
    >
      <ListItemText
        primary={
          <Typography
            sx={{ color: 'text.primary' }}
            fontSize={fontSize.md}
          >{`${profile.name}`}</Typography>
        }
        secondary={
          <Typography sx={{ color: 'text.primary' }} fontSize={fontSize.sm}>
            {profile.description}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

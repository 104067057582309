import React, { useMemo } from 'react';
//components/ui
import Info from 'components/Info';
import { Box } from '@mui/material';
import Input from 'components/Input';

export default function InputBox({
  boxDescription,
  stateHandler,
  isDisabled,
  isEditing,
}) {
  let info = stateHandler ? stateHandler.attributes.value : null;

  return useMemo(() => {
    return (
      <Box
        className="fadein"
        display={'flex'}
        width='95%'
        alignItems={'center'}
        marginTop={1}
      >
        {isEditing ? (
          <Input
            isDisabled={isDisabled}
            attributes={stateHandler.attributes}
            label={boxDescription}
          />
        ) : (
          <Info title={boxDescription} info={info} />
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, stateHandler.attributes.value, boxDescription, info]);
}

const languageList = [
  {
    value: 'es',
    description: 'Spanish',
    nativeName: 'Español',
  },
  {
    value: 'en',
    description: 'English',
    nativeName: 'English',
  },
];

export default languageList;

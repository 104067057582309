import { INavIcon } from "../types/types";
import {
    Business,
    Dashboard,
    Groups,
    Person,
    Security,
    WorkHistory,
    Api
} from '@mui/icons-material';

export const iconsData: INavIcon[] = [
    {
        id: '1',
        title: "global.myProfile",
        icon: <Person />,
        to: "/a/settings/profile",
    },
    {
        id: '2',
        title: "global.dashboard",
        icon: <Dashboard />,
        to: "/a/settings/dashboard",
    },
    {
        id: '3',
        title: "navBar.workspace",
        icon: <Business />,
        to: "/a/settings/workspace",
    },
    {
        id: '4',
        title: "navBar.members",
        icon: <Groups />,
        to: "/a/settings/users",
    },
    {
        id: '5',
        title: "global.security",
        icon: <Security />,
        to: "/a/settings/security",
    },
    {
        id: '6',
        title: "navBar.workHistory",
        icon: <WorkHistory />,
        to: "/a/settings/work-history",
    },
    {
        id: '7',
        title: 'API Keys',
        to: '/a/settings/APIKeys',
        icon: <Api />,
        //    isDevelopment: true,
      },
];
// Components/ui
import {
  Box,
  Grid,
} from "@mui/material";

// Components
import { Icons } from "./components";
import { UserTrii } from "@trii/types/dist/Users";
import TriiCalendarIcon from './images/TriiCalendar.svg';
import TriiCallIcon from './images/TriiCall.svg';
import TriiChatIcon from './images/TriiChat.svg';
import TriiCollectionIcon from './images/TriiCollection.svg';
import TriiContactsIcon from './images/TriiContacts.svg';
import TriiCRMIcon from './images/TriiCRM.svg';
import TriiMarketingIcon from './images/TriiMarketing.svg';
import TriiServiceIcon from './images/TriiService.svg';
import TriiTeamIcon from './images/TriiTeam.svg';
import TriiTicketsIcon from './images/TriiTickets.svg';
import TriiSignatureIcon from './images/TriiSignature.svg';
import TriiDataSyncIcon from './images/TriiDataSync.svg';

interface AplicationsTabProps {
  user: UserTrii;
}
const AplicationsTab = ({
  
  user,
  
}: AplicationsTabProps) => {
  const profileApps = [
    { name: "dropdownAppList.TriiCalendar", icon: TriiCalendarIcon, enabled: user.profile.calendar.enabled },
    { name: "dropdownAppList.TriiCalls", icon: TriiCallIcon, enabled: user.profile.calls.enabled },
    { name: "dropdownAppList.TriiChat", icon: TriiTeamIcon, enabled: user.profile.chat.enabled },
    { name: "dropdownAppList.TriiContacts", icon: TriiContactsIcon, enabled: user.profile.contacts.enabled },
    { name: "dropdownAppList.TriiConversations", icon: TriiChatIcon, enabled: user.profile.conversations.enabled },
    { name: "dropdownAppList.TriiCRM", icon: TriiCRMIcon, enabled: user.profile.crm.enabled },
    { name: "dropdownAppList.TriiDataSync", icon: TriiDataSyncIcon, enabled: user.profile.datasync.enabled },
    { name: "dropdownAppList.TriiDebtCollection", icon: TriiCollectionIcon, enabled: user.profile.debtCollection.enabled },
    { name: "dropdownAppList.TriiMarketing", icon: TriiMarketingIcon, enabled: user.profile.marketing.enabled },
    { name: "dropdownAppList.TriiRepair", icon: TriiServiceIcon, enabled: user.profile.service.enabled },
    { name: "dropdownAppList.TriiSignature", icon: TriiSignatureIcon, enabled: user.profile.signature.enabled },
    { name: "dropdownAppList.TriiTickets", icon: TriiTicketsIcon, enabled: user.profile.tickets.enabled }
  ];

  // profileApps.forEach(app => {
  //   const icon = getIconList.find(icon => icon.title === `benefits.title${app.name.charAt(0).toUpperCase() + app.name.slice(1)}`);
  //   if (icon) {
  //     app.icon = icon.icon;
  //   }
  // });
  return (
    <Box display={"flex"} flexDirection="column"  px={5} pt={4}>
      <Grid container spacing={4}>
        {profileApps.map((item, i) => (
          <Icons key={i} item={item} />
        ))}
      </Grid>
    </Box>
  );
};

export default AplicationsTab;

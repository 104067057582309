/* eslint-disable import/no-anonymous-default-export */
import { Security } from "@trii/types/dist/Security";
import axios from "axios";

import getRequestConfig from "redux/functions/getRequestConfig";

const fetchSecurity = async (
  jwt: string,
  URL: string,
) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    `${URL}/security`,
    config
  );

  return response;
};

const updateSecurity = async (
  jwt: string,
  URL: string,
  data: Security,
) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.put(
    `${URL}/security`,
    data,
    config
  );

  return response;
};

export default {
  fetchSecurity,
  updateSecurity
};

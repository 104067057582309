import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";

import { initRequestData } from "redux/functions/initRequestData";

import spacesSliceService from "./spacesSliceService";
// Types
import { RootState } from "redux/store";
import { ISpaceInfo } from "@trii/types/dist/Spaces";
import { SpacesSliceState } from "./types/SpacesSliceState";
// Service
import InitRequestDataReturn from "redux/types/InitRequestDataReturn";

const initialState: SpacesSliceState = {
  spaces: [],
  status: {
    fetch: "idle",
  },
};

export const getSpaces = createAsyncThunk(
  "space/getSpaces",
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await spacesSliceService.fetchSpaces(jwtToken);

    return response;
  }
);

const spacesSlice = createSlice({
  name: "spaces",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpaces.pending, (state) => {
      state.status.fetch = "loading";
    });
    builder.addCase(
      getSpaces.fulfilled,
      (state, action: PayloadAction<ISpaceInfo[]>) => {
        state.status.fetch = "succeeded";
        state.spaces = action.payload;
      }
    );
  },
});

const selectSpacesState = (state: RootState) => state.Spaces;
export const selectSpaces = createSelector(
  selectSpacesState,
  (state) => state.spaces
);
export const selectSpacesFetchStatus = createSelector(
  selectSpacesState,
  (state) => state.status.fetch
);

export default spacesSlice.reducer;

import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material"

const SkeletonBody = () => (
  <TableBody>
    <TableRow>
      <TableCell sx={{ width: '110px' }}>
        <Skeleton variant="rectangular"></Skeleton>
      </TableCell>
      <TableCell sx={{ width: '200px' }}>
        {' '}
        <Skeleton variant="rectangular"></Skeleton>
      </TableCell>
      <TableCell sx={{ width: '100%' }}>
        {' '}
        <Skeleton variant="rectangular"></Skeleton>
      </TableCell>
    </TableRow>
  </TableBody>
)

export default SkeletonBody
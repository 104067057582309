import { Box, Button } from "@mui/material";
import { t } from "i18next";
import React, { useContext, useState } from "react";
import "./SaveChanges.css";
import { SecurityContext } from "features/Views/Security/context/securityContext";
import { SpinnerIcon } from "components";

const SaveChanges = () => {
  const { saveSecurity } = useContext(SecurityContext);
  const [isLoading, setIsLoading] = useState(false);

  const onSaveChanges = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await saveSecurity();
    setIsLoading(false);
  };

  return (
    <>
      <Box id="panel" className="header">
        <Box className="profile-panel-content">
          <Box className="float-right m-2">
            <Button
              disabled={isLoading}
              startIcon={<SpinnerIcon isLoading={isLoading} />}
              type="submit"
              size="small"
              variant="contained"
              onClick={onSaveChanges}
            >
              {t("global.saveChanges")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SaveChanges;

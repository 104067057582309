import { useState, useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateWorkspace } from 'redux/features/workspace/workspaceSlice';
// Components/ui
import { EditWorkspacePanel } from 'components';
import { EraseWorkspaceDialog } from './components';
import { StyleContext } from 'style/styleProvider';
import { Typography, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Types
import { ISpace } from '@trii/types/dist/Spaces';
import type { UpdateWorkspaceParam } from 'redux/features/workspace/types/UpdateWorkspaceParam';
import UserType from '@trii/types/dist/Users/UserType';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';

type Props = {
  workspace: ISpace;
};

const EraseWorkspacePanel = ({ workspace }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fontSize } = useContext(StyleContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);

  const buttonText =
    workspace?.status !== 4
      ? t('workSpaces.deleteWorkspace')
      : t('delete.workspaceCancelDeletion');

  const deletionWarning = workspace?.status === 4 && t('delete.deletionWarning');

  const cancelDeletion = async () => {
    setIsLoading(true);

    const data: UpdateWorkspaceParam = { id: workspace.id, status: 3 };
    await dispatch(updateWorkspace(data));

    setIsLoading(false);
  };

  const onButtonClick = () => {
    workspace.status !== 4 ? setOpenDeleteDialog(true) : cancelDeletion();
  };

  return (
    <>
      <EraseWorkspaceDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
      />
      <EditWorkspacePanel title={t('workSpaces.workspaceDeletion')}>
        <form className="profile-form-group">
          <Box
            className="profile-panel-content"
            display="flex"
            justifyContent="space-between"
          >
            <Typography fontSize={fontSize.sm} maxWidth="45%">
              {deletionWarning}
            </Typography>
            <Box alignSelf={'center'}>
              <LoadingButton
                color="error"
                size={'small'}
                variant="contained"
                disabled={isLoading || user.userType !== UserType.OWNER}
                loading={isLoading}
                onClick={onButtonClick}
              >
                {buttonText}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </EditWorkspacePanel>
    </>
  );
};

export default EraseWorkspacePanel;

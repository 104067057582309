import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersContext } from 'features/Views/Users/components/TableBody/components/UsersPanel/context/UsersContext';
import { StyleContext } from 'style/styleProvider';
//redux
import { useSelector } from 'react-redux';
import {
  selectAllUsers,
  getUsersFetchStatus,
} from 'redux/features/usersSlice/usersSlice';
//components/ui
import { UserListItemSkeleton, UserListItem } from './components';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';

export default function UsersList() {
  const { t } = useTranslation();

  const { buscando, dataBusqueda } = useContext(UsersContext);
  const { fontSize } = useContext(StyleContext);

  const users = useSelector(selectAllUsers);
  const fetchStatus = useSelector(getUsersFetchStatus);

  const filteredUsers = dataBusqueda.map((user) => (
    <UserListItem user={user} key={user.id} />
  ));

  const usersList =
    fetchStatus === 'loading'
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((user) => (
          <UserListItemSkeleton key={user} />
        ))
      : users.map((user, key) => <UserListItem user={user} key={key} />);

  return (
    <List
      sx={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      {buscando && dataBusqueda.length > 0 ? (
        filteredUsers
      ) : dataBusqueda.length === 0 && buscando ? (
        <ListItem>
          <Typography fontSize={fontSize.md}>{t('global.noResults')}</Typography>
        </ListItem>
      ) : (
        usersList
      )}
    </List>
  );
}

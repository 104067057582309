import { useContext } from 'react';
import { UserAvatar } from './components';
import { useTranslation } from 'react-i18next';
import { DataContext } from 'context/contextApi';
import { StyleContext } from 'style/styleProvider';
// components/ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PopupDelete from 'components/PopupDelete';
import Spinner from 'react-bootstrap/Spinner';

export default function Header({ isEditing, setIsEditing, isLoading }) {
  const { t } = useTranslation();
  const { mediaQuery } = useContext(StyleContext);
  const { setOpenPopup } = useContext(DataContext);
  const buttonFontSize = mediaQuery.xl ? null : 10;
  const headerHeight = mediaQuery.lg ? '20%' : '25%';

  const startIcon = isLoading ? (
    <Spinner
      style={{
        height: '1rem',
        width: '1rem',
        fontSize: '12px',
      }}
      animation="border"
    />
  ) : isEditing ? (
    <SaveIcon />
  ) : (
    <ModeEditOutlineOutlinedIcon />
  );

  const handleEditButton = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };
  const cancelEditProfile = () => {
    setIsEditing(false);
  };

  const openDeletePopup = () => {
    setOpenPopup(true);
  };
  const ConfirmDelete = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: 2,
          paddingRight: 2,
          py: 1.5,
          height: headerHeight,
          backgroundColor: 'dropdownBox',
          boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
          borderRadius: 1,
        }}
      >
        <UserAvatar isEditing={isEditing} />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          {/* Este botón actua condicionalmente como submit del profile-form del componente Body > DetailsTab */}
          <Button
            type="submit"
            form="profile-form"
            size={'small'}
            sx={{ fontSize: buttonFontSize }}
            startIcon={startIcon}
            variant="outlined"
            disabled={isLoading}
            onClick={isEditing ? null : handleEditButton}
          >
            {isEditing ? t('global.saveChanges') : t('global.editProfile')}
          </Button>
          {isEditing ? (
            <>
              <Button
                size={'small'}
                sx={{ fontSize: buttonFontSize }}
                // startIcon={<DeleteOutlinedIcon />}
                variant="outlined"
                color="error"
                onClick={cancelEditProfile}
              >
                {t('global.cancel')}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <PopupDelete
        title={`Eliminar a ${'selectedUser.firstName'}`}
        subtitle="Se eliminarán todos los datos del usuario"
        onDelete={ConfirmDelete}
      ></PopupDelete>
    </>
  );
}

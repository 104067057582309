import axios from 'axios';
import { ApiKey, ApiPrivilege } from '@trii/types/dist/PublicAPI/apiKey';
import getRequestConfig from '../../functions/getRequestConfig';
import { APIKeysState } from './types/APIKeysState';


const convertToApiFormat = (privileges: ApiPrivilege) => ({
  messages: privileges.Messages,
  contacts: privileges.Contacts,
});


const fetchAPIKeys = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/ApiKeys`, config);

  return response.data;
};

const fetchAPIKeyId = async (jwt: string, URL: string, apiKey: ApiKey) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/ApiKeys/${apiKey.id}`, config);

  return response.data;
};

const createAPIKey = async (jwt: string, URL: string, apiKey: ApiKey) => {
  
  const formattedApiKey = {
    ...apiKey,
    privileges: convertToApiFormat(apiKey.privileges),  // Aquí haces la conversión
  };
  const config = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post(`${URL}/apikeys`, formattedApiKey, config)
;
  return response.data;
};

const deleteAPIKey = async (jwt: string, URL: string, id: string, name: string) => {
  const config = getRequestConfig.basic(jwt);

  await axios.delete(`${URL}/ApiKeys/${id}`, {
    ...config,
    data: { name }
  });

  return id;
};

const updateAPIKey = async (jwt: string, URL: string, apiKey: ApiKey) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const apiKeyJson = JSON.stringify(apiKey);

  const response = await axios.put(`${URL}/ApiKeys/${apiKey.id}`, apiKeyJson, config);
  return response.data;
};

const apiKeyService = {
  fetchAPIKeys,
  createAPIKey,
  deleteAPIKey,
  updateAPIKey,
  fetchAPIKeyId
};

export default apiKeyService;
import React, { useContext, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
import { UsersContext } from '../../../../../../context/UsersContext';
//components/ui
import { AplicationsTab, InfoTabsHeader } from './components';
import { DetailsTab, TabPanel } from 'components';
import Divider from '@material-ui/core/Divider';
import { Box } from '@mui/material';

export default function Body({ user }) {
  const [value, setValue] = useState(0);
  const { mediaQuery } = useContext(StyleContext);
  const { handleSaveButton, isEditing } = useContext(UsersContext);
  const bodyHeight = mediaQuery.xl ? '80%' : mediaQuery.lg ? '79%' : '75%';

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius="2px"
      height={bodyHeight}
      px={1}
      py={1.5}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
          px: 2,
        }}
      >
        <InfoTabsHeader setTabUserProfile={setValue} tabUserProfile={value} />
        <Divider />
        <TabPanel tabValue={value} index={0}>
          <DetailsTab
            user={user}
            isEditing={isEditing}
            formId={'users-form'}
            handleUpdateUser={handleSaveButton}
          />
        </TabPanel>
        <TabPanel tabValue={value} index={1}>
          <AplicationsTab user={user}/>
        </TabPanel>
      </Box>
    </Box>
  );
}

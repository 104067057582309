import React from "react";
//redux
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  selectAllRoles,
  deleteRole,
} from "redux/features/roleSlice/roleSlice";
//components/ui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SpinnerIcon } from "components";
import { Box, Button, FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalDeleteRole = ({ isOpen, name, onCancel, roleId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roles = useSelector(selectAllRoles);
  const filteredRoles = roles.filter((role) => role.id !== roleId);
  const [newUsersRoleId, setNewUsersRoleId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAcceptDelete = async () => {
    setIsLoading(true);
    await dispatch(deleteRole({ roleId, newUsersRoleId }));
    setIsLoading(false);
    onCancel();
  };

  const renderMenuItems = filteredRoles.map((role) => (
    <MenuItem key={role.id} value={role.id}>
      {role.title}
    </MenuItem>
  ));

  const handleChangeRole = (event) => {
    setNewUsersRoleId(event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-popup-title">
        {t("roles.deleteRole")} '{name}'
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("roles.deleteRoleDescription")} '{name}'
        </DialogContentText>
      </DialogContent>
      <Box sx={{ display: "flex", flexDirection: "column", px: 3, pb: 2 }}>
        <FormControl fullWidth>
          <Select
            value={newUsersRoleId}
            label="Role"
            onChange={handleChangeRole}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          >
            {renderMenuItems}
          </Select>
        </FormControl>
      </Box>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          {t("global.cancel")}
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleAcceptDelete}
          color="error"
          startIcon={<SpinnerIcon isLoading={isLoading} />}
        >
          {t("global.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeleteRole;

import React from 'react';
import { Header, Body } from './components';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';

const TriiUserProfile = () => {
  const user = useSelector(selectUser);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  if (user.uid === '') {
    return null;
  }

  return (
    <>
      <Header
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isLoading={isLoading}
      />
      <Body
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default TriiUserProfile;

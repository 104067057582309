import React, { useContext } from "react";
import { RolesContext } from "features/Views/Users/components/TableBody/components/RolesPanel/context/rolesContext";
//components/ui
import {
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const BranchOptions = ({ role }) => {
  const {
    handleSelectCreateRole,
    handleOpenDeletePopup,
    handleOpenDeleteErrorPopup,
  } = useContext(RolesContext);

  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "inherit",
          flexGrow: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {role.title}
      </Typography>
      <Box className="profile-showButtonsRoles">
        <IconButton
          style={{ alignSelf: "center", marginRight: "5px" }}
          color="primary"
          size={"small"}
          onClick={(e) => handleSelectCreateRole(e, role)}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          style={{ alignSelf: "center", marginRight: "5px" }}
          color="error"
          size={"small"}
          onClick={
            role.children.length === 0
              ? handleOpenDeletePopup
              : handleOpenDeleteErrorPopup
          }
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BranchOptions;

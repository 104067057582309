// Router
import { useNavigate } from "react-router-dom"
// Translations
import { useTranslation } from "react-i18next"
import { Button, Typography } from "@mui/material"

interface Props {
  title: string;
  subtitle: string;
  image: string;
}

const Layout = ({
  title,
  subtitle,
  image,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate("/a/settings/dashboard")
  }

  return (
    <>
      <Typography
        variant="h2"
        sx={{
          color: "text.primary",
          fontWeight: "bold",
        }}
      >
        {t(title)}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: "text.disabled",
        }}
      >
        {t(subtitle)}
      </Typography>
      <img
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "500px",
          maxHeight: "500px",
        }}
        src={image}
        alt="403"
      />
      <Button
        variant="contained"
        onClick={handleGoBack}
      >
        {t("error.goBack")}
      </Button>
    </>
  )
}

export default Layout
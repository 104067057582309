import React, { useContext } from 'react';
import { StyleContext } from 'style/styleProvider';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import {
  fetchDeletePhoto,
  selectUser,
  setUser,
  updateUserPhoto,
  selectDeletePhotoStatus,
} from 'redux/features/userSlice/userSlice';
// Components/ui
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ImageSelect } from 'components';
import { Avatar, CircularProgress } from '@mui/material';
import { style } from 'components/ImageSelect/style';
// Utils
// import { readAndCompressImage } from 'browser-image-resizer';
import { readAndCompressImage } from 'browser-image-resizer';

interface UserAvatarProps {
  isEditing: boolean;
}

export default function UserAvatar({ isEditing }: UserAvatarProps) {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const deletePhotoStatus = useSelector(selectDeletePhotoStatus);
  const isLoading = deletePhotoStatus === 'loading';
  const { fontSize, mediaQuery } = useContext(StyleContext);
  const [isHover, setIsHover] = React.useState(false);
  const [savingImage, setSavingImage] = React.useState(false);
  const [userImage, setUserImage] = React.useState('');

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleImageChange = async (event) => {
    const newImage = event.target.files[0];
    setSavingImage(true);

    try {
      await dispatch(updateUserPhoto(newImage));
      await dispatch(setUser());
      setSavingImage(false);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleImageChange = async (event) => {
  //   const newImage = event.target.files[0];
  //   setSavingImage(true);

  //   try {
  //     const config = {
  //       quality: 0.7,
  //       maxWidth: 800,
  //       maxHeight: 600,
  //       autoRotate: true,
  //       debug: true,
  //     };
  //     const compressedImage = await readAndCompressImage(newImage, config);

  //     await dispatch(updateUserPhoto(compressedImage));
  //     await dispatch(setUser());
  //     setSavingImage(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleDeleteImage = async () => {
    try {
      await dispatch(fetchDeletePhoto());
      setUserImage('');
    } catch (err) {
      console.log(err);
    }
  };

  const inputImage = (
    <>
      <Avatar
        src={userImage}
        alt={user.display_name}
        sx={isEditing ? style.avatarEditing : style.avatar}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {user.display_name?.charAt(0) || user.email?.charAt(0)}
      </Avatar>
      {savingImage ||
        (isLoading && (
          <Box sx={style.avatarUpdateIcon}>
            <CircularProgress size={30} />
          </Box>
        ))}
    </>
  );

  React.useEffect(() => {
    if (user) {
      setUserImage(user?.imageUrl);
    }
  }, [user]);

  return (
    <Box display="flex" alignItems="center" my={'auto'}>
      <ImageSelect
        isHover={isHover}
        isEditing={isEditing}
        savingImage={savingImage || isLoading}
        setSavingImage={setSavingImage}
        handleImageChange={handleImageChange}
        handleDeleteImage={handleDeleteImage}
      >
        {inputImage}
      </ImageSelect>
      <ListItem>
        <ListItemText
          primary={
            <Typography
              color="text.primary"
              fontSize={fontSize.md}
              fontWeight={'bold'}
            >
              {user.display_name}
            </Typography>
          }
          secondary={
            <Typography fontSize={fontSize.sm} color="text.primary">
              {user.email}
            </Typography>
          }
        />
      </ListItem>
    </Box>
  );
}

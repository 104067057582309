import React, { useMemo } from "react";
//components/ui
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

const NamedSwitch = ({ isEditing, name, stateHandler }) => {
  return useMemo(() => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ width: 420 }}
      >
        <Typography sx={{ color: "text.primary", fontSize: "0.925rem" }} minWidth={150}>
          {name}
        </Typography>
        <Switch disabled={!isEditing} {...stateHandler} />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, stateHandler.checked]);
};

export default NamedSwitch;

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getMobileStyle = (isMobile: boolean) => {
  return {
    width: isMobile ? '100%' : '',
    flexColumn: isMobile ? 'column' : 'row',
    padding: isMobile ? '10px' : '',
    paddingLeft0: isMobile ? 'pl-0' : '',
    minWidth: isMobile ? 'min-width: calc(100vw - 108px)' : '',
  };
};

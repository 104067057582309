import { useTranslation } from 'react-i18next';
//components/ui
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { set_sessions_idleSessionTimeout } from 'redux/features/securitySlice/securitySlice';
interface ISessionTimeoutOption {
  value: number;
  title: string;
  description: string;
  list: { value: number; label: string }[];
}

const Selector = ({ value, title, description, list }: ISessionTimeoutOption) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  return (
    <Box
      id=""
      className="profile-panel-inner"
      sx={{ backgroundColor: 'background.panel', border: '0px' }}
    >
      <Box className="profile-panel-container">
        <Box className="profile-panel-content">
          <Box className="profile-d-flex profile-justify-content-between profile-align-items-center">
            <Box className="profile-d-flex profile-align-items-center ml-3 mr-auto">
              <Box>
                <Typography
                  variant="subtitle2"
                  className="h6"
                  sx={{ color: 'text.primary', fontSize: '1rem' }}
                >
                  {t(title)}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.primary' }}>
                  {t(description)}
                </Typography>
              </Box>
            </Box>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100px' },
                display: 'flex',
                alignItems: 'center',
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl sx={{ m: 1, minWidth: 100 }}>
                <Select
                  value={value}
                  onChange={(event: SelectChangeEvent<number | string>) => {
                    dispatch(
                      set_sessions_idleSessionTimeout(event.target.value as number)
                    );
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                  size="small"
                >
                  {list.map((option, i) => (
                    <MenuItem key={option.value} value={option.value}>
                      {t(option.label, { number: option.value })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Selector;

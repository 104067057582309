import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import workspaceService from './workspaceService';
import { ISpace } from '@trii/types/dist/Spaces';
import { RootState } from 'redux/store';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import type { WorkspaceSlice } from './types/WorkspaceSlice';
import type { UpdateWorkspaceParam } from './types/UpdateWorkspaceParam';
import type { CreateVerifyCustomDomainParams } from './types/CreateVerifyCustomDomainParams';

const initialState: WorkspaceSlice = {
  workspace: null,
  status: { fetch: 'idle', update: 'idle' },
};

export const fetchWorkspace = createAsyncThunk(
  'Workspace/fetchWorkspace',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SPACES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const workspace = await workspaceService.getWorkspace(jwtToken, URL_SPACES);

    return workspace;
  }
);

export const updateWorkspace = createAsyncThunk(
  'Workspace/updateWorkspace',
  async (workspaceData: UpdateWorkspaceParam, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const workspaceDataJSON = JSON.stringify(workspaceData);
    const updatedWorkspace = await workspaceService.updateWorkspace(
      jwtToken,
      workspaceDataJSON
    );

    return updatedWorkspace;
  }
);

export const updateWorkspacePhoto = createAsyncThunk(
  'Workspace/updateWorkspacePhoto',
  async (photo: File, { dispatch }) => {
    const { jwtToken, URL_SPACES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const formData = new FormData();
    formData.append('photo', photo, photo.name);

    const updatedWorkspace = await workspaceService.updateWorkspacePhoto(
      jwtToken,
      URL_SPACES,
      formData
    );

    return updatedWorkspace;
  }
);

export const deleteCustomDomain = createAsyncThunk(
  'Workspace/deleteCustomDomain',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SPACES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const updatedWorkspace = await workspaceService.deleteCustomDomain(
      jwtToken,
      URL_SPACES
    );

    return updatedWorkspace;
  }
);

export const createVerifyCustomDomain = createAsyncThunk(
  'Workspace/createVerifyCustomDomain',
  async (data: CreateVerifyCustomDomainParams, { dispatch }) => {
    const { jwtToken, URL_SPACES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const dataJSON = JSON.stringify(data);
    const response = await workspaceService.createVerifyCustomDomain(
      jwtToken,
      URL_SPACES,
      dataJSON
    );

    return response;
  }
);

export const deleteWorkspace = createAsyncThunk(
  'Workspace/deleteWorkspace',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SPACES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await workspaceService.deleteWorkspace(jwtToken, URL_SPACES);

    return response;
  }
);

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWorkspace.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWorkspace.fulfilled, (state, action: PayloadAction<ISpace>) => {
        state.workspace = action.payload;
        state.status.fetch = 'succeeded';
      })
      .addCase(updateWorkspace.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateWorkspace.fulfilled, (state, action: PayloadAction<ISpace>) => {
        state.workspace = action.payload;
        state.status.update = 'idle';
      })
      .addCase(updateWorkspacePhoto.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateWorkspacePhoto.fulfilled, (state, action) => {
        console.log('Updated workspace photo: ', action.payload);

        state.status.update = 'idle';
        state.workspace = action.payload;
      })
      .addCase(createVerifyCustomDomain.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.hasOwnProperty('id')) {
          state.workspace = response as ISpace;
        }
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        state.workspace = action.payload;
      });
  },
});

const workspaceState = (state: RootState) => state.Workspace;
export const selectWorkpace = createSelector(
  workspaceState,
  (space) => space.workspace
);

// Selectors

// Actions

export default workspaceSlice.reducer;

import React, { createContext, useEffect, useState } from 'react';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
// Redux
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAPIKeys,
  createAPIKey,
  deleteAPIKey,
  fetchAPIKeyId,
  updateAPIKey,
  selectAllAPIKeys,
  selectAPIKeysFetchStatus,
  selectAPIKeyCreateStatus,
  selectAPIKeyDeleteStatus,
  selectAPIKeyUpdateStatus,
} from 'redux/features/APIKeysSlice/APIKeysSlice';

// Types
import { ApiKeysContext, ApiKeysProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';
import { ApiKey, ApiPrivilege } from '@trii/types/dist/PublicAPI/apiKey';
import { UserInfo } from '@trii/types/dist/Users';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const InitApiKeysContext = createContext<ApiKeysContext>({
  privileges: {
    Messages: false,
    Contacts: false,
  },
  setPrivileges: () => {},
  secret: '',
  setSecret: () => {},
  apiKeyData: null,
  setApiKeyData: () => {},
  deletePanelOpen: false,
  editPanelOpen: false,
  createPanelOpen: false,
  createApiKeyNameField: fieldInitialDef,
  createApiKeyUsers: [],
  setCreateApiKeyUsers: () => {},
  deleteApiKeyNameField: fieldInitialDef,
  editApiKeyNameField: fieldInitialDef,
  editApiKeyUsers: [],
  setEditApiKeyUsers: () => {},
  setDeletePanelOpen: () => {},
  setCreatePanelOpen: () => {},
  openCreatePanel: () => {},
  closeCreatePanel: () => {},
  resetCreateFields: () => {},
  sendApiKey: async () => {},
  openDeletePanel: () => {},
  closeDeletePanel: () => {},
  sendDeleteApiKey: async () => {},
  closeEditPanel: () => {},
  setEditPanelOpen: () => {},
  openEditPanel: () => {},
  sendEditedApiKey: async () => {},
  modalSuccess: false,
  setModalSuccess: () => {},
  fetchStatus: 'idle',
  createStatus: 'idle',
  deleteStatus: 'idle',
  updateStatus: 'idle'

});

const ApiKeysProvider = ({ children }: ApiKeysProviderProps) => {
  const dispatch = useAppDispatch()
  
  // States
  const [createPanelOpen, setCreatePanelOpen] = useState(false);
  const [deletePanelOpen, setDeletePanelOpen] = useState(false);
  const [editPanelOpen, setEditPanelOpen] = useState(false);
  const createApiKeyNameField = useField('text');
  const [createApiKeyUsers, setCreateApiKeyUsers] = useState<string[]>([]);
  const [privileges, setPrivileges] = useState({
    Messages: false,
    Contacts: false,
  });
  const deleteApiKeyNameField = useField('text');
  const editApiKeyNameField = useField('text');
  const [editApiKeyUsers, setEditApiKeyUsers] = useState<string[]>([]);
  const [secret, setSecret] = useState<string | null>(null);
  const [apiKeyData, setApiKeyData] = useState<ApiKey | null>(null);
  const [modalSuccess, setModalSuccess] = useState(false);


  // Status selectors
  const apiKeyStatus = useSelector(selectAPIKeyCreateStatus);
  const fetchStatus = useSelector(selectAPIKeysFetchStatus);
  const createStatus = useSelector(selectAPIKeyCreateStatus);
  const deleteStatus = useSelector(selectAPIKeyDeleteStatus);
  const updateStatus = useSelector(selectAPIKeyUpdateStatus);

  // Functions to handle API actions
  //CREATE API KEY 
  const openCreatePanel = () => {
    setCreatePanelOpen(true);
    setDeletePanelOpen(false);
    setEditPanelOpen(false);
  };

  const closeCreatePanel = () => {
    resetCreateFields();
    setCreatePanelOpen(false);
  };

  const resetCreateFields = () => {
    createApiKeyNameField.actions.resetValue();
    setPrivileges({
      Messages: false,
      Contacts: false,
    });
  };

  const sendApiKey = async () => {
  
    const response = await dispatch(
      createAPIKey({
        id: '',
        name: createApiKeyNameField.attributes.value as string,
        privileges,
        spaceId: '',
        createdBy: new Date, 
        createdAt: new Date,
      })
    );
    if (response.payload?.secret) {
      setSecret(response.payload.secret);
    }
  };
  useEffect(() => {
    if (apiKeyStatus === 'succeeded' && secret) {
      setModalSuccess(true); // Only open the modal after secret is set
    }
  }, [apiKeyStatus, secret]);
  //DELETE API KEY 
  const openDeletePanel = (apiKeyData: ApiKey) => {
    setApiKeyData(apiKeyData);
    setDeletePanelOpen(true);
    setCreatePanelOpen(false);
    setEditPanelOpen(false);
  };

  const closeDeletePanel = () => {
    deleteApiKeyNameField.actions.resetValue();
    setDeletePanelOpen(false);
  };

  const sendDeleteApiKey = async (apiKeyId) => {
    await dispatch(deleteAPIKey(apiKeyId));
  };

  //UPDATE API KEY 
  
  const openEditPanel = async (apiKeyId: ApiKey) => {
    try {
      // Obtén los datos de la API key utilizando fetchAPIKeyId
      const response = await dispatch(fetchAPIKeyId(apiKeyId));
  
      if (response.payload) {
        const apiKeyData = response.payload;

        // Actualiza los estados locales con los datos obtenidos
        setApiKeyData(apiKeyData);
        editApiKeyNameField.actions.setValueDirect(apiKeyData.name);
        setPrivileges({
          Messages: apiKeyData.privileges.messages, 
          Contacts: apiKeyData.privileges.contacts, 
        });
        setEditPanelOpen(true);
        setCreatePanelOpen(false);
        setDeletePanelOpen(false);
      }
    } catch (error) {
      console.error('Error fetching API key:', error);
    }
  };

  const closeEditPanel = () => {
    resetEditFields();
    setEditPanelOpen(false);
  };

  const resetEditFields = () => {
    editApiKeyNameField.actions.resetValue();
    setEditApiKeyUsers([]);
  };

  const sendEditedApiKey = async (apiKeyData: ApiKey) => {  
    await dispatch(updateAPIKey(apiKeyData));
    closeEditPanel();
  };

  return (
    <InitApiKeysContext.Provider
      value={{
        sendEditedApiKey,
        openEditPanel,
        editPanelOpen,
        setEditPanelOpen,
        closeEditPanel,
        sendDeleteApiKey,
        closeDeletePanel,
        deleteApiKeyNameField,
        openDeletePanel,
        deletePanelOpen,
        setDeletePanelOpen,
        createApiKeyNameField,
        createApiKeyUsers,
        setCreateApiKeyUsers,
        resetCreateFields,
        sendApiKey,
        createPanelOpen,
        setCreatePanelOpen,
        openCreatePanel,
        closeCreatePanel,
        editApiKeyNameField,        
        editApiKeyUsers,
        setEditApiKeyUsers,
        fetchStatus,
        createStatus,
        deleteStatus,
        updateStatus,
        privileges,
        setPrivileges,
        secret,
        setSecret,
        apiKeyData,
        setApiKeyData,
        modalSuccess,
        setModalSuccess
      }}
    >
      {children}
    </InitApiKeysContext.Provider>
  );
};

export default ApiKeysProvider;

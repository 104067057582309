/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

import getRequestConfig from "redux/functions/getRequestConfig";

const fetchSpaceInfo = async (jwt: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    "https://account.trii.app/api/v1/MySpace",
    config
  );

  return response;
};

export default {
  fetchSpaceInfo,
};

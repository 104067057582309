import { useEffect, useState, useContext } from 'react';
// @ts-ignore
import dayjs, { Dayjs } from 'dayjs';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { DataContext } from 'context/contextApi';
//Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectLogs,
  fetchLogs,
  setLogDateFilter,
  setLogTypeFilter,
  getLogsStatus,
  setLogs,
} from 'redux/features/auditLogSlice/auditLogSlice';
// Components/ui
import './AuditLog.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Button, Snackbar, Box, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import { getMobileStyle } from './style';
// Components
import { DatePicker, ErrorTypes, TableLogs } from './components';

toast.configure();

const AuditLog = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isMobile } = useContext(DataContext);

  const mobileStyle = getMobileStyle(isMobile);

  const logs = useSelector(selectLogs);
  const logStatus = useSelector(getLogsStatus);

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [typeValue, setTypeValue] = useState<string[]>([]);

  const todayDateNow: Dayjs = dayjs();
  const todayDate: Dayjs = dayjs().startOf('day');

  const [selectedStartDate, setSelectedStartDate] = useState(todayDate);
  const [selectedEndDate, setSelectedEndDate] = useState(todayDateNow);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [closeAlert, setCloseAlert] = useState(false);

  const handleChangeFilterType = (event) => {
    const newTypeValue = event.target.value;

    dispatch(setLogTypeFilter(newTypeValue));

    const {
      target: { value },
    } = event;

    setTypeValue(typeof value === 'string' ? value.split(',') : value);
  };

  const applyFilter = async () => {
    dispatch(
      setLogDateFilter({
        start: selectedStartDate.unix(),
        end: selectedEndDate.unix(),
      })
    );
    dispatch(setLogTypeFilter(typeValue));
    dispatch(
      fetchLogs({
        start: selectedStartDate.unix(),
        end: selectedEndDate.unix(),
        type: typeValue.join(''),
      })
    );
  };

  const handleCloseAlert = () => {
    setCloseAlert(true);
  };

  const handleSelect =
    (setState: React.Dispatch<React.SetStateAction<Dayjs>>) => (date: Dayjs) => {
      setState(date);
    };

  useEffect(() => {
    logStatus === 'rejected' && toast.error('No se pudo acceder a los registros');
  }, [logStatus]); //eslint-disable-line

  useEffect(() => {
    if (
      selectedStartDate.isAfter(selectedEndDate) &&
      !selectedStartDate.isSame(selectedEndDate, 'minute')
    ) {
      setError(t('auditLogView.errorDate'));
      setCloseAlert(false);
      setIsError(true);
    } else {
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    applyFilter();

    return () => {
      setSelectedStartDate(todayDate);
      setSelectedEndDate(todayDateNow);
      setTypeValue([]);

      dispatch(setLogTypeFilter([]));
      dispatch(
        setLogDateFilter({ start: todayDate.unix(), end: todayDateNow.unix() })
      );
      dispatch(setLogs([]));
    };
  }, []);

  return (
    <Box
      sx={{
        margin: theme.spacing(1),
      }}
    >
      <Box
        className="overflow-auto api-custom-scroll"
        sx={{
          maxHeight: 'calc(100vh - 120px)',
          paddingRight: isMd ? '10px' : '0px',
        }}
      >
        <Box sx={{ margin: 16, marginTop: 0 }}>
          <Box
            id="AccountProfileInfoPanel"
            className="profile-panel"
            sx={{
              //@ts-ignore
              backgroundColor: (theme) => theme.palette.background.panel,
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          >
            <Box
              className={
                !isMd
                  ? 'profile-panel-hdr profile-panel-hdr-xs'
                  : 'profile-panel-hdr'
              }
              sx={{ justifyContent: 'space-between', paddingY: '5px' }}
            >
              <Box
                className={
                  !isMd
                    ? 'profile-d-flex profile-align-items-center pl-0'
                    : 'profile-d-flex profile-align-items-center'
                }
                sx={{ flexDirection: mobileStyle.flexColumn }}
              >
                {/* Datepicker */}
                <DatePicker
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  handleSelectStart={handleSelect(setSelectedStartDate)}
                  handleSelectEnd={handleSelect(setSelectedEndDate)}
                  isError={isError}
                />
                {/* Tipos */}
                <ErrorTypes
                  typeValue={typeValue}
                  handleChangeFilterType={handleChangeFilterType}
                />
              </Box>
              <Button
                variant="contained"
                sx={{ marginRight: '16px' }}
                onClick={applyFilter}
                disabled={isError}
              >
                {t('filter.apply')}
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={isError && !closeAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{
                width: '100%',
              }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
          <TableLogs logs={logs} logStatus={logStatus} />
        </Box>
      </Box>
    </Box>
  );
};

export default AuditLog;

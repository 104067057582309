import React, { useEffect } from 'react';
//components/ui
import { Box } from '@mui/material';
// Components
import { TableBody, TableHead } from './components';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchRoles } from 'redux/features/roleSlice/roleSlice';
import { fetchProfiles } from 'redux/features/profilesSlice/profilesSlice';
import { setSelectedUser } from 'redux/features/usersSlice/usersSlice';
export default function UserPanel() {
  const dispatch = useAppDispatch();

  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchProfiles());

    return () => {
      setTab(0);
      dispatch(setSelectedUser(null));
    };
  }, []);

  return (
    <Box
      display="flex"
      width="100%"
      height={'100%'}
      maxHeight={{ md: 'calc(100vh - 115px)' }}
      flexDirection={'column'}
      gap="0.5rem"
      className={'profile-fadeinup'}
    >
      <Box className={'profile-fadeinup'}>
        <TableHead tab={tab} setTab={setTab} />
        <TableBody tab={tab} />
      </Box>
    </Box>
  );
}

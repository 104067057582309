import React, { useContext } from "react";
import { StyleContext } from "style/styleProvider";
//redux
//components/ui
import { LinearProgress, Box, Typography } from "@mui/material";

const PageLoading = () => {
  const { color } = useContext(StyleContext);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: color.slate[700],
          marginBottom: 2,
        }}
      >
        Cargando...
      </Typography>
      <LinearProgress sx={{ width: "20%" }} />
    </Box>
  );
};

export default PageLoading;

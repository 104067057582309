import React, { useContext } from "react";
import { StyleContext } from "style/styleProvider";
import PropTypes from "prop-types";
//components/ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DetailSection = ({ children, sectionTitle }) => {
  const { fontSize } = useContext(StyleContext);

  return (
    <Box>
      <Typography
        fontWeight={"500"}
        fontSize={fontSize.md}
        color="text.primary"
      >
        {sectionTitle}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        py={4}
        flexWrap={"wrap"}
        style={{ gap: 10 }}
        ml={"5%"}
      >
        {children}
      </Box>
    </Box>
  );
};

DetailSection.propTypes = {
  children: PropTypes.node.isRequired,
  sectionTitle: PropTypes.string.isRequired,
};

export default DetailSection;

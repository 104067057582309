import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateWorkspace } from 'redux/features/workspace/workspaceSlice';
// Custom hooks
import useField from 'hooks/useField';
// Components/ui
import { Box, Grid, Button } from '@mui/material';
import { Input } from 'components';
import { LoadingButton } from '@mui/lab';
// Types
import { ISpace } from '@trii/types/dist/Spaces';
import { UpdateWorkspaceParam } from 'redux/features/workspace/types/UpdateWorkspaceParam';

type Props = {
  workspace: ISpace;
};

export default function NameSection({ workspace }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const nameInput = useField('name', workspace?.name);

  const handleSave = async () => {
    const name = nameInput.attributes.value;
    const data = {
      id: workspace.id,
      name
    } as UpdateWorkspaceParam;

    setIsLoading(true);
    await dispatch(updateWorkspace(data));
    setIsLoading(false);
  };

  return (
    <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        sm: 'column',
        md: 'column',
      }}
      alignItems={{
        xs: 'stretched',
        sm: 'center',
        md: 'start',
      }}
      justifyContent={'flex-start'}
      width={1}
      margin={'0 auto'}
      gap={2.5}
      ml={2}
      mt={0.5}
    >
      <Input label={t('global.name')} {...nameInput} />
      <Grid item container xs={12}>
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems={{
            xs: 'stretched',
            sm: 'center',
          }}
          justifyContent={'flex-end'}
          width={1}
          margin={'0 auto'}
        >
          <LoadingButton
            size={'small'}
            variant="contained"
            onClick={handleSave}
            disabled={isLoading}
            loading={isLoading}
          >
            {isLoading ? t('global.saving') : t('global.save')}
          </LoadingButton>
        </Box>
      </Grid>
    </Box>
  );
}

import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
//hooks
import useField from 'hooks/useField';
import { useAppDispatch } from 'hooks/useAppDispatch';
//redux
import { addProfile } from 'redux/features/profilesSlice/profilesSlice';
//components/ui
import DialogTitle from '@mui/material/DialogTitle';
import Input from 'components/Input';
import SpinnerIcon from 'components/SpinnerIcon';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PopupInputCreateProfile = ({ isOpen, title, setOpenPopopCreateProfile }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //form state
  const nameField = useField('text');
  const descriptionField = useField('text');
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    setOpenPopopCreateProfile(false);
  };
  const onAccept = async () => {
    setIsLoading(true);

    await dispatch(
      addProfile({
        name: nameField.attributes.value,
        description: descriptionField.attributes.value,
      })
    );

    setIsLoading(false);
    setOpenPopopCreateProfile(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-popup-title">{title}</DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, pb: 2, gap: 2 }}>
        <Input {...nameField} label={t('global.name')} />
        <Input {...descriptionField} label={t('global.description')} />
      </Box>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          {t('global.cancel')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={onAccept}
          startIcon={isLoading ? <SpinnerIcon isLoading={isLoading} /> : null}
        >
          {t('global.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupInputCreateProfile;

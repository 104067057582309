import React from "react";
import { useTranslation } from "react-i18next";
//components/ui
import { Box, Typography } from "@mui/material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

export default function MessageNoProfileExist() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius="2px"
      height="100%"
      px={3}
      py={1}
    >
      <Box
        display="flex"
        height={"100%"}
        flexDirection={"column"}
        width="100%"
        px={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography variant="h4" sx={{ color: "text.primary" }}>
          {t("profileInfo.profiles")}
        </Typography>
        <Box display="flex">
          <Typography mr={1} sx={{ color: "text.primary" }}>
            {t("profileInfo.messageNoProfileTitle")}
          </Typography>

          <GroupAddOutlinedIcon color="primary" />
        </Box>
        <Typography sx={{ color: "text.primary" }}>
          {t("profileInfo.messageNoProfileSubtitle")}
        </Typography>
      </Box>
    </Box>
  );
}

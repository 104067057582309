import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataContext } from "context/contextApi";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SpinnerIcon from "components/SpinnerIcon";

const PopupDelete = ({ title, subtitle, onDelete, loading }) => {
  const { openDeletePopup, setOpenDeletePopup } = useContext(DataContext);
  const { t } = useTranslation();

  const onConfirm = async () => {
    await onDelete();
    setOpenDeletePopup(false);
  };

  return (
    <Dialog
      open={openDeletePopup}
      onClose={() => setOpenDeletePopup(false)}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
    >
      <DialogTitle id="alert-popup-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-popup-description">
          {subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => setOpenDeletePopup(false)}>
          {t("global.cancel")}
        </Button>
        <Button
          disabled={loading}
          color="error"
          onClick={onConfirm}
          startIcon={<SpinnerIcon isLoading={loading} />}
        >
          {t("global.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDelete;

import React from "react";
import { useTranslation } from "react-i18next";
//components/ui
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const TableHead = ({ setTab, tab }) => {
  const { t } = useTranslation();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeNavTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius={"2px"}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChangeNavTab}>
          <Tab label={t("TabsUsers.users")} {...a11yProps(0)} />
          <Tab label={t("TabsUsers.profiles")} {...a11yProps(1)} />
          <Tab label={t("TabsUsers.roles")} {...a11yProps(2)} />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TableHead;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

//Input component with attributes and placeholder props, better used with useField hook

export default function Input({
  isDisabled,
  label,
  attributes,
  style,
  onKeyPress,
  onBlur,
}) {
  return (
    <Grid container item xs={12}>
      <TextField
        disabled={isDisabled}
        sx={{ bgcolor: isDisabled ? 'main.center' : null }}
        label={label}
        variant="outlined"
        className="fadein"
        fullWidth
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        {...attributes}
        {...style}
        max="5"
      />
    </Grid>
  );
}

Input.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  attributes: PropTypes.object,
};

import React from "react";
import Tab from "@material-ui/core/Tab";

export default function TallTab({ label, ...other }) {
  return (
    <Tab
      style={{
        paddingTop: 20,
        paddingBottom: 11,
      }}
      label={label}
      {...other}
    />
  );
}

import { UserInfo } from '@trii/types/dist/Users';
import axios from 'axios';
// Types
import getRequestConfig from 'redux/functions/getRequestConfig';

async function fetchProfileUsers(URL: string, jwtToken: string, profileId: string) {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserInfo[]>(
    `${URL}/?profileId=${profileId}`,
    config
  );

  return response.data;
}

export default { fetchProfileUsers };

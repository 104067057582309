import React from "react";
import Skeleton from "@mui/material/Skeleton";

const UserListItemSkeleton = () => {
  return (
    <div style={{ gap: 10, padding: 10, display: "flex" }}>
      <Skeleton variant="circular" width={55} height={55} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton variant="text" width={210} height={26} />
        <Skeleton variant="text" width={210} height={22} />
      </div>
    </div>
  );
};

export default UserListItemSkeleton;

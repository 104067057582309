import { createContext } from 'react';
// Utils
import profiles from 'utils/profiles';
import roles from 'utils/roles';
// Types
import type { UsersContextType } from './types/UsersContextType';

export const UsersContext = createContext<UsersContextType>({
  isSaving: false,
  handleEditButton: () => {},
  handleSaveButton: () => {},
  setIsEditing: () => {},
  isEditing: false,
  selectedUser: null,
  modalEditar: false,
  setModalEditar: () => {},
  modalEliminar: false,
  setModalEliminar: () => {},
  dataBusqueda: [],
  setDataBusqueda: () => {},
  buscando: false,
  setBuscando: () => {},
  onHandleClose: () => {},
  tabUsersInfoView: 0,
  SetTabUsersInfoView: () => {},
  profile: profiles,
  setProfile: () => {},
  role: roles,
  setRole: () => {},
  selectedUserId: '',
  setSelectedUserId: () => {},
});

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleContext } from 'style/styleProvider';
//components/ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Info = ({ title, info }) => {
  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'background.panel',
        padding: '0 10px',
        borderRadius: 1,
      }}
      marginTop={1}
    >
      <Typography
        letterSpacing={-0.5}
        fontWeight={'600'}
        minWidth="150px"
        variant="subtitle2"
        color="text.primary"
      >
        {title}
      </Typography>
      <Typography
        fontWeight={'400'}
        width={'60%'}
        color="text.primary"
        variant="subtitle2"
      >
        {info}
      </Typography>
    </Box>
  );
};

Info.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default Info;

import { useState, useEffect } from 'react';
// Types
import { ChangeEvent } from 'react';

export type UseFieldType = {
  actions: {
    onError: (errorMsg: string | JSX.Element) => void;
    onSuccess: () => void;
    resetValue: () => void;
    setValueDirect: (newValue: string | number) => void;
  };
  attributes: {
    error: boolean;
    helperText: string;
    type: string;
    value: string | number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
};

const useField = (type: string, initialValue?: string | number): UseFieldType => {
  initialValue = initialValue || '';
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    setValue(initialValue);

    return () => {
      setValue('');
    };
  }, [initialValue]);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onError = (errorMsg) => {
    setError(true);
    setHelperText(errorMsg);
  };

  const onSuccess = () => {
    setError(false);
    setHelperText('');
  };
  const setValueDirect = (newValue: string | number) => {
    setValue(newValue);
  };
  const resetValue = () => {
    setValue('');
  };

  return {
    actions: { onError, onSuccess, resetValue,setValueDirect },
    attributes: { error, helperText, type, value, onChange },
  };
};

export default useField;

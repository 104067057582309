import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { useContext } from 'react';
import { SecurityContext } from 'features/Views/Security/context/securityContext';
//components/ui
import { Box, TextField, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { set_sessions_concurrentSessions } from 'redux/features/securitySlice/securitySlice';

interface IParallelSessionsOption {
  value: number;
  title: string;
  description: string;
}

const ParallelSessionsOption = ({
  value,
  title,
  description,
}: IParallelSessionsOption) => {
  const dispatch = useAppDispatch();

  const { parallelSessionsControl } = useContext(SecurityContext);
  const { t } = useTranslation();

  return (
    <Box
      id=""
      className="profile-panel-inner"
      mt={2}
      sx={{ backgroundColor: 'background.panel', border: '0px' }}
    >
      <Box className="profile-panel-container">
        <Box className="profile-panel-content">
          <Box className="profile-d-flex profile-justify-content-between profile-align-items-center">
            <Box className="profile-d-flex profile-align-items-center ml-3 mr-auto">
              <Box>
                <Typography
                  variant="subtitle2"
                  className="h6"
                  sx={{ color: 'text.primary', fontSize: '1rem' }}
                >
                  {t(title)}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.primary' }}>
                  {t(description)}
                </Typography>
              </Box>
            </Box>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100px' },
                display: 'flex',
                alignItems: 'center',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                onKeyPress={parallelSessionsControl}
                id="outlined-number"
                type="number"
                value={value}
                onChange={(e) =>
                  dispatch(set_sessions_concurrentSessions(Number(e.target.value)))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                InputProps={{
                  inputProps: { min: 1, max: 30 },
                  'aria-label': 'Without label',
                }}
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParallelSessionsOption;

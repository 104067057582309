// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectDashboardMembers,
  selectDashboardBytes,
  selectDashboardFetchStatus,
} from 'redux/features/dashboardSlice/selectors';
// Components/ui
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
// Slice
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchDashboardInfo } from 'redux/features/dashboardSlice/dashboardSlice';
import { fetchWorkspace, selectWorkpace } from 'redux/features/workspace/workspaceSlice';
import { stringAvatar } from 'redux/functions/stringAvatar';
import UserType from '@trii/types/dist/Users/UserType';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const dashboardMembers = useSelector(selectDashboardMembers);
  const dashboardBytes = useSelector(selectDashboardBytes);
  const dashboardFetchStatus = useSelector(selectDashboardFetchStatus);

  const workspace = useSelector(selectSpaceInfo);

  useEffect(() => {
    dispatch(fetchDashboardInfo());
    dispatch(fetchWorkspace());
  }, []);
  const [userType, setUserType] = useState<string>('');
  const [avatarChild, setAvatarChild] = useState<string>('');

  useEffect(() => {
    if (workspace) {
      // Get the key of the enum value
      const newAvatarChild = stringAvatar(workspace?.name);

      setAvatarChild(newAvatarChild);
    }
  }, [workspace]);
  return (
    <Box className="profile-fadeinup">
      <Box className="profile-row" style={{ justifyContent: 'center' }}>
        <Box className="profile-col-8">
          <Card>

            <Box
              sx={{
                p: { xs: 1, md: 2 },
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.dropdownBox,
              
              }}
            >
              <Box className="d-inline-flex  m-0" sx={{  display: 'flex'}}>
                <Avatar
                  alt={workspace?.name}
                  src={workspace?.imageUrl}
                  style={{
                    width: 64,
                    height: 64,
                    border: '1px solid #ced1d5',
                    borderRadius: '50%',
                  }}
                  children={avatarChild}
                ></Avatar>
                <Box sx={{marginLeft: '25px'}}>
                  <Typography variant="h6" color="text.primary">
                    {workspace?.name}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                   {t("dashboard.owner")}: {workspace?.members.find(member => member.userType === 10)?.email} 
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box
        className="profile-row"
        style={{ justifyContent: 'center', marginTop: 15 }}
      >
        <Box className="profile-col-4">
          <Card>
            <Box
              sx={{
                p: { xs: 2, md: 4 },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.dropdownBox,
              }}
            >
              <Box>
                <Typography gutterBottom>{t("dashboard.quantityUser")}</Typography>
                {dashboardMembers !== null && dashboardFetchStatus !== 'loading' ? (
                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    fontWeight={700}
                    display={'flex'}
                    alignItems={'flex-end'}
                    lineHeight={1}
                  >
                    {dashboardMembers}
                  </Typography>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Box>
          </Card>
        </Box>
        <Box className="profile-col-4">
          <Card>
            <Box
              sx={{
                p: { xs: 2, md: 4 },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.dropdownBox,
              }}
            >
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography gutterBottom>{t("dashboard.diskSpace")}</Typography>
                {dashboardBytes !== null && dashboardFetchStatus !== 'loading' ? (
                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    fontWeight={700}
                    display={'flex'}
                    alignItems={'flex-end'}
                    lineHeight={1}
                  >
                    {(dashboardBytes / 1024 / 1024).toFixed(2)} MB
                  </Typography>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

export const style = {
  avatar: {
    width: "86px",
    height: "86px",
    bgcolor: (theme) => (theme.palette.mode === "dark" ? "#3b3a39" : "#f5f5f5"),
    color: (theme) => theme.palette.text.secondary,
    position: "relative", // Add this to position the icon within the Avatar
    overflow: "visible", // Ensure the icon is visible outside the Avatar's bounds
    border: "1px solid gray",
    "& img": {
      borderRadius: "50%",
    },

  },
  avatarEditing: {
    width: "86px",
    height: "86px",
    bgcolor: (theme) => (theme.palette.mode === "dark" ? "#3b3a39" : "#f5f5f5"),
    color: (theme) => theme.palette.text.secondary,
    position: "relative", // Add this to position the icon within the Avatar
    overflow: "visible", // Ensure the icon is visible outside the Avatar's bounds
    "&:hover": {
      background: "rgba(0, 0, 0, 0.10)",
      cursor: "pointer",
      zIndex: 0,
    },
    border: "1px solid gray",
    "& img": {
      borderRadius: "50%",
    },
    "& img:hover": {
      opacity: 0.5,
    },
  },
  avatarDelete: {
    fontSize: "12px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.10)",
      cursor: "pointer",
    },
  },
  avatarUpdateIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
    // color: "rgb(0 0 0 / 50%)",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
    color: (theme) => (theme.palette.mode === "dark" ? theme.palette.text.secondary : "rgb(0 0 0 / 50%)"),

  },
};

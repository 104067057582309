import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
//redux
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectSecurity,
  updateSecurity,
} from 'redux/features/securitySlice/securitySlice';
// Types
import { ISecurityContext } from './types/SecurityContext';

export const SecurityContext = createContext<ISecurityContext>({
  parallelSessionsControl: () => {},
  handleChangeValue: () => {},
  secondFactorOptions: [],
  saveSecurity: () => {},
  ruleNumber: false,
  ruleSpecialCharacter: false,
  ruleCapitalLetter: false,
  ruleLowercaseLetter: false,
  setRuleNumber: () => {},
  setRuleSpecialCharacter: () => {},
  setRuleCapitalLetter: () => {},
  setRuleLowercaseLetter: () => {},
});

const SecurityProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const security = useSelector(selectSecurity);

  const [ruleNumber, setRuleNumber] = useState<boolean>(false);
  const [ruleSpecialCharacter, setRuleSpecialCharacter] = useState<boolean>(false);
  const [ruleCapitalLetter, setRuleCapitalLetter] = useState<boolean>(false);
  const [ruleLowercaseLetter, setRuleLowercaseLetter] = useState<boolean>(false);

  //session management form state handlers
  const handleChangeValue =
    (setState: React.Dispatch<React.SetStateAction<number | string>>) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      setState(event.currentTarget.value);
    };

  //pw security select items to be mapped and rendered
  const secondFactorOptions = [
    { value: true, label: t('global.required') },
    { value: false, label: t('global.optional') },
  ];

  //session management number parallel sessions field input control
  const parallelSessionsControl = (event) => {
    const pwLength = security.sessions_concurrentSessions.toString().length;
    const pwValue = security.sessions_concurrentSessions;
    const newPwValue = Number(pwValue.toString() + event.key);

    if ((pwLength === 0 && event.key < 1) || event.key === '-' || newPwValue > 30) {
      event.preventDefault();
    }
  };

  //send pw state to API
  const saveSecurity = async () => {
    const data = {
      secondFactorApp: security.secondFactorApp,
      sessions_concurrentSessions: security.sessions_concurrentSessions,
      sessions_idleSessionTimeout: security.sessions_idleSessionTimeout,
    };

    await dispatch(updateSecurity(data));
  };

  return (
    <SecurityContext.Provider
      value={{
        parallelSessionsControl,
        handleChangeValue,
        secondFactorOptions,
        saveSecurity,
        ruleNumber,
        ruleSpecialCharacter,
        ruleCapitalLetter,
        ruleLowercaseLetter,
        setRuleNumber,
        setRuleSpecialCharacter,
        setRuleCapitalLetter,
        setRuleLowercaseLetter,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};

export default SecurityProvider;

import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { UserTrii } from '@trii/types/dist/Users';

const fetchUserById = async (
  jwtToken: string,
  URL_SETTINGS: string,
  userId: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(`${URL_SETTINGS}/${userId}`, config);

  return response.data;
};

const resendInvite = async (
  jwtToken: string,
  URL_SETTINGS: string,
  userId: string
): Promise<void> => {
  const config = getRequestConfig.basic(jwtToken);

  await axios.post(`${URL_SETTINGS}/users/${userId}/sendInvitation`, null, config);
};

export default { fetchUserById, resendInvite };

import React, { useEffect } from "react";
// import logo from '../../../assets/logo.png';
import { useNavigate, useLocation } from "react-router-dom";
// Translation
import { useTranslation } from "react-i18next";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
import "./NavMenu.css";
import {
  Box,
  Divider,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { getSpaces } from "redux/features/spacesSlice/spacesSlice";
// Utils
import { iconsData } from "./utils/iconsData";
import { Theme } from "@mui/system";
// Redux
import { useSelector } from "react-redux";
// Slice
import { selectedTheme } from "redux/features/themeSlice/themeSlice";
import { selectUser } from "redux/features/userSlice/userSlice";
import UserType from "@trii/types/dist/Users/UserType";

type TooltipProps = {
  className?: string;
  title: string;
  placement: "right";
  children: React.ReactElement;
};

const NavMenu = () => {
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const user = useSelector(selectUser);

  const TooltipPers = styled(
    ({ className, children, placement, title, ...props }: TooltipProps) => (
      <Tooltip
        {...props}
        classes={{ popper: className }}
        title={title}
        placement={placement}
      >
        {children}
      </Tooltip>
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      //@ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSpaces());
  }, []);

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
          gap: 2,
          marginTop: 1,
        }}
      >
        {iconsData.map((icon, index) => (
          <React.Fragment key={index}>
            {
              (
                user?.userType === UserType.OWNER ||
                user?.userType === UserType.CO_OWNER ||
                user?.userType === UserType.ADMIN ||
                index === 0
              ) && (
                <TooltipPers title={t(icon.title)} placement="right">
                  <IconButton
                    sx={{
                      color: onSelectedTheme.palette.primary.contrastText,
                      borderRadius: 1,
                      backgroundColor:
                        location.pathname === icon.to
                          ? (theme) => `${theme.palette.grey[200]}30`
                          : "transparent",
                      "&:focus": {
                        outline: "none",
                      },
                      "&:hover": {
                        backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
                        color: onSelectedTheme.palette.primary.contrastText,
                      },
                      marginBottom: index === 0 ? "-8px" : null,
                      width: '1.5em',
                      height: '1.5em',
                    }}
                    disableRipple
                    onClick={() => handleNavigate(icon.to)}
                  >
                    {icon.icon}
                  </IconButton>
                </TooltipPers>
              )
            }
            {index === 0 && (
              <Divider
                variant="middle"
                sx={{
                  // marginBottom: "-8px",
                  width: "80%",
                  height: "2px!important",
                  borderColor: `${onSelectedTheme.palette.divider}!important`,
                  backgroundColor:
                    icon.id === "1"
                      ? `${onSelectedTheme.palette.divider}!important`
                      : null,
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default NavMenu;

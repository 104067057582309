import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Radio,
} from '@mui/material';
// Icons
import { Close } from '@mui/icons-material';
// Utils
import { accountType } from './utils/accountType';
// Types
import UserType from '@trii/types/dist/Users/UserType';
import { UserTrii } from '@trii/types/dist/Users';
// Redux
import {
  selectSelectedUser,
  updatUserType,
} from 'redux/features/usersSlice/usersSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  open: boolean;
  handleClose: () => void;
  user: UserTrii;
}

const ChangeAccountTypeModal = ({ open, handleClose, user }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [userType, setUserType] = useState<UserType>(user.userType);

  const handleSelect = async (value: UserType) => {
    setUserType(value);

    await dispatch(updatUserType(value));

    handleClose();
  };

  useEffect(() => {
    setUserType(user.userType);
  }, [user.userType]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100wh',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: '50%',
            height: 'max-content',
            maxHeight: '80%',
            p: 2,
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              with: '100%',
              height: '3rem',
              borderBottom: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              p: 2,
              height: 'calc(100% - 3rem)',
              width: '100%',
              overflowY: 'auto',
            }}
          >
            {accountType.map((type) => (
              <ListItem
                disablePadding
                key={type.id}
                sx={{
                  borderBottom: '1px solid rgba(0,0,0,0.2)',
                }}
              >
                <ListItemButton onClick={() => handleSelect(type.value)} dense>
                  <ListItemIcon>
                    <Radio
                      checked={type.value === userType}
                      value={type.id}
                      name="radio-buttons"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(type.label)}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeAccountTypeModal;

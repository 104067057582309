import { useEffect } from 'react';
import './styles/styles.css'

interface Props {
  color?: string;
  size?: number;
}

const DotPulseLoader = ({
  color = '#ffffff',
  size = 50,
}: Props) => {

  useEffect(() => {
    document.documentElement.style.setProperty('--dot-pulse-size', `${size}px`)
    document.documentElement.style.setProperty('--dot-pulse-color', color)
  }, [size])

  return (
    <div className="snippet" data-title="dot-pulse">
      <div className="stage">
        <div className="dot-pulse"></div>
      </div>
    </div>
  )
}

export default DotPulseLoader;
import { useEffect, useMemo } from 'react';
// Router 
import { useLocation, useNavigate } from 'react-router-dom';
import Router from './router';
// Redux
import { useSelector } from 'react-redux';
import { selectFetchSpaceInfoStatus } from './redux/features/spaceSlice/spaceSlice';
import {
  selectUser,
  getUser,
  setUserAction,
} from 'redux/features/userSlice/userSlice';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ThemeProvider } from '@mui/material';
import { selectedTheme, toggleTheme } from 'redux/features/themeSlice/themeSlice';
import { createTheme } from '@mui/material';
// Components/ui
import moment from 'moment';
import {
  fetchSpaceInfo,
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrlSettings,
  setSpaceUrlSpaces,
} from './redux/features/spaceSlice/spaceSlice';
// Types
import RootProps from 'types/RootProps';
import './App.css';
import UserType from '@trii/types/dist/Users/UserType';
import MuiComponentsConfig from './style/MuiComponentesConfig';

interface Props {
  rootProps: RootProps;
}
const App = ({ rootProps }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fetchSpaceInfoStatus = useSelector(selectFetchSpaceInfoStatus);
  const user = useSelector(selectUser);
  const themeRedux = useSelector(selectedTheme);
  // const theme = useMemo(() => createTheme(themeRedux), [themeRedux]);
  const theme = useMemo(() => MuiComponentsConfig(themeRedux), [themeRedux]);

  useEffect(() => {
    if (rootProps.spaceInfo) {
      const { spaceInfo, userInfo } = rootProps;

      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceFetchStatus('succeeded'));
      dispatch(setSpaceUrlSettings(spaceInfo.id));
      dispatch(setSpaceUrlSpaces(spaceInfo.id));
      dispatch(setUserAction(userInfo));
    } else {
      dispatch(fetchSpaceInfo());
      // dispatch(getUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user === null && fetchSpaceInfoStatus === 'succeeded') {
      dispatch(getUser());
    }
  }, [fetchSpaceInfoStatus, user, dispatch]);

  let userLang = document.documentElement.lang;
  useEffect(() => {
    userLang === 'En' ? moment.locale('En') : moment.locale('ES');
  }, [userLang]);

  useEffect(() => {
    if (user !== null) {
      dispatch(toggleTheme(user.theme));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user && (
      user?.userType !== UserType.ADMIN &&
      user?.userType !== UserType.OWNER &&
      user?.userType !== UserType.CO_OWNER
    ) && (
        !location.pathname.includes('error') &&
        location.pathname !== '/a/settings/profile' &&
        location.pathname !== '/a/settings/dashboard'
      )) {
      navigate('/a/settings/error/403');
    }
  }, [location, user])

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;

import { useTranslation } from 'react-i18next';
// Components/ui
import { EditWorkspacePanel } from 'components';
import DomainCustomize from './components/DomainCustomize';
import { ISpace } from '@trii/types/dist/Spaces';

type Props = {
  workspace: ISpace;
};

const CustomDomainPanel = ({ workspace }: Props) => {
  const { t } = useTranslation();

  return (
    <EditWorkspacePanel title={t('workSpaces.customDomain')}>
      <DomainCustomize workspace={workspace} />
    </EditWorkspacePanel>
  );
};

export default CustomDomainPanel;

import React, { useContext, useEffect, useState } from "react";
import { StyleContext } from "style/styleProvider";
//redux
import { useSelector } from "react-redux";
import { selectUser, updateUser } from "redux/features/userSlice/userSlice";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
//components/ui
import { AplicationsTab, InfoTabsHeader } from "./components";
import { DetailsTab, TabPanel } from "components";
import Divider from "@material-ui/core/Divider";
import { Box } from "@mui/material";
import { ProfilesContext } from "features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext";
import { fetchProfiles, selectAllProfiles, selectProfileUsers } from "redux/features/profilesSlice/profilesSlice";

export default function Body({ isEditing, setIsEditing, setIsLoading }) {
  const [value, setValue] = useState(0);
  const { mediaQuery } = useContext(StyleContext);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  // const bodyHeight = mediaQuery.xl ? "80%" : mediaQuery.lg ? "79%" : "75%";

  const handleUpdateUser = async (userTrii) => {
    setIsLoading(true);

    await dispatch(updateUser(userTrii));

    setIsLoading(false);
    setIsEditing(false);
  };

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch]);

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor="dropdownBox"
      borderRadius="2px"
      height={"calc(100vh - 235px)"}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      px={1}
      py={1.5}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          width: "100%",
          px: 2,
        }}
      >
        <InfoTabsHeader setTabUserProfile={setValue} tabUserProfile={value} />
        <Divider />
        <TabPanel tabValue={value} index={0}>
          <DetailsTab
            isEditing={isEditing}
            user={user}
            handleUpdateUser={handleUpdateUser}
            formId={"profile-form"}
          />
        </TabPanel>
        <TabPanel tabValue={value} index={1} >
          <AplicationsTab user={user}/>
        </TabPanel>
      </Box>
    </Box>
  );
}

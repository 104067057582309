import axios from 'axios';

const URL_DOMAIN = 'https://account.trii.app/api/v1/Domains/CheckSpace';

export const getSpaceSubdomain = async (subdomain: string) => {
  const subdomainJSON = JSON.stringify({
    auth: 'gy94tpW49fT5kk.$dlworyyfhsaurF734.%',
  });
  const response = await axios.post(URL_DOMAIN, subdomainJSON);

  return response.data;
};
// Components/ui
import { UserItem } from 'components';
import { Box } from '@mui/material';
import RoleDisplay from '../RoleDisplay';
import { TabPanel } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectRoleUsers } from 'redux/features/roleSlice/roleSlice';

export default function RoleTabContents({ tab, role }) {
  const roleUsers = useSelector(selectRoleUsers);

  return (
    <Box height={'100%'}>
      <TabPanel tabValue={tab} index={0}>
        <RoleDisplay role={role} status={'read'} />
      </TabPanel>
      <TabPanel tabValue={tab} index={1}>
        <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
          {roleUsers.map((user, index) => (
            <UserItem
              avatarUrl={user.imageUrl}
              displayName={user.name}
              key={index}
            />
          ))}
        </Box>
      </TabPanel>
    </Box>
  );
}

// Components/ui
import { Typography, Avatar, Box, Divider } from '@mui/material';
interface ProfileUserItemProps {
  avatarUrl: string;
  displayName: string;
}
const UserItem = ({ avatarUrl, displayName }: ProfileUserItemProps) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.20)',
        borderRadius: '10px',
        border: '1px solid',
        //@ts-ignore
        borderColor: (theme) => theme.palette.divider
      }}
      p={1}
      display={'flex'}
      alignItems={'center'}
      gap={2}
    >
      <Avatar src={avatarUrl} />
      <Typography color={'text.primary'}>{displayName}</Typography>
    </Box>
  );
};

export default UserItem;

import axios from 'axios';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
// Types
import { UserInfo, UserTrii } from '@trii/types/dist/Users';
import { UsersSlice } from './types/UsersSlice';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import usersSliceService from './usersSliceService';
import { initRequestData } from 'redux/functions/initRequestData';
import { RootState } from 'redux/store';
import { IAddUser } from './types/IAddUser';
import UserType from '@trii/types/dist/Users/UserType';
import getRequestConfig from 'redux/functions/getRequestConfig';
import { RequestStatus } from 'types/reduxTypes';

const usersAdapter = createEntityAdapter<UserInfo>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: UsersSlice = {
  selectedUser: null,
  status: {
    fetch: 'idle',
    fetchById: 'idle',
    add: 'idle',
    resendInvite: 'idle',
  },
  ...usersAdapter.getInitialState(),
};

export const fetchUsers = createAsyncThunk(
  'Users/fetchUsers',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.get(`${URL_SETTINGS}/users`, config);

    return response.data;
  }
);

export const fetchUserById = createAsyncThunk(
  'Users/fetchUserById',
  async (userId: string, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const userTrii = await usersSliceService.fetchUserById(
      jwtToken,
      `${URL_SETTINGS}/users`,
      userId
    );

    return userTrii;
  }
);

export const addUser = createAsyncThunk(
  'Users/addUser',
  async (user: IAddUser, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.post(`${URL_SETTINGS}/users`, user, config);

    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  'Users/deleteUser',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.delete(`${URL_SETTINGS}/users/${id}`, config);

    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  'Users/updateUser',
  async (user: UserTrii, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const userJson = JSON.stringify(user);

    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put<UserTrii>(
      `${URL_SETTINGS}/users/${user.uid}`,
      userJson,
      config
    );

    return response.data;
  }
);

export const resendInvite = createAsyncThunk(
  'Users/resendInvite',
  async (userId: string, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await usersSliceService.resendInvite(jwtToken, URL_SETTINGS, userId);
  }
);

export const updatUserType = createAsyncThunk(
  'Users/updatUserType',
  async (userType: UserType, { dispatch, getState }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const selectedUserId = state.Users.selectedUser?.uid;

    const config = getRequestConfig.contentTypeJson(jwtToken);

    const response = await axios.post<UserTrii>(
      `${URL_SETTINGS}/users/${selectedUserId}/setUserType`,
      { userType },
      config
    );

    return response.data;
  }
);

export const updateUserPhoto = createAsyncThunk(
  'Users/updateUserPhoto',
  async (data: { userId: string; photo: Blob }, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { photo, userId } = data;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };
    const fd = new FormData();

    fd.append('photo', photo, photo.name);

    const response = await axios.post<UserTrii>(
      `${URL_SETTINGS}/users/${userId}/setphoto`,
      fd,
      config
    );

    return response.data;
  }
);

const usersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    setSelectedUser(state, action: PayloadAction<UserTrii>) {
      state.selectedUser = action.payload;
    },
    setResendInviteStatus(state, action: PayloadAction<RequestStatus>) {
      state.status.resendInvite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        usersAdapter.setAll(state, action.payload);
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(addUser.pending, (state, action) => {
        state.status.add = 'loading';
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status.add = 'succeeded';
        const data = action.payload;
        const newUser: UserInfo = {
          id: data?.uid,
          name: data?.display_name,
          imageUrl: data?.imageUrl,
          email: data?.email,
          phone: data?.phone,
          verifiedAccount: data?.verifiedAccount,
          userType: data?.userType,
          isActive: data?.isActive,
          sipNumber: data?.sipExtensionConfig?.extension,
          status: data?.status,
          statusMessage: data?.statusMessage,
          statusLastSeen: data?.statusLastSeen
        };
        usersAdapter.addOne(state, newUser);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status.add = 'idle';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        console.log('UPDATE USER', action.payload);
        usersAdapter.updateOne(state, {
          id: action.payload.uid,
          changes: {
            name: action.payload.display_name,
            email: action.payload.email,
            phone: action.payload.phone,
            imageUrl: action.payload.imageUrl,
          },
        });

        if (state.selectedUser?.uid === action.payload.uid) {
          state.selectedUser = action.payload;
        }
      })
      .addCase(updateUserPhoto.fulfilled, (state, action) => {
        usersAdapter.updateOne(state, {
          id: action.payload.uid,
          changes: { imageUrl: action.payload.imageUrl },
        });

        // if (state.selectedUser?.uid === action.payload.uid) {
        //   state.selectedUser = action.payload;
        // }
      })
      .addCase(fetchUserById.pending, (state, action) => {
        state.status.fetchById = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status.fetchById = 'succeeded';
        state.selectedUser = action.payload;
      })
      .addCase(updatUserType.fulfilled, (state, action) => {
        usersAdapter.updateOne(state, {
          id: action.payload.uid,
          changes: action.payload,
        });
      })
      .addCase(resendInvite.pending, (state, action) => {
        state.status.resendInvite = 'loading';
      })
      .addCase(resendInvite.fulfilled, (state, action) => {
        state.status.resendInvite = 'idle';
      })
      .addCase(resendInvite.rejected, (state, action) => {
        state.status.resendInvite = 'rejected';
      });
  },
});
// Actions
export const { selectAll: selectAllUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state: RootState) => state.Users);
export const { setSelectedUser, setResendInviteStatus } = usersSlice.actions;
// Selectors
export const getUsersFetchStatus = (state: RootState) => state.Users.status.fetch;
export const getUserFetchByIdStatus = (state: RootState) =>
  state.Users.status.fetchById;
export const selectSelectedUser = (state: RootState) => state.Users.selectedUser;
export const selectAddUserStatus = (state: RootState) => state.Users.status.add;
export const selectResendInviteStatus = (state: RootState) =>
  state.Users.status.resendInvite;

export default usersSlice.reducer;

import { useTranslation } from 'react-i18next';
// Components/ui
import { EditWorkspacePanel } from 'components';
import { SubdomainCustom } from './components';
// Types
import { ISpace } from '@trii/types/dist/Spaces';

type Props = {
  workspace: ISpace;
};

const SubdomainPanel = ({ workspace }: Props) => {
  const { t } = useTranslation();

  return (
    <EditWorkspacePanel title={t('workSpaces.subdomainName')}>
      <SubdomainCustom workspace={workspace} />
    </EditWorkspacePanel>
  );
};

export default SubdomainPanel;

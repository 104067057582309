import React, { useContext } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import CopyClipboardButton from 'components/CopyClipboardButton/CopyClipboardButton';
import { useTranslation } from 'react-i18next';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';

const ModalSuccess: React.FC = () => {
  const { t } = useTranslation();

  const { modalSuccess, setModalSuccess, secret, closeCreatePanel } = useContext(InitApiKeysContext);
  
  const handleClose = () => {
    setModalSuccess(false); 
    closeCreatePanel()
  };

  return (
    <Modal open={modalSuccess} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //@ts-ignore
          bgcolor: (theme) => theme.palette.background.dropdownMenu,
          boxShadow: 10,
          p: 2,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t("ApiKeys.modal.apiKeyCreated")}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
          {t("ApiKeys.modal.text1")}
        </Typography>
        <Typography variant="body2" sx={{ color: 'error.main', mb: 2 }}>
          {t("ApiKeys.modal.text2")}
        </Typography>
        <Box display={'flex'} mb={'20px'} alignItems={'center'} gap={'16px'} maxWidth={'100%'}>
          <Typography variant="subtitle1" sx={{ wordWrap: 'break-word', maxWidth:'90%'}}>
            {secret}  {/* Usar el valor de `secret` del contexto */}
          </Typography>
          <CopyClipboardButton text={secret || ''} />
        </Box>
        <Button variant="contained" onClick={handleClose}>
          {t("ApiKeys.modal.done")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalSuccess;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';

const Header = () => {
  const { t } = useTranslation();
  const { closeCreatePanel } = useContext<ApiKeysContext>(InitApiKeysContext);
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Typography variant="subtitle2" sx={{color: theme=>theme.palette.text.primary}}>
        {t('ApiKeys.createApiKey')}
      </Typography>
      <IconButton onClick={closeCreatePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;

import { useContext, useState } from 'react';
import { RolesContext } from '../../context/rolesContext';
//components/ui
import Box from '@mui/material/Box';
import {
  MessageNoRoleExist,
  ModalDeleteRole,
  ModalDeleteRoleError,
  RoleDisplay,
  RoleTabContents,
  RoleTabs,
} from './components';
import { Divider } from '@mui/material';

export default function RolesInfo() {
  const {
    roleView,
    selectedRole,
    showDeletePopup,
    showDeleteErrorPopup,
    handleCloseDeleteErrorPopup,
    handleCloseDeletePopup,
  } = useContext(RolesContext);

  const [roleTab, setRoleTab] = useState(0);

  return (
    <Box
      display="flex"
      gap="0.5rem"
      height="100%"
      width="50%"
      flexDirection="column"
    >
      {roleView === 'create' && (
        <RoleDisplay role={selectedRole} status={'create'} />
      )}
      {roleView === 'read' && (
        <>
          <RoleTabs setTab={setRoleTab} tab={roleTab} />
          <RoleTabContents tab={roleTab} role={selectedRole} />
        </>
      )}

      {roleView === null && <MessageNoRoleExist />}

      <ModalDeleteRole
        isOpen={showDeletePopup}
        name={selectedRole?.title}
        onCancel={handleCloseDeletePopup}
        roleId={selectedRole?.id}
      />

      <ModalDeleteRoleError
        isOpen={showDeleteErrorPopup}
        onCancel={handleCloseDeleteErrorPopup}
        name={selectedRole?.title}
      />
    </Box>
  );
}

import axios from "axios";

import getRequestConfig from "redux/functions/getRequestConfig";

const fetchSpaces = async (jwt: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    "https://account.trii.app/api/v1/MySpaces",
    config
  );

  return response.data;
};

export default { fetchSpaces };

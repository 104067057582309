import React, { useContext } from "react";
import { StyleContext } from "style/styleProvider";
import Typography from "@mui/material/Typography";
import { Box, Card, Divider } from "@mui/material";

const EditWorkspacePanel = ({ children, title }) => {
  const { color, fontSize } = useContext(StyleContext);

  return (
    <Card className="profile-panel" sx={{ backgroundColor: "dropdownBox",  backgroundImage: 'unset' }}>
      <Box
        className="profile-panel-hdr"
        sx={{
          backgroundColor: "background.panelHeader",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        }}
      >
        <Typography color={"text.primary"} fontSize={fontSize.sm}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box className="profile-panel-container show">
        <Box className="profile-panel-content">{children}</Box>
      </Box>
    </Card>
  );
};

export default EditWorkspacePanel;

import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import { Box, Typography, Fade } from '@mui/material';
import { ActionButtons } from './components';
// Translation
import { useTranslation } from 'react-i18next';
// moment
import moment from 'moment';
// Types
import { ApiKey } from '@trii/types/dist/PublicAPI/apiKey';

const FORMAT_DATE = 'YYYY-MM-DD';

interface APIKeysItemProps {
  data: ApiKey;
  key: string;
}

const ApiKeysListItem = ({ data }: APIKeysItemProps) => {
  const [show, setShow] = useState<boolean>(false);
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Box
        sx={{
          color: (theme) => theme.palette.text.primary,
          display: 'flex',
          boxShadow: shadow.md,
          borderRadius: 1,
          px: { md: '1rem', lg: '1rem' },
          my: 0,
          //@ts-ignore
          bgcolor: (theme) => theme.palette.containerBox.default,
          transition: 'all 0.3s ease',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              width: '100%',
              py: 1,
              pr: 4,
            }}
          >
            <Typography
              fontWeight={fontWeight.semibold}
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {data.name}
            </Typography>
            <br />
            <Box display='flex' justifyContent='space-between'>
              <Typography
                fontSize={fontSize.sm}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                {t('ApiKeys.createdAt')} {moment(data.createdAt).format(FORMAT_DATE)}
              </Typography>
            </Box>
          </Box>
          <ActionButtons apiKeyData={data} />
        </Box>
      </Box>
    </Fade>
  );
};

export default ApiKeysListItem;

// Translation
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Typography } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

interface IDatePickerProps {
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  handleSelectStart: (date: Dayjs) => void;
  handleSelectEnd: (date: Dayjs) => void;
  isError: boolean;
}

const DatePicker = ({
  selectedStartDate,
  selectedEndDate,
  handleSelectStart,
  handleSelectEnd,
  isError,
}: IDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          format={DATE_FORMAT}
          ampm={false}
          slotProps={{
            textField: () => ({
              label: t('auditLogView.from'),
              size: 'small',
              className: 'smallInput',
              sx: {
                borderColor: theme => isError
                  ? theme.palette.error.main
                  : // @ts-ignore
                  theme.palette.Boxider,
              },
              inputProps: {
                shrink: 'true',
              },
            }),
          }}
          value={selectedStartDate}
          onChange={handleSelectStart}
        />
        <Typography
          variant="body1"
          sx={{
            marginX: '8px',
            marginTop: '-15px',
            color: 'text.primary',
          }}
        >
          _
        </Typography>
        <DateTimePicker
          format={DATE_FORMAT}
          ampm={false}
          slotProps={{
            textField: () => ({
              label: t('auditLogView.to'),
              size: 'small',
              className: 'smallInput',
              width: '50%',
            }),
          }}
          value={selectedEndDate}
          onChange={handleSelectEnd}
        />
      </LocalizationProvider>
    </Box>
  )
}

export default DatePicker
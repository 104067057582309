import React, { useContext } from "react";
// Redux
import { useSelector } from "react-redux";
// Selectors
import { selectUser } from "redux/features/userSlice/userSlice";
import { selectSession } from "redux/features/sessionSlice/sessionSlice";
import { selectThemeToggleStatus, selectedTheme } from 'redux/features/themeSlice/themeSlice';
import "./MainLayout.css";
import { DataContext } from "context/contextApi";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Components
import NavMenu from "./components/NavMenu";
import { PageLoading } from 'components';
import { useOpenCallPanelWidth } from './utils/useOpenCallPanelWidth';
import { Theme } from "@mui/system";

function MainLayout(props) {
  const { isMobile, showPageSideBar, setShowPageSideBar } =
    useContext(DataContext);
  // const closeDialog = async () => {
  //   setOpenDialogEmpresa(false);
  // };
  const themeToggleStatus = useSelector(selectThemeToggleStatus);
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);

  React.useEffect(() => {
    !isMobile && setShowPageSideBar(true);
  }, []);
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const { boxWidth } = useOpenCallPanelWidth();

  return (
    <Box
      sx={{
        display: "flex",
        width: boxWidth,
        height: "calc(100vh - 4.3rem)",
      }}
    >
      {showPageSideBar && (
        <Box
          sx={{
            //@ts-ignore
            backgroundImage: onSelectedTheme.palette.background.mainGradient,
            minWidth: "3rem",
            maxWidth: "3rem",
            width: "100%",
            maxHeight: "calc(100vh - 4.3rem)",
            // boxShadow: 'inset rgba(0, 0, 0, 0.25) 0px 3px 6px 0px',
            borderRight: `1px solid ${onSelectedTheme.palette.divider}`,
          }}
        >
          <NavMenu />
        </Box>
      )}
      {session && user && themeToggleStatus === "toggled" ? (
        <Box
          bgcolor={onSelectedTheme.palette.background.mainCenter}
          // className="profile-page-content-wrapper"
          sx={{
            width: "100%",
            height: "100%",
            minWidth: isMobile && "fit-content"
          }}
        >
          <Box id="js-profile-page-content" className="profile-page-content">
            {props.children}
            <Dialog
              // open={openDialogEmpresa}
              open={false}
              // onClose={() => setOpenDialogEmpresa(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">title</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  subtitle
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                // onClick={() => setOpenDialogEmpresa(false)}
                >
                  {/* {t("global.cancel")} */}
                  cancel
                </Button>
                <Button
                  color="error"
                // onClick={closeDialog}
                >
                  {/* {t("global.delete")} */}
                  delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      ) : (
        <PageLoading />
      )}
    </Box>
  );
}

export default MainLayout;

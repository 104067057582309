import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components/ui
import { Box, TextField, Typography } from '@mui/material';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';

const Inputs = () => {
  const { t } = useTranslation();
  const { deleteApiKeyNameField } = useContext<ApiKeysContext>(InitApiKeysContext);  
  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('ApiKeys.apiKeyName')}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          marginBottom: '15px',
        }}
        {...deleteApiKeyNameField.attributes}
      />
    </Box>
  );
};

export default Inputs;

import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { deleteWorkspace } from 'redux/features/workspace/workspaceSlice';
// Types
import { Dispatch, SetStateAction } from 'react';
// Components/ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
};

const EraseWorkspaceDialog = ({ openDeleteDialog, setOpenDeleteDialog }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    setIsLoading(true);
    await dispatch(deleteWorkspace());
    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('delete.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('delete.dialog.subtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpenDeleteDialog(false)}>
          {t('global.cancel')}
        </Button>
        <LoadingButton
          onClick={handleDelete}
          disabled={isLoading}
          variant="outlined"
          color="error"
          loading={isLoading}
        >
          {t('global.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EraseWorkspaceDialog;

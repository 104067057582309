// Translation
import { useTranslation } from 'react-i18next'
// Components/ui
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material'
import { menuProps } from '../../style'
// Utils
import { logTypes } from './utils/logTypes'

interface IErrorTypesProps {
  typeValue: string[];
  handleChangeFilterType: (event: SelectChangeEvent<string[]>) => void;
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ErrorTypes = ({
  typeValue,
  handleChangeFilterType,
}: IErrorTypesProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box sx={{ marginLeft: '15px' }}>
      <FormControl
        sx={{
          m: 1,
          width: 300
        }}
        size="small"
        className="fadein"
      >
        <InputLabel id="demo-multiple-chip-label" shrink>
          {t('global.type')}
        </InputLabel>
        <Select
          multiple
          value={typeValue}
          onChange={handleChangeFilterType}
          input={
            <OutlinedInput id="select-multiple-chip" label="Tipo" />
          }
          sx={{
            // fontSize: "14rem",
            "& legend": {
              maxWidth: "fit-content",
              // padding: '0 4px',
            },
          }}
          size="small"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{ fontSize: 12, height: 20, marginTop: 0.2 }}
                  />
                ))
              }
            </Box>
          )}
          MenuProps={menuProps}
        >
          {logTypes.map((value, index) => (
            <MenuItem
              key={index}
              value={value.value}
              style={getStyles(value.value, typeValue, theme)}
            >
              {value.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ErrorTypes
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { ChangeEvent } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateWorkspacePhoto } from 'redux/features/workspace/workspaceSlice';
// Components/ui
import { Spinner } from 'react-bootstrap';
import { Avatar } from '@mui/material';
// Utils
import { readAndCompressImage } from 'browser-image-resizer';

type Props = { imageUrl: string };

const ImageInput = ({ imageUrl }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [savingImage, setSavingImage] = useState(false);

  const inputImage = savingImage ? (
    <Spinner
      animation="border"
      style={{
        marginLeft: 5,
        marginRight: 5,
        height: 54,
        width: 54,
        fontSize: '14px',
        color: 'lightgray',
      }}
    />
  ) : (
    <Avatar
      src={imageUrl !== '' ? imageUrl : '/img/buildingDefault.png'}
      className="editIconProfile rounded-circle"
      style={{
        cursor: 'pointer',
        width: 64,
        height: 64,
      }}
      alt=""
    />
  );

  // const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const newImage = event.target.files[0];

  //   setSavingImage(true);
  //   await dispatch(updateWorkspacePhoto(newImage));
  //   setSavingImage(false);
  // };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newImage = event.target.files[0];

    const config = {
      quality: 0.7,
      maxWidth: 64,
      maxHeight: 64,
      autoRotate: true,
      debug: true,
    };

    try {
      const resizedImageBlob = await readAndCompressImage(newImage, config);
      const resizedImage = new File([resizedImageBlob], newImage.name, {
        type: newImage.type,
      });
      setSavingImage(true);
      await dispatch(updateWorkspacePhoto(resizedImage));
      setSavingImage(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <label title={t('global.updatePhoto')}>
      <input
        type="file"
        onChange={(event) => handleImageChange(event)}
        style={{ display: 'none' }}
        disabled={savingImage}
      />
      {inputImage}
    </label>
  );
};

export default ImageInput;

import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import { DataContext } from "./context/contextApi";

/** Components */
import MainLayout from "./features/Layout/MainLayout";
import Dashboard from "./features/Views/Dashboard";
import AuditLog from "./features/Views/AuditLog/AuditLog";
import Profile from "./features/Views/Profile/Profile";
import Users from "./features/Views/Users/index";
import { Security, Subscription } from "features/Views";
import { WorkspaceAdd, WorkspaceEdit, Workspaces } from "features/Views/Workspaces";
import { ErrorPage } from 'features/Views'
import ApiKeys from 'features/Views/ApiKeys/ApiKeys';
class Routes extends React.Component {
  static contextType = DataContext;

  render() {
    return (
      <MainLayout>
        <ReactRoutes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/a/settings/dashboard" element={<Dashboard />} />
          <Route path="/a/settings/users" element={<Users />} />
          <Route path="/a/settings/security" element={<Security />} />
          <Route path="/a/settings/profile" element={<Profile />} />
          <Route path="/a/settings/work-history" element={<AuditLog />} />
          <Route path="/a/settings/workspace" element={<WorkspaceEdit />} />
          <Route path="/a/settings/subscriptions" element={<Subscription />} />
          <Route path="*" element={<Dashboard />} />
          <Route path="/a/settings/error/:code" element={<ErrorPage />} />
          <Route
            path="/a/settings/APIKeys"
            element={<ApiKeys />}
          />
        </ReactRoutes>
      </MainLayout>
    );
  }
}

export default Routes;

import { useContext } from 'react';
import { ProfilesContext } from '../../../../../../context/profilesContext';
import { StyleContext } from 'style/styleProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import EditableBox from './components/EditableBox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function ProfileAvatar() {
  const { selectedProfile, nameField, descriptionField, isEditing, isLoading } =
    useContext(ProfilesContext);
  const { fontSize, fontWeight } = useContext(StyleContext);
  const { t } = useTranslation();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '40%',
      }}
    >
      <EditableBox
        boxDescription={t('global.name')}
        isEditing={isEditing}
        stateHandler={nameField}
        isDisabled={isLoading}
      >
        <Typography
          sx={{ color: 'text.primary' }}
          fontSize={fontSize.lg}
          fontWeight={fontWeight.bold}
        >
          {selectedProfile.name}
        </Typography>
      </EditableBox>
      <EditableBox
        boxDescription={t('global.description')}
        isEditing={isEditing}
        stateHandler={descriptionField}
        isDisabled={isLoading}
      >
        <Typography sx={{ color: 'text.primary' }} fontSize={fontSize.md}>
          {selectedProfile.description}
        </Typography>
      </EditableBox>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//redux
import { useSelector } from "react-redux";
import { selectAllProfiles } from "redux/features/profilesSlice/profilesSlice";
//components/ui
import { PopupInputCreateProfile } from "components";
import {
  Box,
  OutlinedInput,
  IconButton,
  Typography,
  Slide,
  Tooltip,
  tooltipClasses,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Theme } from "@mui/system";
import { selectedTheme } from "../../../../../../../../../../../redux/features/themeSlice/themeSlice";

type TooltipProps = {
  className?: string;
  title: string;
  placement: "right";
  children: React.ReactElement;
};

const SearchBar = () => {
  const containerRef = React.useRef(null);
  const { t } = useTranslation();
  const profiles = useSelector(selectAllProfiles);
  const [checked, setChecked] = React.useState(false);
  const [openPopopCreateProfile, setOpenPopopCreateProfile] = useState(false);
  const onSelectedTheme: Theme = useSelector(selectedTheme)

  const closeInputSearch = () => {
    setChecked(false);
  };

  const onOpenCreateProfile = () => {
    setOpenPopopCreateProfile(true);
  };

  useEffect(() => {
    checked === true && document.getElementById("busquedaUsuario").focus();
  }, [checked]);

  return (
    <>
      <Box
        boxShadow={"0px 2px 3px rgba(0,0,0,0.1)"}
        borderRadius="2px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={2}
        paddingTop={1}
        paddingBottom={1}
        position="relative"
        ref={containerRef}
      >
        <Typography sx={{ color: "text.primary" }}>
          {t("profileInfo.amount")}: {profiles?.length}
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton
            style={{ alignSelf: "center", marginRight: "5px" }}
            color="primary"
            onClick={onOpenCreateProfile}
          >
            <Tooltip
              title={t("profileInfo.createProfile")}
              placement="right"
            >
              <GroupAddOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Box>
        <Slide
          direction="right"
          in={checked}
          className="profile-inputSearchUsers"
          container={containerRef.current}
        >
          <Box style={{ display: "flex", width: "100%" }}>
            <OutlinedInput
              name="busqueda"
              id="busquedaUsuario"
              size={"small"}
              placeholder={t("global.searchByUserName")}
              style={{ width: "100%" }}
              endAdornment={
                <CloseIcon
                  onClick={closeInputSearch}
                  sx={{
                    cursor: "pointer",
                    color: "rgba(0, 0, 0, 0.54)",
                    width: "0.8em",
                  }}
                />
              }
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
          </Box>
        </Slide>
      </Box>
      <PopupInputCreateProfile
        setOpenPopopCreateProfile={setOpenPopopCreateProfile}
        isOpen={openPopopCreateProfile}
        title={t("profileInfo.createProfile")}
      ></PopupInputCreateProfile>
    </>
  );
}

export default SearchBar;
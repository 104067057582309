export const days = [
    { value: 1, label: "security.session.day" },
    { value: 5, label: "security.session.days" },
    { value: 10, label: "security.session.days" },
    { value: 20, label: "security.session.days" },
    { value: 30, label: "security.session.days" },
];

export const minutes = [
    { value: 0, label: "global.disabled" },
    { value: 5, label: "security.session.minutes" },
    { value: 10, label: "security.session.minutes" },
    { value: 15, label: "security.session.minutes" },
    { value: 30, label: "security.session.minutes" },
    { value: 45, label: "security.session.minutes" },
    { value: 60, label: "security.session.minutes" },
];
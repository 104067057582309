//components/ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalDeleteRoleError = ({ isOpen, name, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-popup-title">
        {t("roles.deleteRoleError")} '{name}'
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("roles.deleteRoleErrorDescription")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("global.accept")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeleteRoleError;

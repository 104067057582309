const getBasicRequestConfig = (jwt: string) => {
  return {
    headers: { Authorization: `Bearer ${jwt}` },
  };
};
const getContentTypeJsonRequestConfig = (jwt: string) => {
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };
};
const getFormDataRequestConfig = (jwt: string) => {
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

const getRequestConfig = {
  basic: getBasicRequestConfig,
  contentTypeJson: getContentTypeJsonRequestConfig,
  contentTypeFormData: getFormDataRequestConfig,
};

export default getRequestConfig;

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
// Types
import { RootState } from 'redux/store';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
import { SpaceSliceState } from './types/SpaceSliceState';
import { RequestStatus } from 'types/reduxTypes';
// Service
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';

import spaceSliceService from './spaceSliceService';

import { initRequestData } from 'redux/functions/initRequestData';

const initialState: SpaceSliceState = {
  spaceInfo: null,
  URL: {
    SETTINGS: '',
    SPACES: '',
  },
  status: {
    fetch: 'idle',
  },
};

export const fetchSpaceInfo = createAsyncThunk(
  'space/fetchSpaceInfo',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await spaceSliceService.fetchSpaceInfo(jwtToken);

    return response.data;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceInfo: (state, action: PayloadAction<ISpaceInfo>) => {
      state.spaceInfo = action.payload;
    },
    setSpaceFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetch = action.payload;
    },
    setSpaceUrlSettings: (state, action: PayloadAction<string>) => {
      state.URL.SETTINGS = `https://agent-api.trii.app/api/v1/settings/${action.payload}`;
    },
    setSpaceUrlSpaces: (state, action: PayloadAction<string>) => {
      state.URL.SPACES = `/api/v1/Spaces/${action.payload}`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceInfo.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(
      fetchSpaceInfo.fulfilled,
      (state, action: PayloadAction<ISpaceInfo>) => {
        state.spaceInfo = action.payload;

        state.status.fetch = 'succeeded';
      }
    );
  },
});

export const {
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrlSettings,
  setSpaceUrlSpaces,
} = spaceSlice.actions;

const selectSpaceState = (state: RootState) => state.Space;
export const selectSpaceInfo = createSelector(
  selectSpaceState,
  (space) => space.spaceInfo
);
export const selectFetchSpaceInfoStatus = createSelector(
  selectSpaceState,
  (space) => space.status.fetch
);

export default spaceSlice.reducer;

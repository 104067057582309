import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import subdomainService from './subdomainService';
// Types
import type { SubdomainSlice } from './types/SubdomainSlice';
import type { GetSubdomainAvailabilityParams } from './types/GetSubdomainAvailabilityParams';
import { RootState } from 'redux/store';

const initialState: SubdomainSlice = {
  name: '',
  availability: null,
  status: { checkSubdomainAvailability: 'idle' },
};

export const getSubdomainAvailability = createAsyncThunk(
  'Subdomain/checkSubdomainAvailability',
  async (data: GetSubdomainAvailabilityParams) => {
    const { subdomain, subdomainName } = data;
    const subdomainJson = JSON.stringify({ subdomain });
    const { available } = await subdomainService.getSubdomainAvailability(
      subdomainJson
    );

    return { subdomainName, available };
  }
);

const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubdomainAvailability.pending, (state, action) => {
        state.status.checkSubdomainAvailability = 'loading';
      })
      .addCase(getSubdomainAvailability.fulfilled, (state, action) => {
        const subdomainObj = action.payload;

        state.status.checkSubdomainAvailability = 'succeeded';
        state.name = subdomainObj.subdomainName;
        state.availability = subdomainObj.available;
      });
  },
});

export const selectSubdomain = (state: RootState) => state.Subdomain.name;
export const selectSubdomainAvailability = (state: RootState) =>
  state.Subdomain.availability;
export const selectSubdomainStatus = (state: RootState) =>
  state.Subdomain.status.checkSubdomainAvailability;

export default subdomainSlice.reducer;

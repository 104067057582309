import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import { TriiUserProfile } from "./components";
import { makeStyles } from "@material-ui/core/styles";
import "./Profile.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

toast.configure();

const Profile = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();
  const [errorSubmit, setErrorSubmit] = useState(false); // eslint-disable-line
  const [openPopupDeleteAcc, setOpenPopupDeleteAcc] = useState(false);
  const [changingImage, setChangingImage] = useState(false); //eslint-disable-line
  const { t } = useTranslation();

  const deleteAccount = async () => {
    setOpenPopupDeleteAcc(false);

    // let result =await  APIUserAccount.eliminarCuentaPerfil(userProfile.uid)  // eslint-disable-line
    // //console.log('delete account: ', resultado)

    toast.warning(t("tostify.deletedAccount"));
  };

  return (
    <>
      <div className={classes.root}>
        <div className="profile-page-content" style={{ alignItems: "center" }}>
          <Box
            display="flex"
            gap="0.5rem"
            height={"80vh"}
            flexGrow={1}
            width="75%"
            flexDirection="column"
            sx={{
              height: "100%",
              overflow: "auto",
            }}
            className="profile-fadeinup"
          >
            <TriiUserProfile />
          </Box>
        </div>
      </div>

      <Dialog
        open={openPopupDeleteAcc}
        onClose={() => setOpenPopupDeleteAcc(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deletePrincipalAccount.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deletePrincipalAccount.subtitle")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopupDeleteAcc(false)}>
            {t("global.cancel")}
          </Button>
          <Button onClick={deleteAccount} color="error">
            {t("global.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;

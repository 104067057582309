import { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectAllProfiles } from 'redux/features/profilesSlice/profilesSlice';
// Components/ui
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SpinnerIcon } from 'components';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ModalDeleteProfile = ({
  isOpen,
  name,
  onCancel,
  profileId,
  handleDelete,
  isDeleteLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const profiles = useSelector(selectAllProfiles);

  const filteredProfiles = profiles.filter((profile) => profile.id !== profileId);

  const [newProfileId, setNewProfileId] = useState('');

  const renderMenuItems = filteredProfiles.map((profile) => (
    <MenuItem key={profile.id} value={profile.id}>
      {profile.name}
    </MenuItem>
  ));

  const handleChangeRole = (event) => {
    setNewProfileId(event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-popup-title">
        {t('profileInfo.deleteProfile')} '{name}'
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('profileInfo.deleteProfileDescription')} '{name}'
        </DialogContentText>
      </DialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, pb: 2 }}>
        <FormControl fullWidth>
          <Select
            value={newProfileId}
            label="Role"
            onChange={handleChangeRole}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
            }}
          >
            {renderMenuItems}
          </Select>
        </FormControl>
      </Box>
      <DialogActions>
        <Button disabled={isDeleteLoading} onClick={onCancel}>
          {t('global.cancel')}
        </Button>
        <Button
          disabled={isDeleteLoading}
          onClick={() => handleDelete(newProfileId)}
          color="error"
          startIcon={<SpinnerIcon isLoading={isDeleteLoading} />}
        >
          {t('global.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeleteProfile;

import UserType from "@trii/types/dist/Users/UserType";
import { IUserType } from "../types/IUserType";


const userTypeList: IUserType[] = [
  {
    id: UserType.NULL.toString(),
    name: '',
  },
  {
    id: UserType.CO_OWNER.toString(),
    name: 'CO OWNER',
  },
  {
    id: UserType.OWNER.toString(),
    name: 'OWNER',
  },
  {
    id: UserType.ADMIN.toString(),
    name: 'ADMIN',
  },
  {
    id: UserType.AGENT.toString(),
    name: 'AGENT',
  },
  {
    id: UserType.EXTERNAL.toString(),
    name: 'EXTERNAL',
  },
]

export default userTypeList;
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "style/styleProvider";
//components/ui
import Button from "@mui/material/Button";
import SpinnerIcon from "components/SpinnerIcon";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

const EditButton = ({ isEditing, isLoading, onClick }) => {
  const { t } = useTranslation();
  const { iconSize, mediaQuery } = React.useContext(StyleContext);
 const buttonFontSize = mediaQuery.xl ? null : 10;

  const startIcon = isLoading ? (
    <SpinnerIcon isLoading={isLoading} />
  ) : isEditing ? (
    <SaveIcon />
  ) : (
    <ModeEditOutlineOutlinedIcon />
  );
  const text = isEditing
    ? t("global.save")
    : isLoading
    ? t("global.saving")
    : t("global.edit");

  return (
    <Button
      size={'small'}
      startIcon={startIcon}
      variant="outlined"
      className="buttonGroupProfileHeader"
      onClick={onClick}
      disabled={isLoading}
      sx={{fontSize: buttonFontSize}}
    >
      {text}
    </Button>
  );
};

export default EditButton;

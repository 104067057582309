import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';

const Privileges = () => {
  const { t } = useTranslation();
  const { privileges, setPrivileges } = useContext<ApiKeysContext>(InitApiKeysContext);

  // Handle switch change
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
  
    // Explicitly type the updatedPrivileges object to match the expected type
    const updatedPrivileges = {
      ...privileges,
      [name]: checked,
    } as { Messages: boolean; Contacts: boolean };
    
    setPrivileges(updatedPrivileges);
  };

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t('ApiKeys.privileges')}
      </Typography>

      <Box display={"flex"} flexDirection={"column"}>
        <FormControlLabel
          control={
            <Switch
              checked={privileges.Messages}
              onChange={handleSwitchChange}
              name="Messages"
              color="primary"
            />
          }
          label={t('ApiKeys.messages')}
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            marginLeft: '16px',
            marginRight: 0,
            '& span.MuiTypography-root': {
              fontSize: '0.9rem'
            }
          }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={privileges.Contacts}
              onChange={handleSwitchChange}
              name="Contacts"
              color="primary"
            />
          }
          label={t('ApiKeys.contacts')}
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            marginLeft: '16px',
            marginRight: 0,
            '& span.MuiTypography-root': {
              fontSize: '0.9rem'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Privileges;

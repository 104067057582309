import { useState } from 'react';
// Context
import { UsersContext } from './UsersContext';
// Redux
import { useSelector } from 'react-redux';
import { selectSelectedUser } from 'redux/features/usersSlice/usersSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateUser } from 'redux/features/usersSlice/usersSlice';
// Types
import { Profile } from './types/Profile';
import { UserInfo, UserTrii } from '@trii/types/dist/Users';
import type { UsersContextType } from './types/UsersContextType';
// Utils
import profiles from 'utils/profiles';
import roles from 'utils/roles';

const UsersDataProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [profile, setProfile] = useState<Profile[]>(profiles);
  const [role, setRole] = useState<Profile[]>(roles);
  const [modalEditar, setModalEditar] = useState<boolean>(false);
  const [modalEliminar, setModalEliminar] = useState<boolean>(false);
  const [dataBusqueda, setDataBusqueda] = useState<UserInfo[]>([]);
  const [buscando, setBuscando] = useState(false);
  //Editing user State
  const selectedUser = useSelector(selectSelectedUser);

  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  //
  const [tabUsersInfoView, SetTabUsersInfoView] = useState(0);

  const onHandleClose = () => {
    setModalEditar(false);
  };

  const handleEditButton = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleSaveButton = async (userTrii: UserTrii) => {
    setIsSaving(true);
    const response = await dispatch(updateUser(userTrii));
    // setSelectedUser(response.payload);

    setIsSaving(false);
    setIsEditing(false);
  };

  const contextValue: UsersContextType = {
    isSaving,
    handleEditButton,
    handleSaveButton,
    setIsEditing,
    isEditing,
    selectedUser,
    modalEditar,
    setModalEditar,
    modalEliminar,
    setModalEliminar,
    dataBusqueda,
    setDataBusqueda,
    buscando,
    setBuscando,
    onHandleClose,
    tabUsersInfoView,
    SetTabUsersInfoView,
    profile,
    setProfile,
    role,
    setRole,
    selectedUserId,
    setSelectedUserId,
  };

  return (
    <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>
  );
};

export default UsersDataProvider;

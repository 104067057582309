// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Card, Grid, Typography } from "@mui/material"
// Types
import { IIcon } from "../../utils/iconList"
import { disabledStyle, enabledStyle } from "./styles/styles"
import { UserProfile, UserProfile_Chat } from "@trii/types/dist/Users";

// interface Props {
//   item: UserProfile[keyof UserProfile]
// }

interface Props {
  item: {
    enabled: boolean;
    icon: string; // Supongo que icon es una URL a la imagen del ícono
    name: string; // Título del ícono
  };
}

const Icons = ({
  item,
}: Props) => {
  const { t } = useTranslation()
  // const isEnabled = typeof item === 'object' && 'enabled' in item ? item.enabled : false;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        component={Card}
        padding={2}
        borderRadius={2}
        width={1}
        data-aos={"fade-up"}
        sx={item.enabled ? enabledStyle : disabledStyle}

      >
        <Box display={"flex"} alignItems={"center"}>
          <Box
            component="img"
            height={50}
            src={item.icon}
          />
          <Typography
            variant={"h4"}
            gutterBottom
            sx={{
              fontWeight: 500,
              fontSize: "1.1243rem !important",
              cursor: "default",
            }}
          >
            {t(item.name)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default Icons
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { selectAPIKeyCreateStatus } from 'redux/features/APIKeysSlice/APIKeysSlice'; // Adjust this import according to your actual slice
import { RequestStatus } from 'types/reduxTypes';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeCreatePanel, createApiKeyNameField, sendApiKey } =
    useContext<ApiKeysContext>(InitApiKeysContext);

  const createStatus: RequestStatus = useSelector(selectAPIKeyCreateStatus);
  const isLoading: boolean = createStatus === 'loading';
  const isDisabled: boolean =
    createStatus === 'loading' || !createApiKeyNameField.attributes.value;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeCreatePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        disabled={isDisabled}
        onClick={sendApiKey}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;

import React, { useEffect } from "react";

export const useSwitch = (
  initialState: boolean
) => {
  // initialState = initialState ;
  const [checked, setChecked] = React.useState(initialState);

  useEffect(() => {
    setChecked(initialState);

    return () => {
      setChecked(false);
    };
  }, [initialState]);

  const onChange = () => setChecked((prev) => !prev);

  return { checked, onChange };
};

export default useSwitch;
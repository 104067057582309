import React, { useContext } from 'react';
// Types
// Components/ui
import { Box, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { APIKeysState } from 'redux/features/APIKeysSlice/APIKeysSlice';
import { ApiKey } from '@trii/types/dist/PublicAPI/apiKey';

interface IActionButtonsProps {
  apiKeyData: ApiKey;
}

const ActionButtons = ({ apiKeyData }: IActionButtonsProps) => {
  const { openDeletePanel, openEditPanel } =
    useContext<ApiKeysContext>(InitApiKeysContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="space-evenly"
    >
      <Stack direction="row">
        <IconButton
          sx={{ color: (theme) => theme.palette.primary.main }}
          onClick={() => openEditPanel(apiKeyData)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => openDeletePanel(apiKeyData)}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActionButtons;

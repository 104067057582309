import { useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  fetchWorkspace,
  selectWorkpace,
} from 'redux/features/workspace/workspaceSlice';
// Components/ui
import { makeStyles } from '@material-ui/core/styles';
import {
  ErasePanel,
  ProfilePanel,
  SubdomainPanel,
  CustomDomainPanel,
} from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';

const WorkspaceEdit = () => {
  const dispatch = useAppDispatch();
  const workspace = useSelector(selectWorkpace);
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchWorkspace());
  }, []);

  return (
    <div
      className={`${classes.root} profile-fadeinup`}
      style={{
        maxHeight: "calc(100vh - 45px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="border-faded border-0">
        <div className="profile-page-content">
          <div className="content">
            <div className="text-center m-1 h1"></div>
          </div>
          <div className="profile-row profile-justify-content-center mt-4">
            <div className="profile-col-lg-8 profile-col-md-12 profile-col-sm-12 profile-col-xs-12">
              <ProfilePanel workspace={workspace} />
              <SubdomainPanel workspace={workspace} />
              <CustomDomainPanel workspace={workspace} />
              <ErasePanel workspace={workspace} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceEdit;

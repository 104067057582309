import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, tabValue, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      hidden={tabValue !== index}
      aria-labelledby={`simple-tab-${index}`}
      style={{ overflow: 'auto', height: '100%', maxHeight: 'calc(100vh - 400px)' }}
      {...other}
    >
      {tabValue === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
};

export default TabPanel;

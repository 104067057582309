import React, { useState, useContext } from "react";
import { ProfilesContext } from "features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext";
//components/ui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SpinnerIcon from "components/SpinnerIcon";
import Input from "components/Input";

const PopupDuplicateProfile = ({
  isOpen,
  title,
  nombre,
  descripcion,
  copy_ID,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { duplicatedNameField, duplicatedDescriptionField } =
    useContext(ProfilesContext);

  const handleAccept = async () => {
    setLoading(true);

    await onAccept();

    setLoading(false);
    onCancel();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-popup-title"
      aria-describedby="alert-popup-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-popup-title">{title}</DialogTitle>
      <Box
        sx={{ display: "flex", flexDirection: "column", px: 3, pb: 2, gap: 2 }}
      >
        <Input {...duplicatedNameField} label={t("global.name")} />
        <Input
          {...duplicatedDescriptionField}
          label={t("global.description")}
        />
      </Box>
      <DialogActions>
        <Button disabled={loading} onClick={onCancel}>
          {t("global.cancel")}
        </Button>
        <Button
          disabled={loading}
          startIcon={<SpinnerIcon isLoading={loading} />}
          onClick={handleAccept}
        >
          {t("global.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDuplicateProfile;

import { UserInfo } from '@trii/types/dist/Users';
import axios from 'axios';
// Types
import getRequestConfig from 'redux/functions/getRequestConfig';

async function fetchRoleUsers(URL: string, jwtToken: string, roleId: string) {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserInfo[]>(`${URL}/?roleId=${roleId}`, config);

  return response.data;
}

export default { fetchRoleUsers };

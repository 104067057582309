import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfilesContext } from '../../../../context/profilesContext';
import { StyleContext } from 'style/styleProvider';
import { DataContext } from 'context/contextApi';
// Utils
import { profileNameControl } from 'helpers/authControl';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  deleteProfile,
  fetchProfiles,
  updateProfile,
  addProfile,
} from 'redux/features/profilesSlice/profilesSlice';
// Components/ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  PopupDuplicateProfile,
  EditButton,
  FormNotificationError,
} from 'components';
import { ModalDeleteProfile, ProfileAvatar } from './components';

export default function ProfileInfoHeader() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    setSelectedProfile,
    selectedProfile,
    setIsEditing,
    isEditing,
    isLoading,
    updatedProfile,
    setIsLoading,
    duplicatedNameField,
    duplicatedDescriptionField,
    nameField,
  } = useContext(ProfilesContext);
  const { setOpenDeletePopup, openDeletePopup } = useContext(DataContext);
  const { mediaQuery } = useContext(StyleContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [openPopupDuplicateProfile, setOpenPopupDuplicateProfile] = useState(false);

  // Styles
  const buttonFontSize = mediaQuery.xl ? null : 10;
  const headerHeight = mediaQuery.lg ? '20%' : '25%';

  const handleEditButton = async () => {
    if (!isEditing) {
      return setIsEditing(true);
    }

    setIsLoading(true);

    if (profileNameControl(nameField.attributes.value)) {
      setIsEditing(false);

      const response = await dispatch(updateProfile(updatedProfile));

      setSelectedProfile(response.payload);
      nameField.actions.onSuccess();
    } else {
      nameField.actions.onError(
        <FormNotificationError errorMsg={t('global.nameError')} />
      );
    }

    setIsLoading(false);
  };

  const handleProfileDelete = async (newProfileID: string) => {
    setIsDeleting(true);

    await dispatch(deleteProfile({ profileId: selectedProfile.id, newProfileID }));
    await dispatch(fetchProfiles());

    setIsDeleting(false);
    setOpenDeletePopup(false);
    setSelectedProfile(null);
  };

  const onOpenDuplicateProfile = () => {
    setOpenPopupDuplicateProfile(true);
  };

  const handleDuplicateProfile = async () => {
    const duplicatedProfile = updatedProfile;

    delete duplicatedProfile.id;

    const response = await dispatch(
      addProfile({
        ...duplicatedProfile,
        name: duplicatedNameField.attributes.value,
        description: duplicatedDescriptionField.attributes.value,
      })
    );
    // await dispatch(fetchProfiles());
  };

  const onCancelDuplicateProfile = () => {
    setOpenPopupDuplicateProfile(false);
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 2,
          py: 1.5,
          height: headerHeight,
          bgcolor: 'background.panel',
          boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
          borderRadius: 1,
        }}
      >
        <Box sx={{ alignSelf: 'end', display: 'flex', gap: 1 }}>
          <Button
            size="small"
            startIcon={<ContentCopyOutlinedIcon />}
            variant="outlined"
            className="buttonGroupProfileHeader"
            sx={{ fontSize: buttonFontSize }}
            onClick={onOpenDuplicateProfile}
          >
            {t('profileInfo.duplicate')}
          </Button>
          <EditButton
            isEditing={isEditing}
            isLoading={isLoading}
            onClick={handleEditButton}
          />
          <Button
            size="small"
            startIcon={<DeleteOutlinedIcon />}
            variant="outlined"
            color="error"
            className="buttonGroupProfileHeader"
            onClick={() => setOpenDeletePopup(true)}
            sx={{ fontSize: buttonFontSize }}
          >
            {t('global.delete')}
          </Button>
        </Box>
        <ProfileAvatar />
      </Box>

      {/* <PopupDelete
        title={`Eliminar perfil ${selectedProfile.name}`}
        subtitle="Se eliminarán todos los datos del perfil"
        onDelete={handleProfileDelete}
        loading={isDeleting}
      /> */}

      <ModalDeleteProfile
        isOpen={openDeletePopup}
        profileId={selectedProfile.id}
        name={selectedProfile.name}
        onCancel={() => setOpenDeletePopup(false)}
        handleDelete={handleProfileDelete}
        isDeleteLoading={isDeleting}
      />

      <PopupDuplicateProfile
        isOpen={openPopupDuplicateProfile}
        title={t('profileInfo.duplicate')}
        nombre={selectedProfile.name}
        descripcion={selectedProfile.description}
        onAccept={handleDuplicateProfile}
        onCancel={onCancelDuplicateProfile}
      />
    </>
  );
}

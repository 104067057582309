import React from "react";
// Components/ui
import { useTheme } from "@mui/material";
// Icons
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Components
import { DotPulseLoader } from "./components";

const Adornment = ({ status }) => {
  const theme = useTheme();
  switch (status) {
    case "idle":
      return null;
    case "loading":
      return <DotPulseLoader color={theme.palette.primary.light} size={5} />;
    case "available":
      return (
        <CheckRoundedIcon style={{ height: 27, width: 27 }} color="success" />
      );
    case "unavailable":
      return (
        <CloseRoundedIcon style={{ height: 27, width: 27 }} color="error" />
      );
    default:
      return null;
  }
};

export default Adornment;

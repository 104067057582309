import React from "react";
//redux
import { useSelector } from "react-redux";
import {
  selectAllViewRoles,
  getRoleFetchState,
} from "redux/features/roleSlice/roleSlice";
//components/ui
import TreeView from "@mui/lab/TreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RoleBranch from "./components/RoleBranch";

const RolesTree = () => {
  const roles = useSelector(selectAllViewRoles);
  const rolesFetchState = useSelector(getRoleFetchState);

  const renderBranches = (nodes) => {
    return (
      <RoleBranch key={nodes.id} data={nodes}>
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderBranches(node))
          : null}
      </RoleBranch>
    );
  };

  return (
    <TreeView
      defaultExpanded={["3"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, overflowY: "auto" }}
      color="text.primary"
    >
      {rolesFetchState === "succeeded" && renderBranches(roles)}
    </TreeView>
  );
}

export default RolesTree;
import { useState, useCallback } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
//Types
import { ISpace, Status } from '@trii/types/dist/Spaces';
// Components/ui
import {
  DomainStepper,
  DomainInfo,
  DomainDeleteButton,
  DeleteDialog,
} from './components';
import { Button, Box, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  workspace: ISpace;
};

const DomainCustomize = ({ workspace }: Props) => {
  const [configDomain, setConfigDomain] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t } = useTranslation();

  const openConfigDomain = useCallback(() => {
    setConfigDomain(true);
  }, [configDomain]);
  const closeConfigDomain = useCallback(() => {
    setConfigDomain(false);
  }, [configDomain]);

  return (
    <>
      <Box
        className="profile-panel-inner p-2 mt-0"
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          marginLeft: "75px",
          //@ts-ignore
          borderColor:  (theme) => theme.palette.borderColor
        }}
      >
        <Box
          flexDirection="column"
          gap={2}
          className="profile-d-flex profile-justify-content-between"
        >
          {workspace?.domain_custom && workspace?.domain_custom.host !== '' && (
            <Box
              display={'flex'}
              flexDirection="row"
              justifyContent={'space-between'}
            >
              <DomainInfo
                domainHost={workspace.domain_custom.host}
                workspaceStatus={Status[workspace.status]}
              />
              <DomainDeleteButton setOpenDeleteDialog={setOpenDeleteDialog} />
            </Box>
          )}
          <Box alignSelf="end">
            <Button
              startIcon={<BorderColorIcon />}
              size="small"
              variant="contained"
              color="inherit"
              sx={{ marginRight: 1 }}
              onClick={openConfigDomain}
            >
              {t('workSpaces.editDomain')}
            </Button>
            <IconButton size="small" color="error" component="button" onClick={closeConfigDomain}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        {configDomain && (
          <DomainStepper
            setConfigDomain={setConfigDomain}
            domainHost={workspace.domain_custom?.host}
          />
        )}
      </Box>
      {openDeleteDialog && (
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        />
      )}
    </>
  );
};

export default DomainCustomize;

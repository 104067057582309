import React, { useState, useEffect, useContext } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components
import { Box, Divider } from '@mui/material';
import { SidePanel } from './components/index';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import ApiKeysProvider, { InitApiKeysContext } from './context/ApiKeysContext';
import { fetchAPIKeys, selectAPIKeyCreateStatus } from 'redux/features/APIKeysSlice/APIKeysSlice';
import ModalSuccess from './components/SidePanel/components/ModalSuccess/ModalSuccess';
import { ApiKeysContext } from './context/types';

export default function ApiKeys() {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  
  // Obtener el valor del contexto
  const { secret, setSecret, closeCreatePanel, modalSuccess, setModalSuccess } = useContext<ApiKeysContext>(InitApiKeysContext);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAPIKeys());
    };

    fetchData();
  }, [dispatch]);

  // Añadir un useEffect para asegurarse de que React reaccione cuando modalSuccess cambie
  useEffect(() => {
    if (modalSuccess) {
      console.log('Modal success is true');
    }
  }, [modalSuccess]);

  const handleCloseModal = () => {
    try {
      setModalSuccess(false);
      setSecret(null);
      closeCreatePanel();
    } catch (e) {
      console.error('Error closing modal', e);
    }
  };

  return (
    <ApiKeysProvider>
      <Box display={'flex'} height={'100%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'90%'}
          width={'100%'}
          className={'conversations-fadeinup conversations-paddingContent'}
          borderRadius={2}
          mt={1}
          position="relative"
        >
          <Header />
          <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
          <Body />
        </Box>
        <SidePanel />

        {/* Import and use the ApiKeyModal */}
        <ModalSuccess      
        />
      </Box>
    </ApiKeysProvider>
  );
}

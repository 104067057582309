import axios from 'axios';

const URL = 'https://account.trii.app/api/v1/Domains/Check';

const getSubdomainAvailability = async (
  subdomainJson: string
): Promise<{ available: boolean }> => {
  const response = await axios.post(URL, subdomainJson);

  return response.data;
};

export default { getSubdomainAvailability };

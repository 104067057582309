import { useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { UsersContext } from '../../../../../../context/UsersContext';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchUserById } from 'redux/features/usersSlice/usersSlice';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { Avatar, Chip, ListItemAvatar } from '@mui/material';
import { Box } from '@mui/system';
import UserType from '@trii/types/dist/Users/UserType';
// Functions
import { stringAvatar } from 'redux/functions/stringAvatar';

type Props = {
  user: UserInfo;
};

export default function UserListItem({ user }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setIsEditing, selectedUserId, setSelectedUserId } =
    useContext(UsersContext);
  const { fontSize } = useContext(StyleContext);
  const [userType, setUserType] = useState<string>('');
  const [avatarChild, setAvatarChild] = useState<string>('');
  const [isJoinPending, setIsJoinPending] = useState<boolean>(false);

  const handleUserSelect = async () => {
    setIsEditing(false);
    setSelectedUserId(user.id);

    await dispatch(fetchUserById(user.id));
  };

  useEffect(() => {
    if (user) {
      // Get the key of the enum value
      const newAvatarChild = stringAvatar(user.name);

      setAvatarChild(newAvatarChild);
      const entries = Object.entries(UserType);
      const entry = entries.find((entry) => entry[1] === user.userType);

      setUserType(entry[0]);
      if (!user.joinTimeStamp && !user.isActive) {
        setIsJoinPending(true);
        return;
      }


    }
  }, [user]);
  console.log('User', user);
  return (
    <>
      <ListItemButton
        selected={selectedUserId === user.id}
        className="fadein"
        onClick={handleUserSelect}
        style={{ gap: 10, padding: 10 }}
      >
        <ListItemAvatar sx={{ textAlign: "-webkit-center" }}>
          <Avatar
            alt={user.name}
            src={user.imageUrl}
            style={{
              width: 55,
              height: 55,
              borderRadius: "50%",
              objectFit: "cover",
              border: "1px solid #ced1d5",
            }}
            children={avatarChild}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={fontSize.md} sx={{ color: "text.primary" }}>
                {`${user.name}`}
              </Typography>
              {isJoinPending ? (
                <Box>
                  <Chip
                    label={t("usersInfo.joinPending").toUpperCase()}
                    size="small"
                    sx={{
                      backgroundColor: (theme) =>
                        `${theme.palette.primary.light}50`,
                      color: "text.primary",
                      height: "17px",
                      borderRadius: "5px",
                      "& span": {
                        paddingX: "3px",
                        fontSize: "10px",
                      },
                      marginRight: "5px",
                    }}
                  />
                  <Chip
                    label={userType}
                    size="small"
                    sx={{
                      backgroundColor: (theme) =>
                        `${theme.palette.primary.light}50`,
                      color: "text.primary",
                      height: "17px",
                      borderRadius: "5px",
                      "& span": {
                        paddingX: "3px",
                        fontSize: "10px",
                      },
                    }}
                  />
                </Box>
              ) : (
                <Chip
                  label={userType}
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.light}50`,
                    color: "text.primary",
                    height: "17px",
                    borderRadius: "5px",
                    "& span": {
                      paddingX: "3px",
                      fontSize: "10px",
                    },
                  }}
                />
              )}
            </Box>
          }
          secondary={
            <Typography fontSize={fontSize.sm} sx={{ color: "text.primary" }}>
              {user.email}
            </Typography>
          }
        />
      </ListItemButton>
      <Divider variant="middle" component="li" />
    </>
  );
}

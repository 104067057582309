import { Profile } from 'features/Views/Users/components/TableBody/components/UsersPanel/context/types/Profile';

const roles: Profile[] = [
  {
    id: '1',
    name: 'CEO',
    description: 'Tiene acceso a todos los permisos',
    permissions: {
      ver: 'Ver',
      crear: 'Crear',
      editar: 'Editar',
      eliminar: 'Eliminar',
    },
  },
  {
    id: '2',
    name: 'Administrador',
    description: 'Tiene acceso a ciertos permisos.',
    permissions: {
      ver: 'Ver',
      crear: 'Crear',
      editar: 'Editar',
      eliminar: 'Eliminar',
    },
  },
  {
    id: '3',
    name: 'Supervisor',
    description: 'Tiene acceso a ciertos permisos.',
    permissions: {
      ver: 'Ver',
      crear: 'Crear',
      editar: 'Editar',
      eliminar: 'Eliminar',
    },
  },
];

export default roles;

// Components/ui
import { Alert, Snackbar } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setResendInviteStatus } from 'redux/features/usersSlice/usersSlice';

type InvitationErrorProps = {
  open: boolean;
};

const InvitationError = ({ open }: InvitationErrorProps) => {
  const dispatch = useAppDispatch();

  function handleClose() {
    dispatch(setResendInviteStatus('idle'));
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        Error sending invitation
      </Alert>
    </Snackbar>
  );
};

export default InvitationError;

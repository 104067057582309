import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfilesContext } from 'features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext';
// Components/ui
import { TabPanel } from 'components';
import { SwitchSection, UserItem } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectProfileUsers } from 'redux/features/profilesSlice/profilesSlice';
import { Box } from '@mui/material';

export default function ProfileInfoBodyPanels({ tab }) {
  const { t } = useTranslation();
  const {
    collectionsSwitches,
    conversationSwitches,
    contactSwitches,
    marketingSwitches,
    serviceSwitches,
    ticketSwitches,
    chatSwitches,
    callSwitches,
    calendarSwitches,
    crmSwitches,
    dataSyncSwitches,
    signatureSwitches,
    // agentSwitches,
  } = useContext(ProfilesContext);

  const profileUsers = useSelector(selectProfileUsers);

  const profilePermissionsSections = [
    {
      title: t('conversations.conversations'),
      actions: [
        t('enabled'),
        t('conversations.cannedResponsesAdmin'),
        t('conversations.cannedResponsesCreate'),
        t('conversations.dashboard'),
        t('editConfig'),
        t('conversations.editMsgNote'),
        t('conversations.endFromInbox'),
        t('conversations.enterIncognitoMode'),
        t('conversations.otherInboxes'),
        t('conversations.otherInboxesUsers'),
        t('conversations.otherInboxesGroups'),
        t('conversations.showUnassignedConversations'),
      ],
      stateHandlers: conversationSwitches,
    },
    {
      title: 'Tickets',
      actions: [
        t('enabled'),
        t('tickets.create'),
        t('tickets.delete'),
        t('tickets.edit'),
        t('tickets.update'),
      ],
      stateHandlers: ticketSwitches,
    },
    {
      title: t('collections'),
      actions: [t('enabled')],
      stateHandlers: collectionsSwitches,
    },
    {
      title: 'Service',
      actions: [t('enabled')],
      stateHandlers: serviceSwitches,
    },
    {
      title: 'Marketing',
      actions: [t('enabled')],
      stateHandlers: marketingSwitches,
    },
    {
      title: t('contacts.contacts'),
      actions: [
        t('enabled'),
        t('editConfig'),
        t('contacts.export'),
        t('contacts.import'),
        t('contacts.join'),
        t('contacts.delete'),
      ],
      stateHandlers: contactSwitches,
    },
    {
      title: 'Chat',
      actions: [t('enabled'),t('editConfig')],
      stateHandlers: chatSwitches,
    },
    {
      title: t('global.calls'),
      actions: [t('enabled'),t('editConfig'), t('conversations.dashboard')],
      stateHandlers: callSwitches,
    },
    {
      title: t('global.calendar'),
      actions: [t('enabled'),t('editConfig')],
      stateHandlers: calendarSwitches,
    },
    {
      title: 'CRM',
      actions: [t('enabled'),t('editConfig')],
      stateHandlers: crmSwitches,
    },
    {
      title: 'DataSync',
      actions: [t('enabled'),t('editConfig')],
      stateHandlers: dataSyncSwitches,
    },
    {
      title: t('global.signature'),
      actions: [t('enabled'), t('editConfig')],
      stateHandlers: signatureSwitches,
    }
  ];

  const ProfilePermissionsConfig = profilePermissionsSections.map(
    (section, index) => (
      <SwitchSection
        sectionTitle={section.title}
        sectionActions={section.actions}
        sectionStateHandlers={section.stateHandlers}
        key={index}
      />
    )
  );

  return (
    <>
      <TabPanel tabValue={tab} index={0}>
        {ProfilePermissionsConfig}
      </TabPanel>
      <TabPanel tabValue={tab} index={1}>
        <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
          {profileUsers.map((user, index) => (
            <UserItem
              avatarUrl={user.imageUrl}
              displayName={user.name}
              key={index}
            />
          ))}
        </Box>
      </TabPanel>
    </>
  );
}

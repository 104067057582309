import { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { getUserFetchByIdStatus } from 'redux/features/usersSlice/usersSlice';
// Components/ui
import { Header, Body } from './components';
import { Fade, Box, CircularProgress } from '@mui/material';
// Types
import { UserTrii } from '@trii/types/dist/Users';

type Props = {
  user: UserTrii;
};

const UserProfile = ({ user }: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const userFetchByIdStatus = useSelector(getUserFetchByIdStatus);
  const isLoading = userFetchByIdStatus === 'loading';

  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <Fade in={isMounted}>
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex" gap="0.5rem" maxHeight="100%" flexDirection="column">
            <Header user={user} />
            <Body user={user} />
          </Box>
        )}
    </Fade>
  );
};

export default UserProfile;

import { UseFieldType } from 'hooks/useField';

export const fieldInitialDef: UseFieldType = {
  actions: {
    onError: () => {},
    onSuccess: () => {},
    resetValue: () => {},
    setValueDirect: (value: string | number) => {}
  },
  attributes: {
    error: false,
    helperText: '',
    type: '',
    value: '',
    onChange: () => {},
  },
};

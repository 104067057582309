// Translations
import { useTranslation } from 'react-i18next'
// Components/ui
import {
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material"

const EmptyLogs = () => {
  const { t } = useTranslation()

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={3} sx={{ width: '100%' }}>
          <Typography sx={{ textAlign: 'center' }}>
            {t('tableContent.noLogs')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default EmptyLogs
// Router
import { useParams } from "react-router-dom"
// Components/ui
import { Box } from "@mui/material"
// Components
import { Layout } from "./components"
// Image
import Error401 from "./assets/images/401.svg"
import Error403 from "./assets/images/403.svg"
import Error404 from "./assets/images/404.svg"
import Error500 from "./assets/images/500.svg"

const ErrorPage = () => {
  const { code } = useParams<{ code: string }>()
  console.log(code)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        width: "100%",
        height: "100%",
      }}
    >
      {
        code === "401" && (
          <Layout
            title="error.unauthorized.title"
            subtitle="error.unauthorized.subtitle"
            image={Error401}
          />
        )
      }
      {
        code === "403" && (
          <Layout
            title="error.forbidden.title"
            subtitle="error.forbidden.subtitle"
            image={Error403}
          />
        )
      }
      {
        code === "404" && (
          <Layout
            title="error.notFound.title"
            subtitle="error.notFound.subtitle"
            image={Error404}
          />
        )
      }
      {
        code === "500" && (
          <Layout
            title="error.internalServerError.title"
            subtitle="error.internalServerError.subtitle"
            image={Error500}
          />
        )
      }
    </Box>
  )
}

export default ErrorPage
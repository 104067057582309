import { createSlice } from '@reduxjs/toolkit';
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
// Types
import PoolSliceState from './types/PoolSliceState';
import { RootState } from '../../rootReducer';

const initialState = {
  pool: new CognitoUserPool({
    UserPoolId: 'sa-east-1_81dIzrFFU',
    ClientId: '12rg9i8fotah5dt9ms8mfmn2mq',
    Storage: new CookieStorage({
      secure: false,
      domain: window.location.host.replace(':8080', '').replace(':8081', ''),
    }),
  }),
};

const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {},
});

export const selectPool = (state: RootState) => state.Pool.pool;

export default poolSlice.reducer;

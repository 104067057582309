import React from "react";
import Box from "@mui/material/Box";
import { SearchBar, RolesTree } from "./components";

const SidebarNavRoles = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={"50%"}
      flexGrow={1}
      height={"100%"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius={"2px"}
      mr={1}
    >
      <SearchBar />
      <RolesTree />
    </Box>
  );
};

export default SidebarNavRoles;

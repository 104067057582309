import React, { useState, useContext } from 'react';
import { SecurityContext } from 'features/Views/Security/context/securityContext';
import { useTranslation } from 'react-i18next';
//components/ui
import { ParallelSessionsOption, Selector } from './components';
import { Box, Divider, SelectChangeEvent, Typography } from '@mui/material';
// Utils
import { days, minutes } from './utils/sessionTimeoutOptionList';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { selectSecurity } from 'redux/features/securitySlice/securitySlice';

const SessionManagementPanel = () => {
  const { t } = useTranslation();

  const security = useSelector(selectSecurity);

  const { sessions_concurrentSessions, sessions_idleSessionTimeout } = security;

  return (
    <Box
      id="panel-2"
      className="profile-panel"
      sx={{
        backgroundColor: 'background.panel',
        marginBottom: '0px !important',
      }}
    >
      <Box className="profile-panel-container">
        <Box className="profile-panel-content">
          <Box>
            <Typography
              variant="h6"
              className="h3 profile-panel-content"
              sx={{ color: 'text.primary' }}
            >
              {t('security.sessionManagement.title')}
            </Typography>
          </Box>
          <Divider />
          <ParallelSessionsOption
            value={sessions_concurrentSessions}
            title="security.sessionManagement.numberOfParallelSessions"
            description="security.sessionManagement.numberOfParallelSessionsDescription"
          />
          <Selector
            value={sessions_idleSessionTimeout}
            title="security.sessionManagement.idleSessionTimeout"
            description="security.sessionManagement.idleSessionTimeoutDescription"
            list={minutes}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SessionManagementPanel;

import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';
import { initRequestData } from '../../functions/initRequestData';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import apiKeyService from './APIKeysService';
import { ApiKey } from '@trii/types/dist/PublicAPI/apiKey';
import { RequestStatus } from 'redux/types/RequestStatus';

export interface APIKeysState {
  APIKeys: ApiKey[];
  status: {
    fetch: RequestStatus;
    create: RequestStatus;
    update: RequestStatus;
    delete: RequestStatus;
  };
}

const initialState: APIKeysState = {
  APIKeys: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    update: 'idle',
    delete: 'idle',
  },
};

export const fetchAPIKeys = createAsyncThunk(
  'apiKey/fetchAPIKeys',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await apiKeyService.fetchAPIKeys(jwtToken, URL_SETTINGS);
    return response;
  }
);
export const fetchAPIKeyId = createAsyncThunk(
  'apiKey/fetchAPIKeyId',
  async (data: ApiKey, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await apiKeyService.fetchAPIKeyId(jwtToken, URL_SETTINGS, data);
    return response;
  }
);

export const createAPIKey = createAsyncThunk(
  'apiKey/createAPIKey',
  async (apiKey: ApiKey, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await apiKeyService.createAPIKey(jwtToken, URL_SETTINGS, apiKey);
    return response;
  }
);

export const deleteAPIKey = createAsyncThunk(
  'apiKey/deleteAPIKey',
  async ({ id, name }: { id: string; name: string }, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await apiKeyService.deleteAPIKey(jwtToken, URL_SETTINGS, id, name);
    return response;
  }
);

export const updateAPIKey = createAsyncThunk(
  'apiKey/updateAPIKey',
  async (apiKey: ApiKey, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await apiKeyService.updateAPIKey(jwtToken, URL_SETTINGS, apiKey);
    return apiKey;
  }
);

const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAPIKeys.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchAPIKeys.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.APIKeys = action.payload;
      })
      // .addCase(fetchAPIKeyId.pending, (state) => {
      //   state.status.fetch = 'loading';
      // })
      .addCase(fetchAPIKeyId.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // Update the specific API key data in the state
        const fetchedApiKey = action.payload;
        const existingApiKeyIndex = state.APIKeys.findIndex(apiKey => apiKey.id === fetchedApiKey.id);
        if (existingApiKeyIndex >= 0) {
          state.APIKeys[existingApiKeyIndex] = fetchedApiKey;
        } else {
          state.APIKeys.push(fetchedApiKey);
        }
      })
      .addCase(fetchAPIKeyId.rejected, (state) => {
        state.status.fetch = 'rejected';
      })
      .addCase(createAPIKey.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createAPIKey.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.APIKeys.push(action.payload);
      })
      .addCase(deleteAPIKey.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteAPIKey.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.APIKeys = state.APIKeys.filter((apiKey) => apiKey.id !== action.payload);
      })
      .addCase(updateAPIKey.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateAPIKey.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        const editAPIKey = action.payload;
        state.APIKeys = state.APIKeys.map((apiKey) => {
          if (apiKey.id === editAPIKey.id) {
            return editAPIKey;
          }
          return apiKey;
        });
      });
  },
});

const selectAPIKeyState = (state: RootState) => state.ApiKey;
export const selectAllAPIKeys = createSelector(
  selectAPIKeyState,
  (state) => state.APIKeys
);
export const selectAPIKeysFetchStatus = createSelector(
  selectAPIKeyState,
  (state) => state.status.fetch
);
export const selectAPIKeyCreateStatus = createSelector(
  selectAPIKeyState,
  (state) => state.status.create
);
export const selectAPIKeyDeleteStatus = createSelector(
  selectAPIKeyState,
  (state) => state.status.delete
);
export const selectAPIKeyUpdateStatus = createSelector(
  selectAPIKeyState,
  (state) => state.status.update
);

// New selector to select an API key by ID
export const selectAPIKeyById = (id: string) =>
  createSelector(selectAllAPIKeys, (APIKeys) =>
    APIKeys.find((apiKey) => apiKey.id === id)
  );

export default apiKeySlice.reducer;

import React from 'react';
//components/ui
import { CopyIcon } from './components';
import TextField from '@material-ui/core/TextField';
import StatusAdornment from 'components/StatusAdornment';

const CNAMEInput = ({ cnameField, cNameStatus }) => {
  return (
    <TextField
      {...cnameField.attributes}
      fullWidth
      disabled
      id="inputSubdomain"
      variant="outlined"
      size="small"
      InputProps={{
        style: { color: '#000' },
        endAdornment: (
          <>
            <StatusAdornment status={cNameStatus} text={''} />
            <CopyIcon textToCopy={cnameField.attributes.value} />
          </>
        ),
      }}
    />
  );
};

export default CNAMEInput;

import axios from 'axios';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { ISpace } from '@trii/types/dist/Spaces';

const URL = 'https://account.trii.app';

const getWorkspace = async (
  jwtToken: string,
  URL_SPACES_ID: string
): Promise<ISpace> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<ISpace>(`${URL}${URL_SPACES_ID}`, config);
  return response.data;
};

const updateWorkspace = async (
  jwtToken: string,
  workspaceData: string
): Promise<ISpace> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  const response = await axios.put<ISpace>(
    `${URL}/api/v1/Spaces`,
    workspaceData,
    config
  );
  return response.data;
};

const updateWorkspacePhoto = async (
  jwtToken: string,
  URL_SPACES_ID: string,
  photoFormData: FormData
): Promise<ISpace> => {
  const config = getRequestConfig.contentTypeFormData(jwtToken);

  const response = await axios.post<ISpace>(
    `${URL}${URL_SPACES_ID}/setphoto`,
    photoFormData,
    config
  );
  return response.data;
};

const deleteWorkspace = async (
  jwtToken: string,
  URL_SPACES_ID: string
): Promise<ISpace> => {
  const config = getRequestConfig.basic(jwtToken);

  const response = await axios.delete<ISpace>(`${URL}${URL_SPACES_ID}`, config);
  return response.data;
};

const deleteCustomDomain = async (jwtToken: string, URL_SPACES_ID: string) => {
  const config = getRequestConfig.basic(jwtToken);

  const response = await axios.delete(`${URL}${URL_SPACES_ID}/customDomain`, config);
  return response.data;
};

const createVerifyCustomDomain = async (
  jwtToken: string,
  URL_SPACES_ID: string,
  dataJSON: string
): Promise<object> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  const response = await axios.put<object>(
    `${URL}${URL_SPACES_ID}/customDomain`,
    dataJSON,
    config
  );

  return response.data;
};

export default {
  getWorkspace,
  updateWorkspace,
  updateWorkspacePhoto,
  deleteWorkspace,
  deleteCustomDomain,
  createVerifyCustomDomain,
};

import React, { useEffect } from "react";
import { RolesInfo, SidebarNavRoles } from "./components";
import RolesDataProvider from "./context/rolesContext";
//redux
import { fetchRoles } from "redux/features/roleSlice/roleSlice";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";

const RolesPanel = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  return (
    <RolesDataProvider>
      <SidebarNavRoles />
      <RolesInfo />
    </RolesDataProvider>
  );
};

export default RolesPanel;

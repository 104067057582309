import React, { useState, useEffect } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useField from 'hooks/useField';
//redux
import { addRole, updateRole } from 'redux/features/roleSlice/roleSlice';
//components/ui
import { Box } from '@mui/material';
import { Input, SpinnerIcon } from 'components';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
//Types
import { UserRole } from '@trii/types/dist/Users';

export default function RoleDisplay({ role, status }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //form state
  const [isLoading, setIsLoading] = useState(false);
  const buttonTitle =
    status === 'create' ? t('roles.createRole') : t('global.saveChanges');
  //form fields control
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const nameField = useField('text', status === 'create' ? null : role.title);
  const descriptionField = useField(
    'text',
    status === 'create' ? null : role.description
  );
  const asociatedRoleField = useField(
    'text',
    status === 'create' ? role.title : role.parent?.title
  );

  //functions
  const handleCheck = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleAddRole = async () => {
    const newRole = {
      title: nameField.attributes.value,
      description: descriptionField.attributes.value,
      sharedData: checkboxChecked,
      parentId: role.id,
    };

    setIsLoading(true);
    await dispatch(addRole(newRole));
    setIsLoading(false);
  };

  const handleEdditRole = async () => {
    const editedRole = {
      id: role.id,
      title: nameField.attributes.value,
      description: descriptionField.attributes.value,
      sharedData: checkboxChecked,
      parentId: role.parent?.id,
      isDefault: role.isDefault,
      parent: role.parent,
    } as UserRole;

    setIsLoading(true);
    await dispatch(updateRole(editedRole));
    setIsLoading(false);
  };

  useEffect(() => {
    status === 'read'
      ? setCheckboxChecked(role.sharedData)
      : setCheckboxChecked(false);
  }, [role, status]);

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      // sx={{ backgroundColor: 'dropdownBox' }}
      boxShadow={'0px 1px 2px rgba(0,0,0,0.2)'}
      borderRadius="2px"
      height="100%"
      px={3}
      py={1}
    >
      <Box
        display="flex"
        height={'100%'}
        flexDirection={'column'}
        width="100%"
        style={{ gap: 120 }}
        px={2}
        pt={3}
        position="relative"
      >
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          <Input label={t('global.name')} {...nameField} />
          <Input
            label={t('global.detail')}
            {...descriptionField}
            style={{ multiline: true, minRows: 5, maxRows: 5 }}
          />
          <Input
            label={t('rolesInfo.associatedRole')}
            {...asociatedRoleField}
            isDisabled={true}
          />
        </Box>
        <Box>
          <Typography sx={{ color: 'text.primary' }}> Comparti Datos </Typography>
          <FormControlLabel
            control={<Checkbox checked={checkboxChecked} onChange={handleCheck} />}
            label={'Compartir datos con colegas dentro del mismo nivel'}
            sx={{ color: 'text.primary' }}
          />
        </Box>
        <Box mb={3} textAlign="end" position="absolute" right="0" bottom="0">
          <Button
            onClick={status === 'create' ? handleAddRole : handleEdditRole}
            variant="contained"
            size="small"
            disabled={isLoading}
            startIcon={<SpinnerIcon isLoading={isLoading} />}
          >
            {buttonTitle}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import { t } from 'i18next';
// Components/ui
import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
// Context
import { useContext } from 'react';
import { SecurityContext } from 'features/Views/Security/context/securityContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectSecurity,
  setSecondFactorApp,
} from 'redux/features/securitySlice/securitySlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
const TwoFactorsAuth = () => {
  const dispatch = useAppDispatch();

  const { secondFactorOptions } = useContext(SecurityContext);

  const security = useSelector(selectSecurity);

  const { secondFactorApp } = security;

  const stringToBoolean = (value: string): boolean => {
    return value.toLowerCase() === 'true';
  };

  const booleanToString = (value: boolean): 'true' | 'false' => {
    return value ? 'true' : 'false';
  };

  const handleChangeValue = (e: 'true' | 'false') => {
    const booleanValue = stringToBoolean(e);

    dispatch(setSecondFactorApp(booleanValue));
  };

  const renderMenuItems = secondFactorOptions.map((option, i) => (
    <MenuItem key={i} value={option.value.toString()}>
      {option.label}
    </MenuItem>
  ));

  return (
    <Box
      id="panelTwoFactorsAuth"
      className="profile-panel"
      sx={{ backgroundColor: 'background.panel' }}
    >
      <Box className="profile-panel-container">
        <Box className="profile-panel-content">
          <Typography
            variant="h6"
            className="h3 profile-panel-content"
            color="text.primary"
          >
            {t('TwoFactorAuth.title')}(2FA)
          </Typography>
          <Divider />

          <Box className="profile-panel-content" sx={{ textAlign: 'end' }}>
            <FormControl sx={{ maxWidth: 300 }}>
              <Select
                value={booleanToString(secondFactorApp)}
                onChange={(e) =>
                  handleChangeValue(e.target.value as 'true' | 'false')
                }
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
              >
                {renderMenuItems}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TwoFactorsAuth;

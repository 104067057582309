import React from 'react';
// Redux
import { useSelector } from 'react-redux';

// Components/ui
import { Box } from '@mui/material';
import { GroupItem, GroupItemSkeleton } from './components';
import ApiKeysListItem from './components/ApiKeysListItem/ApiKeysListItem';
import ApiKeysListSkeleton from './components/ApiKeysListSkeleton/ApiKeysListSkeleton';
import { selectAllAPIKeys, selectAPIKeysFetchStatus } from 'redux/features/APIKeysSlice/APIKeysSlice';

export default function Body() {
  const apiKeys = useSelector(selectAllAPIKeys);
  const fetchStatus = useSelector(selectAPIKeysFetchStatus);

  const APIList =
    fetchStatus === 'succeeded' ? (
      apiKeys.map((apiKey) => <ApiKeysListItem key={apiKey.id} data={apiKey} />)
    ) : (
      <ApiKeysListSkeleton />
    );

  return (
    <Box sx={{ maxHeight: '90%' }}>
      <Box
        sx={{
          py: 2,
          px: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxHeight: 'calc(90vh - 64px - 48px)',
          overflowY: 'auto',
        }}
      >
        {APIList}
      </Box>
    </Box>
  );
}
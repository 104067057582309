import React, { useContext, useState } from 'react';
import { UsersContext } from 'features/Views/Users/components/TableBody/components/UsersPanel/context/UsersContext';
import { useTranslation } from 'react-i18next';
//redux
import { useSelector } from 'react-redux';
import { selectAllUsers } from 'redux/features/usersSlice/usersSlice';
//components/ui
import { PopupCreateUser } from 'components';
import {
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Slide,
  styled,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/system';
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';

type TooltipProps = {
  className?: string;
  title: string;
  placement: 'bottom';
  children: React.ReactElement;
};

export default function SearchBar() {
  const { t } = useTranslation();
  const containerRef = React.useRef(null);
  const users = useSelector(selectAllUsers);
  const { setBuscando, setDataBusqueda } = useContext(UsersContext);
  const [busqueda, setBusqueda] = useState('');
  const [checked, setChecked] = React.useState(false);
  const [openPopupCreateUser, setOpenPopupCreateUser] = useState(false);
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const handleSearchBarChange = (e) => {
    e.persist();
    setBusqueda(e.target.value);

    if (e.target.value !== '') {
      setBuscando(true);
    } else {
      setBuscando(false);
    }

    filtrarElementos(e.target.value);
  };

  const filtrarElementos = (filtro) => {
    const search = users.filter((user) =>
      user.name.toLowerCase().includes(filtro.toLowerCase())
    );

    setDataBusqueda(search);
  };

  const showInputSearch = () => {
    setChecked(true);
  };

  const closeInputSearch = () => {
    setChecked(false);
  };

  const onOpenCreateUser = () => {
    setOpenPopupCreateUser(true);
  };

  React.useEffect(() => {
    document.documentElement.style.setProperty('--search-background', onSelectedTheme.palette.background.default);
    if (checked === true) document.getElementById('busquedaUsuario').focus();
  }, [checked]);

  return (
    <>
      <Box
        boxShadow={'0px 2px 3px rgba(0,0,0,0.1)'}
        borderRadius="2px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={2}
        paddingTop={1}
        paddingBottom={1}
        position="relative"
        ref={containerRef}
      >
        <Typography sx={{ color: 'text.primary' }}>
          {t('usersInfo.active')}: {users.length}
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton
            style={{ alignSelf: 'center', marginRight: '5px' }}
            color="primary"
            onClick={onOpenCreateUser}
          >
            <Tooltip title={t('usersInfo.createUser')} placement="bottom">
              <PersonAddAltIcon />
            </Tooltip>
          </IconButton>
          <InputAdornment
            position="start"
            onClick={showInputSearch}
            sx={{ cursor: 'pointer' }}
          >
            <SearchOutlinedIcon />
          </InputAdornment>
        </Box>
        <Slide
          direction="right"
          in={checked}
          className="profile-inputSearchUsers"
          container={containerRef.current}
        >
          <Box style={{ display: 'flex', width: '100%' }}>
            <OutlinedInput
              name="busqueda"
              id="busquedaUsuario"
              value={busqueda}
              size={'small'}
              onChange={handleSearchBarChange}
              placeholder={t('global.searchByUserName')}
              style={{ width: '100%' }}
              endAdornment={
                <CloseIcon
                  onClick={closeInputSearch}
                  sx={{
                    cursor: 'pointer',
                    color: 'rgba(0, 0, 0, 0.54)',
                    width: '0.8em',
                  }}
                ></CloseIcon>
              }
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
            />
          </Box>
        </Slide>
      </Box>
      <PopupCreateUser
        isOpen={openPopupCreateUser}
        setOpenPopupCreateUser={setOpenPopupCreateUser}
      ></PopupCreateUser>
    </>
  );
}

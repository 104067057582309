import React from "react";
import ProfilesDataProvider from "./context/profilesContext";
//comṕonents/ui
import { ProfileInfo, SidebarNavProfile } from "./components";

const ProfilePanel = () => {
  return (
    <ProfilesDataProvider>
      <SidebarNavProfile />
      <ProfileInfo />
    </ProfilesDataProvider>
  );
};

export default ProfilePanel;

import { createContext, useState } from 'react';
import useField from 'hooks/useField';
// Types
import { IRolesContext } from './types/RolesContext';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { fetchRoleUsers } from 'redux/features/roleSlice/roleSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const RolesContext = createContext<IRolesContext>({
  handleOpenDeleteErrorPopup: () => {},
  handleCloseDeleteErrorPopup: () => {},
  showDeleteErrorPopup: false,
  showDeletePopup: false,
  handleOpenDeletePopup: () => {},
  handleCloseDeletePopup: () => {},
  roleView: null,
  setRoleView: () => {},
  nameField: fieldInitialDef,
  descriptionField: fieldInitialDef,
  asociatedRoleField: fieldInitialDef,
  selectedRole: null,
  setSelectedRole: () => {},
  handleSelectCreateRole: () => {},
  handleSelectRole: () => {},
});

const RolesDataProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  const [selectedRole, setSelectedRole] = useState(null);
  const [roleView, setRoleView] = useState(null);
  //form state
  const nameField = useField('text', roleView === 'read' ? selectedRole : null);
  const descriptionField = useField(
    'text',
    roleView === 'read' ? selectedRole : null
  );
  const asociatedRoleField = useField('text', selectedRole);
  //delete popup state
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteErrorPopup, setShowDeleteErrorPopup] = useState(false);

  const handleOpenDeletePopup = () => {
    setShowDeletePopup(true);
  };

  const handleOpenDeleteErrorPopup = () => {
    setShowDeleteErrorPopup(true);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const handleCloseDeleteErrorPopup = () => {
    setShowDeleteErrorPopup(false);
  };

  const handleSelectCreateRole = (event, role) => {
    event.stopPropagation();
    setRoleView('create');
    setSelectedRole(role);
  };

  const handleSelectRole = (role) => {
    setRoleView('read');
    setSelectedRole(role);

    if (role?.id) {
      dispatch(fetchRoleUsers(role.id as string));
    }
  };

  return (
    <RolesContext.Provider
      value={{
        handleOpenDeleteErrorPopup,
        handleCloseDeleteErrorPopup,
        showDeleteErrorPopup,
        showDeletePopup,
        handleOpenDeletePopup,
        handleCloseDeletePopup,
        roleView,
        setRoleView,
        nameField,
        descriptionField,
        asociatedRoleField,
        selectedRole,
        setSelectedRole,
        handleSelectCreateRole,
        handleSelectRole,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export default RolesDataProvider;

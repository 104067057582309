import axios from 'axios';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { UserProfile } from '@trii/types/dist/Users';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import profilesService from './profilesService';
import getRequestConfig from 'redux/functions/getRequestConfig';

const profilesAdapter = createEntityAdapter<UserProfile>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchProfiles = createAsyncThunk(
  'Profiles/fetchProfiles',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.get(`${URL_SETTINGS}/profiles`, config);

    return response.data;
  }
);

export const addProfile = createAsyncThunk(
  'Profiles/addProfile',
  async (profile: any, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const profileJson = JSON.stringify(profile);

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };

    const response = await axios.post(
      `${URL_SETTINGS}/profiles`,
      profileJson,
      config
    );

    return response.data;
  }
);

export const deleteProfile = createAsyncThunk(
  'Profiles/deleteProfile',
  async (deleteData: { profileId: string; newProfileID: string }, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const { profileId, newProfileID } = deleteData;

    const config = {
      ...getRequestConfig.contentTypeJson(jwtToken),
      data: { profileId: newProfileID },
    };

    const response = await axios.delete(
      `${URL_SETTINGS}/profiles/${profileId}`,
      config
    );

    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  'Profiles/updateProfile',
  async (profile: any, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const profileJson = JSON.stringify(profile);

    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${URL_SETTINGS}/profiles/${profile.id}`,
      profileJson,
      config
    );

    return response.data;
  }
);

export const fetchProfileUsers = createAsyncThunk(
  'Profiles/fetchProfileUsers',
  async (profileId: string, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await profilesService.fetchProfileUsers(
      `${URL_SETTINGS}/users`,
      jwtToken,
      profileId
    );

    return response;
  }
);

const profilesSlice = createSlice({
  name: 'Profiles',
  initialState: profilesAdapter.getInitialState({
    status: 'idle',
    profilesStatus: 'idle',
    users: [],
  }),
  reducers: {
    profileAdded: profilesAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        const profiles = action.payload;

        state.status = 'fulfilled';
        profilesAdapter.setAll(state, profiles);
      })
      .addCase(addProfile.fulfilled, (state, action) => {
        profilesAdapter.setOne(state, action.payload);
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        profilesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(fetchProfileUsers.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(fetchProfileUsers.fulfilled, (state, action) => {
        state.profilesStatus = 'fulfilled';
        state.users = action.payload;
      });
  },
});

export const { selectAll: selectAllProfiles, selectById: selectProfileById } =
  profilesAdapter.getSelectors((state: any) => state.Profiles);
export const getFetchProfilesStatus = (state: any) => state.Profiles.status;
export const getFetchProfileUsersStatus = (state: any) =>
  state.Profiles.profilesStatus;
export const selectProfileUsers = (state: any) => state.Profiles.users;

export default profilesSlice.reducer;

// Utils
import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { DashboardData } from './types/DashboardData';

async function fetchDashboardInfo(jwtToken: string, URL_SETTINGS: string) {
  const config = getRequestConfig.basic(jwtToken);

  const response = await axios.get<DashboardData>(
    `${URL_SETTINGS}/dashboard`,
    config
  );

  return response.data;
}

export default {
  fetchDashboardInfo,
};

const dateFormatList = [
  {
    value: "DD-MM-YYYY",
    description: "DD-MM-YYYY ",
  },
  {
    value: "DD/MM/YYYY",
    description: "DD/MM/YYYY ",
  },
  {
    value: "DD.MM.YYYY",
    description: "DD.MM.YYYY",
  },
  {
    value: "DD MM YYYY ",
    description: "DD MM YYYY",
  },
  {
    value: "DD-MM-YY",
    description: "DD-MM-YY",
  },
  {
    value: "DD.MM.YY",
    description: "DD.MM.YY",
  },
  {
    value: "DD/MM/YY",
    description: "DD/MM/YY",
  },
  {
    value: "D.MM.YY",
    description: "D.MM.YY",
  },
  {
    value: "D-M-YY",
    description: "D-M-YY",
  },
  {
    value: "D/M/YY",
    description: "D/M/YY",
  },
  {
    value: "D.M.YY",
    description: "D.M.YY",
  },
  {
    value: "D.M.YY.",
    description: "D.M.YY.",
  },
  {
    value: "D-M-YYYY",
    description: "D-M-YYYY",
  },
  {
    value: "D/M/YYYY",
    description: "D/M/YYYY",
  },
  {
    value: "D.M.YYYY",
    description: "D.M.YYYY",
  },
  {
    value: "D. M. YYYY.",
    description: "D. M. YYYY.",
  },
  {
    value: "D. M. YYYY",
    description: "D. M. YYYY",
  },
  {
    value: "D MMM, YYYY",
    description: "D MMM, YYYY",
  },
  {
    value: "DD MMM, YYYY",
    description: "DD MMM, YYYY",
  },
  {
    value: "D. MMMM YYYY",
    description: "D. MMMM YYYY",
  },
  {
    value: "DD.MM.YYYY.",
    description: "DD.MM.YYYY.",
  },
  {
    value: "DD.MM.YY.",
    description: "DD.MM.YY.",
  },
  {
    value: "YYYY-MM-DD",
    description: "YYYY-MM-DD",
  },
  {
    value: "YYYY/MM/DD",
    description: "YYYY/MM/DD",
  },
  {
    value: "YYYY.MM.DD",
    description: "YYYY.MM.DD",
  },
  {
    value: "YYYY MM DD",
    description: "YYYY MM DD",
  },
  {
    value: "YY/MM/DD",
    description: "YY/MM/DD",
  },
  {
    value: "YY-MM-DD",
    description: "YY-MM-DD",
  },
  {
    value: "YY.M.D",
    description: "YY.M.D",
  },
  {
    value: "YY-M-D",
    description: "YY-M-D",
  },
  {
    value: "YY. M. D",
    description: "YY. M. D",
  },
  {
    value: "YYYY/M/D",
    description: "YYYY/M/D",
  },
  {
    value: "YYYY年MM月DD日",
    description: "YYYY年MM月DD日",
  },
  {
    value: "YY年M月D日",
    description: "YY年M月D日",
  },
  {
    value: "YYYY.MM.DD.",
    description: "YYYY.MM.DD.",
  },
  {
    value: "YYYY. MM. DD",
    description: "YYYY. MM. DD",
  },
  {
    value: "MM-DD-YYYY",
    description: "MM-DD-YYYY",
  },
  {
    value: "MM/DD/YYYY",
    description: "MM/DD/YYYY",
  },
  {
    value: "MM.DD.YYYY",
    description: "MM.DD.YYYY",
  },
  {
    value: "MM DD YYYY",
    description: "MM DD YYYY",
  },
  {
    value: "MM-DD-YY",
    description: "MM-DD-YY",
  },
  {
    value: "MM/DD/YY",
    description: "MM/DD/YY",
  },
  {
    value: "M/DD/YY",
    description: "M/DD/YY",
  },
  {
    value: "MMM-DD-YYYY",
    description: "MMM-DD-YYYY",
  },
  {
    value: "MMM DD, YYYY",
    description: "MMM DD, YYYY",
  },
  {
    value: "MMM D, YYYY",
    description: "MMM D, YYYY",
  },
  {
    value: "MMMM D, YYYY",
    description: "MMMM D, YYYY",
  },
  {
    value: "YYYY.DD.MM",
    description: "YYYY.DD.MM",
  },
  {
    value: "YY.D.M",
    description: "YY.D.M",
  },
];

export default dateFormatList;

import { useTranslation } from 'react-i18next';
// Components/ui
import { EditWorkspacePanel } from 'components';
import { ImageInput, NameSection } from './components';
import { Grid, Box } from '@mui/material';
// Types
import { ISpace } from '@trii/types/dist/Spaces';

type Props = {
  workspace: ISpace;
};

const WorkspaceProfilePanel = ({ workspace }: Props) => {
  const { t } = useTranslation();

  return (
    <EditWorkspacePanel title={t('workSpaces.profile')}>
      <Grid item container xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          width={1}
          margin={'0 auto'}
        >
          <ImageInput imageUrl={workspace?.image_url} />
          <NameSection workspace={workspace} />
        </Box>
      </Grid>
    </EditWorkspacePanel>
  );
};

export default WorkspaceProfilePanel;

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
//redux
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  selectAllProfiles,
  fetchProfiles,
} from "redux/features/profilesSlice/profilesSlice";
//components/ui
import { SearchBar, ProfilesList } from "./components";

const SidebarNavProfile = () => {
  const dispatch = useAppDispatch();
  const profiles = useSelector(selectAllProfiles);

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={"33.3%"}
      flexGrow={1}
      height={"100%"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius={"2px"}
      mr={1}
      maxWidth={"350px"}
      minWidth={"350px"}
    >
      <SearchBar />
      <ProfilesList profiles={profiles} />
    </Box>
  );
};

export default SidebarNavProfile;

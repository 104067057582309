import { Avatar, Badge, Box, IconButton, styled } from "@mui/material";
//redux
import { useSelector } from "react-redux";
import { selectUser } from "redux/features/userSlice/userSlice"
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { style } from "./style";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.divider}`,
  mr: "5px",
  color: "red",
}));

interface ImageSelectProps {
  children: React.ReactNode;
  isEditing: boolean;
  isHover: boolean;
  savingImage: boolean;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteImage: () => void;
}

const ImageSelect = ({
  children,
  isEditing,
  isHover,
  savingImage,
  handleImageChange,
  handleDeleteImage,
}: ImageSelectProps) => {
  const user = useSelector(selectUser);

  const isEditingImageStyle = isEditing
    ? {
      cursor: "pointer",
      opacity: isHover ? "0.5" : "1",
      transition: "all 0.15s",
    }
    : {};

  const styleAvatar = {
    borderRadius: "50%",
    border: `2.5px solid ${theme => theme.palette.divider}`,
    ...isEditingImageStyle,
  };

  if (isEditing) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar>
            <IconButton
              onClick={() => handleDeleteImage()}
              disabled={savingImage || !user?.imageUrl}
              style={{
                padding: "0px",
              }}
            >
              <DeleteIcon sx={style.avatarDelete}></DeleteIcon>
            </IconButton>
          </SmallAvatar>
        }
      >
        {isHover && <AddAPhotoIcon sx={style.avatarUpdateIcon} />}
        <label
          style={{
            ...styleAvatar,
          }}
        >
          <input
            type="file"
            onChange={(event) => handleImageChange(event)}
            style={{ display: "none" }}
            disabled={savingImage}
            accept="image/*"
          />
          {children}
        </label>
      </Badge>
    );
  }

  return (
    <Box
      style={{
        ...styleAvatar,
      }}
    >
      {children}
    </Box>
  );
};

export default ImageSelect;

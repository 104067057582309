import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersContext } from '../../../../../../context/UsersContext';
import { StyleContext } from 'style/styleProvider';
import { DataContext } from 'context/contextApi';
// Redux
import { useSelector } from 'react-redux';
import {
  deleteUser,
  fetchUsers,
  resendInvite,
  selectResendInviteStatus,
  setSelectedUser,
} from 'redux/features/usersSlice/usersSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
// Components
import { ChangeAccountTypeModal, InvitationError, UserAvatar } from './components';
import { PopupDelete, SpinnerIcon } from 'components';
// Types
import { UserTrii } from '@trii/types/dist/Users';
import { LoadingButton } from '@mui/lab';

type Props = {
  user: UserTrii;
};
const Header = ({ user }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [openChangeAccountTypeModal, setOpenChangeAccountTypeModal] =
    useState(false);

  const { handleEditButton, isSaving, isEditing, setIsEditing } =
    useContext(UsersContext);
  const { mediaQuery } = useContext(StyleContext);
  const { setOpenDeletePopup } = useContext(DataContext);

  const userTrii = useSelector(selectUser);
  const resendInvitationStauts = useSelector(selectResendInviteStatus);

  const userIsPending = !user.joinTimeStamp && !user.isActive;
  const resendInvitationLoading = resendInvitationStauts === 'loading';

  // Styles
  const buttonFontSize = mediaQuery.xl ? null : 10;
  const headerHeight = mediaQuery.lg ? '20%' : '25%';

  const startIcon = isSaving ? (
    <SpinnerIcon isLoading={isSaving} />
  ) : isEditing ? (
    <SaveIcon />
  ) : (
    <ModeEditOutlineOutlinedIcon />
  );
  //functions
  const confirmDelete = async () => {
    if (user.uid !== userTrii.uid) {
      setIsDeleting(true);

      await dispatch(deleteUser(user.uid));
      await dispatch(fetchUsers());

      setIsDeleting(false);
      setOpenDeletePopup(false);
      dispatch(setSelectedUser(null));
    }
  };

  const openDeletePopup = () => {
    setOpenDeletePopup(true);
  };

  const handleOpenChangeAccountTypeModal = () => {
    setOpenChangeAccountTypeModal(true);
  };

  const handleCloseChangeAccountTypeModal = () => {
    setOpenChangeAccountTypeModal(false);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  function handleResendInvitation() {
    dispatch(resendInvite(user.uid));
  }

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 2,
          py: 1.5,
          height: headerHeight,
          bgcolor: 'background.panel',
          boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
          borderRadius: 1,
        }}
      >
        <Box sx={{ alignSelf: 'end', display: 'flex', gap: 1 }}>
          {userIsPending && (
            <LoadingButton
              size="small"
              sx={{ fontSize: buttonFontSize }}
              startIcon={<ForwardToInboxIcon />}
              variant="outlined"
              onClick={handleResendInvitation}
              disabled={resendInvitationLoading}
              loading={resendInvitationLoading}
            >
              {t('usersInfo.resendInvitation')}
            </LoadingButton>
          )}
          <Button
            size={'small'}
            sx={{ fontSize: buttonFontSize }}
            startIcon={<ModeEditOutlineOutlinedIcon />}
            variant="outlined"
            onClick={handleOpenChangeAccountTypeModal}
            disabled={userIsPending}
          >
            {t('usersInfo.changeAccountType')}
          </Button>
          <Button
            type="submit"
            form="users-form"
            size={'small'}
            sx={{ fontSize: buttonFontSize }}
            startIcon={startIcon}
            variant="outlined"
            disabled={isSaving || userIsPending}
            onClick={isEditing ? null : handleEditButton}
          >
            {isEditing ? t('global.saveChanges') : t('global.editProfile')}
          </Button>
          {isEditing ? (
            <>
              <Button
                size={'small'}
                sx={{ fontSize: buttonFontSize }}
                // startIcon={<DeleteOutlinedIcon />}
                variant="outlined"
                color="error"
                onClick={cancelEdit}
              >
                {t('global.cancel')}
              </Button>
            </>
          ) : (
            <>
              <Button
                size={'small'}
                sx={{ fontSize: buttonFontSize }}
                startIcon={<DeleteOutlinedIcon />}
                variant="outlined"
                color="error"
                onClick={openDeletePopup}
              >
                {t('global.delete')}
              </Button>
            </>
          )}
        </Box>
        <UserAvatar user={user} />
      </Box>
      <ChangeAccountTypeModal
        open={openChangeAccountTypeModal}
        handleClose={handleCloseChangeAccountTypeModal}
        user={user}
      />
      <PopupDelete
        title={`Eliminar a ${user.display_name}`}
        subtitle="Se eliminarán todos los datos del usuario"
        onDelete={confirmDelete}
        loading={isDeleting}
      />
      <InvitationError open={resendInvitationStauts === 'rejected'} />
    </>
  );
};

export default Header;

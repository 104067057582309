import React from "react";
//redux
import { useSelector } from "react-redux";
import { getFetchProfilesStatus } from "redux/features/profilesSlice/profilesSlice";
//components/ui
import { ProfilesListItem, ProfilesListSkeleton } from "./components";
import List from "@mui/material/List";

export default function ProfilesList({ profiles }) {
  const fetchStatus = useSelector(getFetchProfilesStatus);
  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const profilesList =
    fetchStatus === "loading"
      ? skeletons.map((profile) => <ProfilesListSkeleton key={profile} />)
      : profiles.map((profile) => (
          <ProfilesListItem profile={profile} key={profile.id} />
        ));

  return (
    <List
      sx={{
        overflow: "auto",
        height: "100%",
      }}
    >
      {profilesList}
    </List>
  );
}

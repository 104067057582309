import { useContext } from 'react';
import Box from '@mui/material/Box';
import { UsersContext } from '../../context/UsersContext';
import { MessageNoUserExist, UserProfile } from './components';

export default function UserInfo() {
  const { selectedUser } = useContext(UsersContext);

  return (
    <Box
      height="100%"
      flexGrow={1}
      overflow="auto"
      sx={{ backgroundColor: 'dropdownBox', boxShadow: "0px 1px 2px rgba(0,0,0,0.2)"}}
    >
      {selectedUser === null ? (
        <MessageNoUserExist />
      ) : (
        <UserProfile user={selectedUser} />
      )}
    </Box>
  );
}

// Components/ui
import { Divider, Box } from '@mui/material';
import { Header, Inputs, ActionButtons } from './components';
import PanelContainer from '../PanelContainer';
import Privileges from './components/Privileges/Privileges';

const CreatePanel = () => {

  return (
    <PanelContainer>
      <Header />
      <Box sx={{

maxHeight: 'calc(100vh - 170px)', overflow: 'auto' }}>

      <Divider />
      <Inputs />
      <Privileges />
      
      </Box>

      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <ActionButtons />
    </PanelContainer>
  );
};

export default CreatePanel;

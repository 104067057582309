import React from "react";
import { useTranslation } from "react-i18next";
//redux
import { useSelector } from "react-redux";
import { selectAllRoles } from "redux/features/roleSlice/roleSlice";
//components/ui
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function SearchBar() {
  const { t } = useTranslation();
  const roles = useSelector(selectAllRoles);
  const rolesAmount = roles.length || "cargando";

  return (
    <Box
      boxShadow={"0px 2px 3px rgba(0,0,0,0.1)"}
      borderRadius="2px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingX={2}
      paddingTop={1}
      paddingBottom={1}
      position="relative"
      height={56}
    >
      <Typography sx={{ color: "text.primary" }}>
        {t("profileInfo.amount")}: {rolesAmount}
      </Typography>
    </Box>
  );
}

import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Hooks
import useField from 'hooks/useField';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
import {
  addUser,
  setSelectedUser,
  selectAddUserStatus,
} from 'redux/features/usersSlice/usersSlice';
// Components/ui
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Button,
  SelectChangeEvent,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
// Slice
import { fetchRoles, selectAllRoles } from 'redux/features/roleSlice/roleSlice';
import {
  fetchProfiles,
  selectAllProfiles,
} from 'redux/features/profilesSlice/profilesSlice';
import { Selector } from './components';
import { LoadingButton } from '@mui/lab';
// Types
import userTypeList from './utils/userTypeList';
import UserType from '@trii/types/dist/Users/UserType';

const PopupCreateUser = ({ isOpen, setOpenPopupCreateUser }) => {
  const { t } = useTranslation();
  const addUserStatus = useSelector(selectAddUserStatus);
  const isLoading = addUserStatus === 'loading';
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');
  const [typeSelected, setTypeSelected] = useState(UserType.AGENT.toString());
  const [showError, setShowError] = useState(false);

  const roles = useSelector(selectAllRoles);
  const profiles = useSelector(selectAllProfiles);
  const dispatch = useAppDispatch();
  const nameField = useField('text');
  const lastNameField = useField('text');
  const emailField = useField('email');

  const resetFields = () => {
    nameField.actions.resetValue();
    lastNameField.actions.resetValue();
    emailField.actions.resetValue();
    setSelectedRole('');
    setSelectedProfile('');
    setTypeSelected(UserType.AGENT.toString());
  };

  const createUser = async () => {
    const toUserType = Number(typeSelected) as UserType;
    const { value: firstname } = nameField.attributes;
    const { value: lastname } = lastNameField.attributes;
    const { value: email } = emailField.attributes;
    const name = firstname ? firstname : email;
    const newUser = {
      firstname: name as string,
      lastname: lastname as string,
      email: email as string,
      roleID: selectedRole,
      profileID: selectedProfile,
      userType: toUserType,
    };

    const response = await dispatch(addUser(newUser));

    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(setSelectedUser(response.payload));
    } else {
      setShowError(true);
    }

    resetFields();
    setOpenPopupCreateUser(false);
  };

  const cancelCreateUser = () => {
    resetFields();
    setOpenPopupCreateUser(false);
  };

  const handleChange =
    (setState: React.Dispatch<React.SetStateAction<string>>) =>
    (event: SelectChangeEvent) => {
      setState(event.target.value);
    };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchRoles());
      dispatch(fetchProfiles());
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-popup-title"
        aria-describedby="alert-popup-description"
        fullWidth
      >
        <DialogTitle id="alert-popup-title">{t('usersInfo.createUser')}</DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            px: 3,
            mb: 4,
            gap: 3,
          }}
        >
          <TextField
            size="small"
            label={t('global.name')}
            {...nameField.attributes}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField
            size="small"
            label={t('global.lastName')}
            {...lastNameField.attributes}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField
            size="small"
            label={t('global.email')}
            {...emailField.attributes}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <Selector
            list={userTypeList}
            title={t('global.userType')}
            value={typeSelected}
            onChange={handleChange(setTypeSelected)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
              justifyContent: 'space-between',
            }}
          >
            <Selector
              list={roles}
              title={t('global.role')}
              value={selectedRole}
              onChange={handleChange(setSelectedRole)}
            />
            <Selector
              list={profiles}
              title={t('global.profile')}
              value={selectedProfile}
              onChange={handleChange(setSelectedProfile)}
            />
          </Box>
        </Box>
        <DialogActions>
          <Button disabled={isLoading} onClick={cancelCreateUser}>
            {t('global.cancel')}
          </Button>
          <LoadingButton
            onClick={createUser}
            loading={isLoading}
            disabled={
              selectedRole === '' ||
              selectedProfile === '' ||
              emailField.attributes.value === ''
            }
          >
            {t('global.accept')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={() => {
          setShowError(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('usersInfo.errorCreatingUser')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PopupCreateUser;

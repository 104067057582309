// Components/ui
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
// Types
import { UserProfile, UserRole } from "@trii/types/dist/Users"
import { IUserType } from "components/PopupCreateUser/types/IUserType";

interface SelectorProps {
  list: UserRole[] | UserProfile[] | IUserType[];
  title: string;
  value: string;
  onChange: (value: SelectChangeEvent) => void;
}

const Selector = ({
  list,
  title,
  value,
  onChange,
}: SelectorProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <FormControl
        className="fadein"
        sx={{ width: "100%" }}
        size="small"
      >
        <InputLabel id="owner-select-label">
          {title}
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={title}
          fullWidth
          variant="outlined"
          sx={{
            fontSize: "1rem",
          }}
        >
          {list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item?.name || item?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Selector
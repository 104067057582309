import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  text: string;
}

const CopyClipboardButton = ({ text }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick} color="primary">
        <ContentCopyIcon sx={{ fontSize: 22 }} />
      </IconButton>
      <Snackbar
        message={t('ApiKeys.modal.copiedToClipboard')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyClipboardButton;

import React from 'react';
import UsersDataProvider from './context/UsersContextProvider';
//redux
import { fetchUsers } from 'redux/features/usersSlice/usersSlice';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
//components/ui
import { SidebarNav, UserInfo } from './components';

const UsersPanel = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <UsersDataProvider>
      <SidebarNav />
      <UserInfo />
    </UsersDataProvider>
  );
};

export default UsersPanel;

import React, { useContext } from 'react';
// Types
import { ApiKeysContext } from '../../context/types';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components/index';
import { InitApiKeysContext } from '../../context/ApiKeysContext';

const SidePanel = () => {
  const { createPanelOpen, deletePanelOpen, editPanelOpen } =
    useContext<ApiKeysContext>(InitApiKeysContext);

  return (
    <>
      {(createPanelOpen && <CreatePanel />) ||
        (deletePanelOpen && <DeletePanel />) ||
        (editPanelOpen && <EditPanel />)}
    </>
  );
};

export default SidePanel;

import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import auditLogReducer from './features/auditLogSlice/auditLogSlice';
import securityReducer from './features/securitySlice/securitySlice';
import roleReducer from './features/roleSlice/roleSlice';
import profilesReducer from './features/profilesSlice/profilesSlice';
import usersReducer from './features/usersSlice/usersSlice';
import userReducer from './features/userSlice/userSlice';
import sessionReducer from './features/sessionSlice/sessionSlice';
import domainReducer from './features/domainSlice/domainSlice';
import poolReducer from './features/poolSlice/poolSlice';
import themeReducer from './features/themeSlice/themeSlice';
import workspaceReducer from './features/workspace/workspaceSlice';
import subdomainReducer from './features/subdomainSlice/subdomainSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import dashboardSlice from './features/dashboardSlice/dashboardSlice';
import APIKeysSlice from './features/APIKeysSlice/APIKeysSlice';


const rootReducer = combineReducers({
  User: userReducer,
  Session: sessionReducer,
  Logs: auditLogReducer,
  Domain: domainReducer,
  Pool: poolReducer,
  Role: roleReducer,
  Users: usersReducer,
  Profiles: profilesReducer,
  Security: securityReducer,
  Theme: themeReducer,
  Workspace: workspaceReducer,
  Subdomain: subdomainReducer,
  Spaces: spacesSlice,
  Space: spaceSlice,
  Dashboard: dashboardSlice,
  ApiKey: APIKeysSlice

  
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

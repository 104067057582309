import React from "react";
import SecurityProvider from "./context/securityContext";
//redux
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  fetchSecurity,
  getSecurityFetchStatus,
} from "redux/features/securitySlice/securitySlice";
//components/ui
import {
  SaveChanges,
  SessionManagementPanel,
  TwoFactorsAuth,
} from "./components";
import { Box, Divider } from "@mui/material";

const Security = () => {
  const dispatch = useAppDispatch();
  const securityFetchStatus = useSelector(getSecurityFetchStatus);

  React.useEffect(() => {
    dispatch(fetchSecurity());
  }, [dispatch]);

  return (
    <SecurityProvider>
      {securityFetchStatus === "succeeded" && (
        <Box className="profile-fadeinup">
          <Box
            className="profile-container overflow-auto"
            style={{ height: "calc(100vh - 145px)", padding: "0px" }}
          >
            <SaveChanges />
            <Divider sx={{ marginY: "15px", marginX: "10px" }} />
            <Box
              sx={{ overflowY: "auto", maxHeight: "90%", paddingRight: "2px" }}
            >
              <TwoFactorsAuth />
              <SessionManagementPanel />
            </Box>
          </Box>
        </Box>
      )}
    </SecurityProvider>
  );
};

export default Security;

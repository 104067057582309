import UserType from '@trii/types/dist/Users/UserType';

export const accountType = [
  {
    id: 2,
    label: 'usersInfo.accountType.coOwner',
    value: UserType.CO_OWNER,
  },
  {
    id: 3,
    label: 'usersInfo.accountType.admin',
    value: UserType.ADMIN,
  },
  {
    id: 4,
    label: 'usersInfo.accountType.commonUser',
    value: UserType.AGENT,
  },
];

import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { deleteCustomDomain } from 'redux/features/workspace/workspaceSlice';
// Types
import type { Dispatch, SetStateAction } from 'react';
// Components/ui
import { DialogText } from './components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
};

const DeleteDialog = ({ openDeleteDialog, setOpenDeleteDialog }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);

    await dispatch(deleteCustomDomain());

    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('edit.subdomain.caution')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <DialogText />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size={'small'}
          variant="outlined"
          onClick={() => setOpenDeleteDialog(false)}
        >
          {t('global.cancel')}
        </Button>
        <LoadingButton
          size={'small'}
          onClick={handleDelete}
          disabled={isLoading}
          loading={isLoading}
          variant="outlined"
          color="error"
        >
          {t('global.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;

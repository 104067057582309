import { useContext } from 'react';
import PropTypes from 'prop-types';
import { RolesContext } from '../../../../../context/rolesContext';
// Components/ui
import { styled } from '@mui/material';
import BranchOptions from './components/BranchOptions';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    width: 'auto',
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: '36px',
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover .profile-showButtonsRoles': {
      display: 'flex',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
}));

function RoleBranch({ data, ...other }) {
  const { handleSelectRole } = useContext(RolesContext);

  return (
    <StyledTreeItemRoot
      onClick={() => handleSelectRole(data)}
      key={data.id}
      nodeId={data.id}
      label={<BranchOptions role={data} />}
      {...other}
    />
  );
}

RoleBranch.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  nodeId: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.number,
};

export default RoleBranch;

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { Security } from '@trii/types/dist/Security';
import { initRequestData } from 'redux/functions/initRequestData';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import securityService from './securityService';
import { RootState } from 'redux/store';
import { SecurityState } from './types/SecurityState';

const initialState: SecurityState = {
  security: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchSecurity = createAsyncThunk(
  'Security/fetchSecurity',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await securityService.fetchSecurity(jwtToken, URL_SETTINGS);

    return response.data;
  }
);

export const updateSecurity = createAsyncThunk(
  'Users/updateUser',
  async (data: Security, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await securityService.updateSecurity(
      jwtToken,
      URL_SETTINGS,
      data
    );

    return response.data;
  }
);

const securitySlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    setSecondFactorApp(state, action: PayloadAction<boolean>) {
      console.log('action.payload', action.payload);
      state.security.secondFactorApp = action.payload;
    },
    set_sessions_concurrentSessions(state, action: PayloadAction<number>) {
      state.security.sessions_concurrentSessions = action.payload;
    },
    set_sessions_idleSessionTimeout(state, action: PayloadAction<number>) {
      state.security.sessions_idleSessionTimeout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSecurity.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSecurity.fulfilled, (state, action: PayloadAction<Security>) => {
        state.status.fetch = 'succeeded';
        state.security = action.payload;
      })
      .addCase(updateSecurity.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(
        updateSecurity.fulfilled,
        (state, action: PayloadAction<Security>) => {
          state.status.update = 'succeeded';
          state.security = action.payload;
        }
      );
  },
});

export const {
  setSecondFactorApp,
  set_sessions_concurrentSessions,
  set_sessions_idleSessionTimeout,
} = securitySlice.actions;

const securityState = (state: RootState) => state.Security;
export const getSecurityFetchStatus = createSelector(
  securityState,
  (state) => state.status.fetch
);
export const selectSecurity = createSelector(
  securityState,
  (state) => state.security
);
export const getSecurityUpdateStatus = createSelector(
  securityState,
  (state) => state.status.update
);

export default securitySlice.reducer;

import { useState, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Utils
import { spaceSubdomainNameControl } from 'helpers/authControl';

// Components/ui
import { FormNotificationError, StatusAdornment } from 'components';
import TextField from '@material-ui/core/TextField';
import subdomainService from 'redux/features/subdomainSlice/subdomainService';

const SubdomainInput = ({ subdomainNameInput, existingSubdomainName }) => {
  const { t } = useTranslation();
  const [adornmentStatus, setAdornmentStatus] = useState('idle');
  const subdomainInputValue = subdomainNameInput.attributes.value;

  const handleSpaceSubdomainNameControl = (subdomainName: string, subdomainAvailability: boolean) => {
    !spaceSubdomainNameControl(subdomainName)
      ? subdomainNameInput.actions.onError(
        <FormNotificationError errorMsg={t('add.error.invalidSubdomain')} />
      )
      : !subdomainAvailability
        ? subdomainNameInput.actions.onError(
          <FormNotificationError errorMsg={t('add.error.nonAvailableSubdomain')} />
        )
        : subdomainNameInput.actions.onSuccess();
  };

  useEffect(() => {
    if (
      subdomainInputValue !== '' &&
      subdomainInputValue !== existingSubdomainName
    ) {
      setAdornmentStatus('loading');
      let timer = setTimeout(async () => {

        const subdomain = `${subdomainInputValue}.mytrii.app`;
        const subdomainJson = JSON.stringify({ subdomain });
        const { available } = await subdomainService.getSubdomainAvailability(
          subdomainJson
        );

        handleSpaceSubdomainNameControl(subdomainInputValue, available);

        available && spaceSubdomainNameControl(subdomainInputValue)
          ? setAdornmentStatus('available')
          : setAdornmentStatus('unavailable');
      }, 1700);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomainInputValue]);

  return (
    <TextField
      {...subdomainNameInput.attributes}
      fullWidth
      id="inputSubdomain"
      variant="outlined"
      size="small"
      autoComplete='off'
      InputProps={{
        endAdornment: (
          <>
            <StatusAdornment status={adornmentStatus} text={'.mytrii.app'} />
          </>
        ),
      }}
    />
  );
};

export default SubdomainInput;

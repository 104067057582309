import React from "react";
//components/ui
import Box from "@mui/material/Box";
import { UsersPanel, RolesPanel, ProfilePanel } from "./components";

export default function TableBody({ tab }) {
  return (
    <Box display="flex" mt={1} height={"calc(100vh - 200px)"} width="100%">
      {tab === 0 && <UsersPanel />}
      {tab === 1 && <ProfilePanel />}
      {tab === 2 && <RolesPanel />}
    </Box>
  );
}

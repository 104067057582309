import { createTheme } from '@mui/material';

const MuiComponentsConfig = (themeRedux) => {
  return createTheme({
    ...themeRedux,
    components: {
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: themeRedux.palette.background.panel,
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            zIndex: 1,
            '& div': {
              backgroundColor: themeRedux.palette.background.panel,
              // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: themeRedux.palette.background.panel,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: themeRedux.palette.background.panel,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          },
          '& MenuList-root': {
            padding: 0
          }
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            background: "transparent",
            "& .MuiBackdrop-root": {
              // background: "transparent",
              border: "none",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: themeRedux.palette.text.primary,
          },
        },
      },
      // MuiMenuItem: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: themeRedux.palette.background.panel,
      //       '&.Mui-selected': {
      //         backgroundColor: themeRedux.palette.action.selected,
      //       },
      //     },
      //   },
      // },
    },
  });
};

export default MuiComponentsConfig;

import React from 'react';
import { useTranslation } from 'react-i18next';

const timeFormatList = () => {
  const { t } = useTranslation();

  const nameFormatList = [
    { value: "12", description: t('usersInfo.12hours') },
    { value: "24", description: t('usersInfo.24hours') },
  ];

  return nameFormatList;
};

export default timeFormatList;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSession } from 'redux/features/sessionSlice/sessionSlice';
import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { RootState } from 'redux/store';

interface CognitoUserSession {
  idToken?: CognitoIdToken;
  refreshToken?: CognitoRefreshToken;
  accessToken?: CognitoAccessToken;
  clockDrift?: number;
}

export const initRequestData = createAsyncThunk<
  InitRequestDataReturn,
  void,
  { state: RootState }
>('session/initRequest', async (_, { dispatch, getState }) => {
  const { Space, Session } = getState();
  let jwtToken: string;

  if (Session.session && Session.session.isValid()) {
    jwtToken = Session.session.idToken.getJwtToken();
  } else {
    const sessionRefresh: CognitoUserSession = (await dispatch(setSession()))
      .payload;

    jwtToken = sessionRefresh.idToken.getJwtToken();
  }
  const URL_SETTINGS = Space.URL.SETTINGS;
  const URL_SPACES = Space.URL.SPACES;

  return {
    jwtToken,
    URL,
    URL_SETTINGS,
    URL_SPACES,
  };
});

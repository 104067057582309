import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { UserTrii } from '@trii/types/dist/Users';

const fetchUserInfo = async (
  jwtToken: string,
  URL_CONTACTS: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(URL_CONTACTS, config);

  return response.data;
};

const updateUser = async (
  jwtToken: string,
  URL_USER: string,
  user: UserTrii
): Promise<UserTrii> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<UserTrii>(URL_USER, user, config);

  return response.data;
};

const deleteUser = async (
  jwtToken: string,
  URL_USER: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.delete<UserTrii>(URL_USER, config);

  return response.data;
}

const userSliceService = {
  deleteUser,
  fetchUserInfo,
  updateUser
};

export default userSliceService;

import React, { memo } from 'react';
//components/ui
import { Box, InputLabel } from '@mui/material';
import Info from 'components/Info';
import { FormControl, MenuItem, Select } from '@mui/material';

export default memo(function SelectBox({
  selectTitle,
  values,
  value,
  setValue,
  valueName,
  isEditing,
  valueDescription,
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box className="fadein" display={'flex'} width="95%">
      {isEditing ? (
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel shrink>{selectTitle}</InputLabel>
          <Select
            value={value}
            onChange={handleChange}
            sx={{
              '& legend': {
                maxWidth: 'fit-content',
              },
            }}
            label={selectTitle}
            size="small"
          >
            {values.map((value, index) => {
              if (value.hasOwnProperty('value')) {
                return (
                  <MenuItem key={index} value={value.value}>
                    {value.description}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={value.id} value={value.id}>
                  {value.hasOwnProperty('title') ? value.title : value.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <Info title={selectTitle} info={valueName || valueDescription} />
      )}
    </Box>
  );
});

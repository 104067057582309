import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
// Types
import { UserSliceState } from './types/UserSliceState';
import { RootState } from 'redux/store';
import { UserTrii } from '@trii/types/dist/Users';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Service
import userSliceService from './userSliceService';

const initialState: UserSliceState = {
  user: null,
  status: {
    deletePhoto: 'idle',
  }
};

export const getUser = createAsyncThunk<UserTrii, undefined, { state: RootState }>(
  'User/getUser',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const triiUser = await userSliceService.fetchUserInfo(
      jwtToken,
      `${URL_SETTINGS}/user`
    );
    return triiUser;
  }
);

export const setUser = createAsyncThunk('User/setUser', async (_, { dispatch }) => {
  const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const config = {
    headers: { Authorization: `bearer ${jwtToken}` },
  };
  const response = await axios.get(`${URL_SETTINGS}/user`, config);

  return response.data;
});

export const updateUserPhoto = createAsyncThunk(
  'User/updateUserPhoto',
  async (photo: Blob, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const config = {
      headers: { Authorization: `bearer ${jwtToken}` },
    };
    const fd = new FormData();

    fd.append('photo', photo, photo.name);

    const response = await axios.post(`${URL_SETTINGS}/user/setphoto`, fd, config);

    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  'User/updateUser',
  async (user: UserTrii, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const updatedUser = await userSliceService.updateUser(
      jwtToken,
      `${URL_SETTINGS}/user`,
      user
    );

    return updatedUser;
  }
);

export const fetchDeletePhoto = createAsyncThunk(
  'User/deletePhoto',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const updatedUser = await userSliceService.deleteUser(
      jwtToken,
      `${URL_SETTINGS}/user/setphoto`,
    );

    return updatedUser;
  }
);

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserAction: (state, action: PayloadAction<UserTrii>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        console.log('success fetching user: ', action.payload);
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.error);
      })
      .addCase(updateUser.pending, (state, action) => {
        console.log('pending update user: ', action.meta.arg);
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        console.log('success update user: ', action.payload);
        state.user = action.payload;
        setUserAction(action.payload);
      })
      .addCase(updateUser.rejected, (state, action) => {
        console.log('error update user: ', action.error);
      })
      .addCase(updateUserPhoto.pending, (state, action) => {
        console.log('pending update user photo: ', action.meta.arg);
      })
      .addCase(updateUserPhoto.fulfilled, (state, action) => {
        console.log('success update user photo: ', action.payload);
        state.user = action.payload;
        setUserAction(action.payload);
      })
      .addCase(updateUserPhoto.rejected, (state, action) => {
        console.log('error update user photo: ', action.error);
      })
      .addCase(fetchDeletePhoto.pending, (state, action) => {
        state.status.deletePhoto = 'loading';
      })
      .addCase(fetchDeletePhoto.fulfilled, (state) => {
        state.status.deletePhoto = 'succeeded';
        console.log('success delete user photo');
      })
      .addCase(fetchDeletePhoto.rejected, (state, action) => {
        state.status.deletePhoto = 'rejected';
        console.log('error delete user photo: ', action.error);
      });
  },
});

export const { setUserAction } = userSlice.actions;

// Selectors
export const selectState = (state: RootState) => state.User
export const selectUser = createSelector(
  selectState,
  (state) => state.user
);
export const selectDeletePhotoStatus = createSelector(
  selectState,
  (state) => state.status.deletePhoto
);

export default userSlice.reducer;

// Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
// Types
import { DashboardSliceState } from './types/DashboardSliceState';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import dashboardService from './dashboardService';

const initialState: DashboardSliceState = {
  totalMembers: null,
  totalBytes: null,
  status: {
    fetch: 'idle',
  },
};

export const fetchDashboardInfo = createAsyncThunk(
  'dashboard/fetchDashboardInfo',
  async (_, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchDashboardInfo(
      jwtToken,
      URL_SETTINGS
    );

    return response;
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardInfo.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchDashboardInfo.fulfilled, (state, action) => {
        state.totalMembers = action.payload.totalMembers;
        state.totalBytes = action.payload.totalBytes;

        console.log('success fetching dashboard info: ', action.payload);
        state.status.fetch = 'succeeded';
      })
      .addCase(fetchDashboardInfo.rejected, (state) => {
        state.status.fetch = 'rejected';
      });
  },
});

export default dashboardSlice.reducer;

import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Component/ui
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Types
import { UserTrii } from '@trii/types/dist/Users';
import { Avatar } from '@mui/material';
// Functions
import { stringAvatar } from 'redux/functions/stringAvatar';

type Props = {
  user: UserTrii;
};

export default function UserAvatar({ user }: Props) {
  const [avatarChild, setAvatarChild] = useState<string>('');
  const { fontSize } = useContext(StyleContext);

  useEffect(() => {
    if (user) {
      const newAvatarChild = stringAvatar(user.display_name);
      setAvatarChild(newAvatarChild);
    }
  }, [user]);

  return (
    <Box display="flex" alignItems="center" my={'auto'}>
      <Avatar
        src={user.imageUrl}
        alt={user.display_name}
        children={avatarChild}
        sx={{
          width: 54,
          height: 54,
        }}
      />
      <ListItem>
        <ListItemText
          primary={
            <Typography
              fontSize={fontSize.md}
              fontWeight={'bold'}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {user.display_name}
            </Typography>
          }
          secondary={
            <Typography
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {user.email}
            </Typography>
          }
        />
      </ListItem>
    </Box>
  );
}

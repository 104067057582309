import React from "react";
import { useTranslation } from "react-i18next";
//components/ui
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const InfoTabsHeader = ({ tabUserProfile, setTabUserProfile }) => {
  const { t } = useTranslation();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (event, newValue) => {
    setTabUserProfile(newValue);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      width="80%"
      // sx={{ backgroundColor: 'background.panel' }}
    >
      <Tabs value={tabUserProfile} onChange={handleTabChange}>
        <Tab label={t("global.details")} {...a11yProps(0)} />
        <Tab label={t("global.applications")} {...a11yProps(1)} />
      </Tabs>
    </Box>
  );
};

export default InfoTabsHeader;

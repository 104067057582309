import React from 'react';
import { useTranslation } from 'react-i18next';

const nameFormatList = () => {
  const { t } = useTranslation();

  const nameFormatList = [
    { value: "1", description: t('user.Name Lastname') },
    { value: "2", description: t('user.Lastname Name') },
  ];

  return nameFormatList;
};

export default nameFormatList;
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Types
// Components/ui
import { Button, Box, Typography, Breadcrumbs } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ApiKeysContext } from '../../context/types';
import { InitApiKeysContext } from '../../context/ApiKeysContext';

export default function Header() {
  const { t } = useTranslation();
  const { openCreatePanel, createPanelOpen } =
    useContext<ApiKeysContext>(InitApiKeysContext);

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      sx={{ paddingRight: 2 }}
      borderRadius={'4px'}
      height="50px"
       // className="m-0"
       gap={'1rem'}
    >
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreatePanel}
        disabled={createPanelOpen}
      >
        {t('ApiKeys.createApiKey')}
      </Button>
    </Box>
  );
}

const paymentList = [
  { id: 1, pending: true },
  { id: 2, pending: false },
  { id: 3, pending: true },
  { id: 4, pending: false },
  { id: 5, pending: true },
  { id: 6, pending: false },
  { id: 7, pending: true },
  { id: 8, pending: false },
  { id: 9, pending: true },
  { id: 10, pending: false },
  { id: 11, pending: true },
  { id: 12, pending: false },
  { id: 13, pending: true },
  { id: 14, pending: false },
];

export default paymentList;

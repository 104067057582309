import React from "react";
import Box from "@mui/material/Box";
import { SearchBar, UsersList } from "./Components";

const SidebarNav = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={"33.3%"}
      flexGrow={1}
      height={"100%"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius={"2px"}
      mr={1}
      maxWidth={"350px"}
      minWidth={"350px"}

    >
      <SearchBar />
      <UsersList />
    </Box>
  );
};

export default SidebarNav;

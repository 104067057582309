import { useEffect, useState } from 'react';
//components/ui
import Info from 'components/Info';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Types
import { UseDateFieldType } from 'hooks/useDateField';

const DATE_FORMAT = 'DD/MM/YYYY';

interface Props {
  boxDescription: string;
  stateHandler: UseDateFieldType;
  isDisabled: boolean;
  isEditing: boolean;
}

const DateBox = ({
  boxDescription,
  stateHandler,
  isDisabled,
  isEditing,
}: Props) => {
  const [info, setInfo] = useState('');

  useEffect(() => {
    const { value } = stateHandler.attributes;
    if (value) {
      const date = value.format(DATE_FORMAT);
      setInfo(date);
    }
  }, [stateHandler.attributes.value])

  return (
    <Box
      className="fadein"
      display={'flex'}
      width='95%'
      alignItems={'center'}
      marginTop={1}
    >
      {isEditing ? (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            sx={{
              width: '100%',
            }}
            slotProps={{ textField: { size: 'small' } }}
            label={boxDescription}
            value={stateHandler.attributes.value}
            onChange={(date) => { stateHandler.attributes.onChange(date) }}
            format='DD/MM/YYYY'
          />
        </LocalizationProvider>
      ) : (
        <Info title={boxDescription} info={info} />
      )}
    </Box>
  )
}

export default DateBox;
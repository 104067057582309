import React, { useContext } from 'react';
// Components/ui
import { Divider, Box } from '@mui/material';
import { Header, Inputs, ActionButtons } from './components';
import PanelContainer from '../PanelContainer';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';

const DeletePanel = () => {

  return (
    <PanelContainer>
      <Header />
      <Box
        sx={{
          maxHeight: "calc(100vh - 240px)",
          overflow: "auto",
        }}
      >

      <Divider />
      <Inputs />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      </Box>
      <ActionButtons/>
    </PanelContainer>
  );
};

export default DeletePanel;

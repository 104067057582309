import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';

// Redux
import { useSelector } from 'react-redux';

// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { APIKeysState, selectAllAPIKeys, selectAPIKeysFetchStatus } from 'redux/features/APIKeysSlice/APIKeysSlice';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeEditPanel, sendEditedApiKey, editApiKeyNameField, editApiKeyUsers, apiKeyData, privileges } =
    useContext<ApiKeysContext>(InitApiKeysContext);

  const editStatus: RequestStatus = useSelector(selectAPIKeysFetchStatus); // Obtenemos el estado de carga
  const isLoading: boolean = editStatus === 'loading';

  // Validar si el botón debe estar deshabilitado
  const isDisabled: boolean = isLoading || !editApiKeyNameField.attributes.value;

  const handleSaveClick = () => {
    sendEditedApiKey({...apiKeyData, name: editApiKeyNameField.attributes.value as string, privileges: privileges});
  };
  
  

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeEditPanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        size="small"    
        disabled={isDisabled}
        onClick={handleSaveClick}
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;

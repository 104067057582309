import React, { useMemo } from 'react';
//components/ui
import { Box } from '@mui/material';
import Input from 'components/Input';

export default function EditableBox({
  boxDescription,
  stateHandler,
  isEditing,
  children,
  isDisabled,
  ...other
}) {
  return useMemo(() => {
    return (
      <Box className="fadein" display={'flex'} alignItems={'center'}>
        {isEditing ? (
          <Input
            attributes={stateHandler.attributes}
            label={boxDescription}
            isDisabled={isDisabled}
            {...other}
          />
        ) : (
          children
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, stateHandler.attributes.value, boxDescription, children]);
}

// Translation
import { useTranslation } from "react-i18next"
// Components/ui
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { Table } from "react-bootstrap"
// Types
import { AuditLog, AuditLogType } from "@trii/types/dist/AuditLogs";
// Moment
import moment from "moment"
// Components
import {
  EmptyLogs,
  SkeletonBody,
} from "./components"

interface ITableLogsProps {
  logs: AuditLog[];
  logStatus: string;
}

const TableLogs = ({
  logs,
  logStatus,
}: ITableLogsProps) => {
  const { t } = useTranslation()

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "calc(100vh - 340px)",
        //@ts-ignore
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <Table
        style={{
          minWidth: 650,
          width: "100%",
          borderSpacing: "0px"
        }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                minWidth: "110px", //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
              }}
            ></TableCell>
            <TableCell
              sx={{
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
              }}
              style={{ minWidth: "200px" }}
              size={"small"}
            >
              {t("tableHeader.dateTime")}
            </TableCell>
            <TableCell
              sx={{
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
              }}
            >
              {t("global.detail")}
            </TableCell>
          </TableRow>
        </TableHead>
        {((logStatus === "succeeded" && logs.length === 0) ||
          logStatus === "rejected") && <EmptyLogs />}
        {logStatus === "loading" && logs && logs.length === 0 ? (
          <SkeletonBody />
        ) : (
          <TableBody>
            {logs?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell sx={{ width: "110px" }}>
                  <Typography
                    sx={{
                      color:
                        row.type === AuditLogType.INFO
                          ? "green"
                          : row.type === AuditLogType.WARNING
                          ? "orange"
                          : row.type === AuditLogType.ERROR
                          ? "red"
                          : "white",
                      fontSize: "0.8rem",
                    }}
                  >
                    {row.type === AuditLogType.INFO
                      ? "Info"
                      : row.type === AuditLogType.WARNING
                      ? "Warning"
                      : row.type === AuditLogType.ERROR
                      ? "Error"
                      : "error data type"}
                  </Typography>
                </TableCell>
                <TableCell scope="row" sx={{ minWidth: "200px" }}>
                  {moment(row.datetime).format("L, h:mm a")}
                </TableCell>
                <TableCell sx={{ width: "100%" }}>{row.details}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default TableLogs
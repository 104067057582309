import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import auditLogService from './auditLogService';
import { RootState } from 'redux/store';
import { DateData } from './types/DateData';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { AuditLogSlice } from './types/AuditLogSlice';
import { AuditLog } from '@trii/types/dist/AuditLogs';

const initialState: AuditLogSlice = {
  logs: null,
  status: { fetch: 'idle' },
  error: null,
  date: { start: null, end: null },
  type: null,
};

export const fetchLogs = createAsyncThunk(
  'logs/fetchLogs',
  async (dateData: DateData, { dispatch }) => {
    const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const logs = await auditLogService.getLogs(URL_SETTINGS, jwtToken, dateData);

    return logs;
  }
);

export const auditLogSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogDateFilter: (
      state,
      action: PayloadAction<{ start: number; end: number }>
    ) => {
      state.date = { ...state.date, ...action.payload };
    },
    setLogTypeFilter: (state, action: PayloadAction<string[]>) => {
      const newTypeValue = action.payload;
      state.type = newTypeValue.join('');
    },
    setLogs: (state, action: PayloadAction<AuditLog[]>) => {
      state.logs = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLogs.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        console.log('success fetching log: ', action.payload);
        state.status.fetch = 'succeeded';
        state.logs = action.payload;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        console.log('erorr fetching log: ', action.payload);
        state.status.fetch = 'rejected';
        state.error = action.error.message;
      });
  },
});

//Actions
export const { setLogDateFilter, setLogTypeFilter, setLogs } = auditLogSlice.actions;
// Selectors
const selectAuditLogState = (state: RootState) => state.Logs;
export const selectLogs = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.logs
);
export const logsTypeFilterSelector = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.type
);
export const logsinitDateFilterSelector = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.date.start
);
export const logsEndDateFilterSelector = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.date.end
);
export const getLogsStatus = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.status.fetch
);
export const getLogsError = createSelector(
  selectAuditLogState,
  (auditLogState) => auditLogState.error
);

export default auditLogSlice.reducer;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch } from 'react-redux';

// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { InitApiKeysContext } from 'features/Views/ApiKeys/context/ApiKeysContext';
import { ApiKeysContext } from 'features/Views/ApiKeys/context/types';
import { RequestStatus } from 'types/reduxTypes';
import { deleteAPIKey, selectAPIKeyById, selectAPIKeyDeleteStatus } from 'redux/features/APIKeysSlice/APIKeysSlice';
import { RootState } from 'redux/store';
import { useAppDispatch } from 'hooks/useAppDispatch';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, apiKeyData, deleteApiKeyNameField } = useContext<ApiKeysContext>(InitApiKeysContext);

  const dispatch = useAppDispatch();
  const deleteStatus: RequestStatus = useSelector(selectAPIKeyDeleteStatus);
  const selectedApiKey = useSelector((state: RootState) =>
    selectAPIKeyById(apiKeyData.id)(state)
  );

  const isLoading = deleteStatus === 'loading';
  const isDisabled = isLoading || deleteApiKeyNameField.attributes.value !== apiKeyData.name;

  const handleDeleteClick = () => {
    if (selectedApiKey) {
      dispatch(deleteAPIKey({ id: selectedApiKey.id, name: selectedApiKey.name }));
    }
    closeDeletePanel();

  };
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDeletePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (color) => color.palette.error.main,
          '&:hover': {
            backgroundColor: (color) => color.palette.error.dark,
          },
        }}
        disabled={isDisabled}
        onClick={handleDeleteClick}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;

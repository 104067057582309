import React, { useContext, useState } from "react";
import { ProfileInfoBodyTabs, ProfileInfoBodyPanels } from "./components";
import { Box } from "@mui/material";
import { StyleContext } from 'style/styleProvider';

import Divider from "@material-ui/core/Divider";

export default function ProfileInfoBody() {
  const [profileTab, setProfileTab] = useState(0);
  const { mediaQuery } = useContext(StyleContext);

  const BodyHeight = mediaQuery.lg ? '80%' : '75%';

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ backgroundColor: "background.panel" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius="2px"
      height={BodyHeight}
    >
      <Box display="flex" height={"100%"} flexDirection={"column"} width="100%">
        <ProfileInfoBodyTabs tab={profileTab} setTab={setProfileTab} />
        <Divider />
        <Box
          display={"flex"}
          flexDirection="column"
          height={"100%"}
          px={5}
          pt={4}
          overflow="auto"
        >
          <ProfileInfoBodyPanels tab={profileTab} />
        </Box>
      </Box>
    </Box>
  );
}

// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

export default function MessageNoUserExist() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      boxShadow={'0px 1px 2px rgba(0,0,0,0.2)'}
      borderRadius="2px"
      height="-webkit-fill-available"
      px={3}
      py={1}
    >
      <Box
        display="flex"
        height={'100%'}
        flexDirection={'column'}
        px={2}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          {t('usersInfo.users')}
        </Typography>
        <Box display="flex">
          <Typography mr={1} sx={{ color: 'text.primary' }}>
            {t('usersInfo.messageNoUsersTitle')}
          </Typography>

          <GroupAddOutlinedIcon color="primary" />
        </Box>
        <Typography sx={{ color: 'text.primary' }}>
          {t('usersInfo.messageNoUsersSubtitle')}
        </Typography>
      </Box>
    </Box>
  );
}

// import { UserInfoBodyHeader, UserInfoBodyDetails } from './Components';
import { Box, Typography } from "@mui/material";

import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { useTranslation } from "react-i18next";

export default function MessageNoRoleExist() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ backgroundColor: "dropdownBox" }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius="2px"
      height="100%"
      px={3}
      py={1}
    >
      <Box
        display="flex"
        height={"100%"}
        flexDirection={"column"}
        width="100%"
        px={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography variant="h4" sx={{ color: "text.primary" }}>
          {t("rolesInfo.roles")}
        </Typography>
        <Box display="flex">
          <Typography mr={1} sx={{ color: "text.primary" }}>
            {t("rolesInfo.messageNoRolTitle")}
          </Typography>

          <GroupAddOutlinedIcon color="primary" />
        </Box>
        <Typography sx={{ color: "text.primary" }}>
          {t("rolesInfo.messageNoRolSubtitle")}
        </Typography>
      </Box>
    </Box>
  );
}

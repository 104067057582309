import { Box } from '@mui/material';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  tabValue: number;
}

const TabPanel = ({ children, tabValue, index, ...other }: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      hidden={tabValue !== index}
      aria-labelledby={`simple-tab-${index}`}
      style={{ overflow: 'auto', height: '100%' }}
      sx={{ backgroundColor: 'dropdownBox' }}
      {...other}
    >
      {tabValue === index && children}
    </Box>
  );
};

export default TabPanel;
